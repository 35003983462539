import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "@src/components/buttons/Button";
import { useDialog } from "@src/providers/DialogProvider";
import * as yup from "yup";
import { useYupValidationResolver } from "../Forms/yupValidationResolver";
import isEmpty from "lodash/isEmpty";
import { addAuthKeyToUrl } from "@src/helpers/authSession";
import { useValidateJoinCode } from "./useValidateJoinCode";

const validationSchema = yup.object({
  code: yup.string().required("Join code is required"),
});

interface JoinCodeInputs {
  code: string;
}

export const JoinCodeDialog = () => {
  const { isLoading, validateJoinCode } = useValidateJoinCode();
  const { hideDialog } = useDialog();
  const resolver = useYupValidationResolver(validationSchema);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm<JoinCodeInputs>({
    resolver,
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async (data: JoinCodeInputs) => {
    const isValid = await validateJoinCode(data.code);
    if (!isValid) {
      setError("code", { type: "custom", message: "Invalid code" });
      return;
    }

    addAuthKeyToUrl("joinCode", data.code);
    reset();
    hideDialog("join-code-modal");
  };

  return (
    <Box sx={{ width: "100%" }} data-testid="join-code-dialog">
      <Typography variant="body2" sx={{ mb: 1 }}>
        Enter join code
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="code"
          control={control}
          render={(field) => (
            <Box>
              <TextField
                inputProps={{
                  "data-testid": "code-input",
                }}
                error={!isEmpty(errors["code"])}
                color="primary"
                label="Join code"
                fullWidth
                helperText={errors["code"]?.message}
                {...field}
              />
            </Box>
          )}
        />
        <Box sx={{ mt: 2 }}>
          <Button
            sx={{ width: 100, height: 30 }}
            disabled={isLoading}
            loading={isLoading}
            data-testid="confirm-join-code"
            type="submit"
            color="primary"
            variant="contained"
          >
            Join session
          </Button>
        </Box>
      </form>
    </Box>
  );
};
