import { useUser } from "@src/providers/UserProvider";
import { useEffect } from "react";
import { MetricsUser } from "./common";
import { MetricsClient } from "./MetricsClient";

export const useSetMetricsUser = ({
  company_id,
}: Pick<MetricsUser, "company_id">) => {
  const user = useUser();

  useEffect(() => {
    if (!user.uid || !user.email) return;

    MetricsClient.getInstance().setUser({
      user_id: user.uid,
      user_name: user.name,
      user_email: user.email,
      company_name: undefined, // we don't have company name in embed
      company_id,
    });
  }, [company_id, user.email, user.name, user.uid, user.userRole]);
};
