import { useIsCircleOwner } from "./../../../providers/NetworkingHubProvider";
import { useUser } from "@src/providers/UserProvider";
import { useEffect, useMemo } from "react";
import { NetworkingHubRoom } from "./../../../contracts/networking-hub/NetworkingHubRoom";
import { toast } from "react-toastify";

interface UseCircleCapacityManagementOptions {
  onLeave: () => void;
  isConnected: boolean;
  circle?: NetworkingHubRoom;
}

/**
 * hook to prevent a user from joining a full circle via a direct room link
 */
export const useCircleCapacityManagement = ({
  onLeave,
  isConnected,
  circle,
}: UseCircleCapacityManagementOptions) => {
  const user = useUser();
  const isCircleOwner = useIsCircleOwner(circle?.id);

  const isCircleAtCapacity = useMemo(() => {
    return (circle?.maxSlots || 0) <= (circle?.users?.length || 0);
  }, [circle?.maxSlots, circle?.users?.length]);

  useEffect(() => {
    if (!circle || !isCircleAtCapacity) return;

    const isAlreadyHere = !!circle?.users?.find(
      ({ userId }) => userId === user.originalId,
    );

    if (isConnected || isCircleOwner || isAlreadyHere) return;

    toast.warning("The circle you tried to join is full");
    onLeave();
  }, [isCircleOwner, onLeave, isCircleAtCapacity, isConnected, user, circle]);
};
