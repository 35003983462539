import React, { memo } from "react";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { transformImageUrl } from "@src/helpers/image";

export const CircleUserList = memo(
  ({
    users,
    className,
  }: {
    users: { userId: string; avatar: string; username: string }[];
    className?: string;
  }) => {
    return (
      <List className={className}>
        {users.map((user) => (
          <ListItem key={user.userId}>
            <ListItemAvatar>
              <Avatar
                alt={user.username}
                src={transformImageUrl({
                  url: user.avatar,
                  width: 72,
                  height: 72,
                })}
              />
            </ListItemAvatar>
            <ListItemText primary={user.username} />
          </ListItem>
        ))}
      </List>
    );
  },
);
