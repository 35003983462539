import type { ClientCapabilityState, Company } from "../company/company";
import type { EventLocationTarget, EventType } from "../enums/event-type";
import type { HostType } from "../enums/host-type";
import type { Stream } from "../media/stream";

export enum RegistrationType {
  EVENT = "event",
  NETWORKING_HUB = "networking hub",
}
export interface Registration {
  enabled?: boolean;
  terms?: string;
  privacy?: string;
  customUrl?: string;
  customQuestions?: RegistrationQuestion[];
  generalAccessCookiesEnabled?: boolean;
  outsideOfAppEnabled?: boolean;
  isEmailInputEnabled?: boolean;
}

export enum RegistrationQuestionAnswerType {
  TEXT_SHORT = "Short Text",
  TEXT_LONG = "Long Text",
  NUMBER = "Number",
  SELECT = "Select",
}

export const MAX_TEXT_LONG_LENGTH = 250;
export interface RegistrationQuestion {
  id: string;
  type: RegistrationQuestionAnswerType;
  question: string;
  isRequired?: boolean;
}

export interface RegistrationQuestionAnswer {
  questionId: string;
  answer: any;
}

export interface RegisteredAttendeeResponse {
  uid: string;
  name: string;
  email: string;
  join_url: string;
  authToken?: string;
  joinCode?: string;
  metadata?: any;
}

export interface Event {
  uid: string;
  autoSelectLatestReplay?: boolean;
  organizerUid: string;
  creatorUid: string;
  groupUid?: string;
  groupName?: string;
  name: string;
  description?: string;
  location?: string;
  picture?: string;
  thumbnail?: string;
  duration?: number;
  hashtags?: string[];
  startDate: Date;
  endDate: Date;
  timezone: string;
  isEventCancelled: boolean;
  inPersonLink?: string;
  type: EventType;
  target: EventLocationTarget;
  isVendorEvent?: boolean;
  hideParticipants?: boolean;
  isRegistrationModeEnabled: boolean;
  isAttendeeRegistrationModeEnabled: boolean;
  registration?: Registration;
  extraTab?: {
    name: string;
    channelId: string;
  };
  whitelabel?: {
    networkingStarted?: {
      image?: string;
      buttonText?: string;
    };
    hideChat?: boolean;
    showPoweredBy?: boolean;
  };

  networkingHub?: string;
  disableNetworking?: boolean;

  liveEventManagement: EventStatus;

  networkingGroups: NetworkingGroup[];

  streamInfo: {
    streamId: string;
    rtmpForceId?: string;
    virtualMeeting: string;
    streamPath?: string;
    youTubeEndpoint?: string;
    videoLoopUrl?: string;
    rtmpForcePath?: string;
    ownStack?: string;

    privateStreamInfo?: {
      publishUrl: string;
    };
  };

  eventInfo: {
    privateEventInfo?: {
      reminders: string[];
      registrations: string[];
      liveViews: string[];
      onDemandViews: string[];
      reminderUsers?: RegisteredUser[];
      registeredUsers?: RegisteredUser[];
      liveUsers?: RegisteredUser[];
      onDemandUsers?: RegisteredUser[];
    };
    hosts: Host[];
    admins: string[];
    planFeatures?: ClientCapabilityState & {
      maxHosts?: number;
      maxUsers?: number;
    };
  };

  organizerProfile?: Company;
  userIsRegistered: boolean;
  userIsAlwaysPresenter?: boolean;
  totalViews?: number;
  liveViewers?: LiveViewer[];
  rawStream?: Stream;

  ivsStreamInfo?: {
    introvokeStreamKey: string;
    streamPath: string;

    privateInfo?: {
      arn: string;
      channelArn: string;
      injestEndpoint: string;
      streamKey: string;
    };
  };

  presenters?: string[];
  organizers?: string[];
  allowAnonymousAccess?: boolean;
  betaOptIn?: boolean;
  hasLiveSupport?: boolean;
  closeCaptioningSettings?: CloseCaptioningSettings;
}

enum Language {
  English = "en",
}

export type TranscriptionSettings = {
  enabled: boolean;
  language?: Language;
};

export type TranslationSettings = {
  enabled: boolean;
  languages: Language[];
};

export type CloseCaptioningSettings = {
  transcription: TranscriptionSettings;
  translation?: TranslationSettings;
};

export interface NetworkingGroup {
  name: string;
  userIds: string[];
}

export interface LiveViewer {
  userid: string;
  userName: string;
  profilePicture: string;
}

export interface RegisteredUser {
  name: string;
  email: string;
}

export interface Host {
  uid: string;
  userUid?: string;

  name: string;
  company: string;
  title?: string;

  privateHost?: {
    email: string;
  };

  timestamp?: number;
  hostType: HostType;

  picture?: string;

  isApproved?: boolean;
}

export type EventStatus = {
  isEventLive: boolean;
  eventLivestreamStartTime?: string;
  eventLivestreamEndTime?: string;
  isNetworkingLive: boolean;
  isVotingOn: boolean;
  isAnonymousQnaOn?: boolean;
  isApproveQnaOn?: boolean;
  isChatReactionsOn?: boolean;
  isLiveReactionsOn?: boolean;
  networkingStart: string;
  returnToEvent: boolean;
  blockedEmails?: string[];
} & ReplayConfig;

export type ReplayConfig = {
  replayEnabled: boolean;
  replayUrl?: string | null;
  streamPlaceholderMessage?: string | null;
};

export interface CanJoinEventAsRole {
  canJoin: boolean;
  isGraceUser?: boolean;
}
export interface CanJoinEventResponse {
  host: CanJoinEventAsRole;
  presenter: CanJoinEventAsRole;
  attendee: CanJoinEventAsRole;
}
