/** @jsxImportSource @emotion/react */
import escapeRegExp from "lodash/escapeRegExp";
import orderBy from "lodash/orderBy";
import React, { memo, useEffect, useMemo, useState, RefObject } from "react";
import { toast } from "react-toastify";

import { css } from "@emotion/react";
import { Box, Stack, SvgIcon, Theme, useMediaQuery } from "@mui/material";
import { Button, Grid } from "@mui/material";
import { Pagination } from "@mui/material";

import { NetworkingHubRoom } from "../../contracts/networking-hub/NetworkingHubRoom";
import { User, UserRole } from "../../contracts/user/user";
import { NavigationHelper } from "../../helpers/navigation";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { RequiredKeys } from "../../types/RequiredKeys";
import {
  SocketUser,
  useRoomUsers,
  useRooms,
} from "../Presence/IntrovokePresence";
import { PlusIcon } from "../icons";
import { CreateNetworkingCircleDialog } from "./CreateNetworkingCircleDialog";
import { MingleMenu } from "./MingleMenu";
import { NetworkingCircle } from "./NetworkingCircle";
import { useHandleInvite } from "./NetworkingHubInviteProviderNexus";
import { SearchRoomTextField } from "./SearchRoomTextField";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { useIsSessionActive } from "../Presence/SocketClientProvider";
import { CircleSortOrder } from "@src/providers/config";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";

export interface NetworkingHubContentProps {
  user: User;
  eventId: string;
  networkingRooms: RequiredKeys<NetworkingHubRoom, "id">[];
  cleanUI?: boolean;
  filteredRooms?: string[];
  sortCirclesBy: CircleSortOrder;
  onCreateRoom?: (data: {
    name: string;
    slots: number;
    metadata: Record<string, string>;
  }) => Promise<void>;
  hidePagination?: boolean;
  room?: string | null;
  hideRandomUserMingle?: boolean;
  showParticipantNames?: ParticipantNameOption;
  hideCreateCircle?: boolean;
  parentScrollRef?: RefObject<HTMLDivElement>;
}

const bySearch =
  (search: string, users: SocketUser[] = []) =>
  (room: RequiredKeys<NetworkingHubRoom, "id">) => {
    if (search === "") {
      return true;
    }
    const searchRegExp = new RegExp(escapeRegExp(search), "i");
    return Boolean(
      room.name.match(searchRegExp) ||
        users.some((user) => user.meta.username.match(searchRegExp)) ||
        Object.values(room.metadata || {}).some((value) =>
          value.match(searchRegExp),
        ),
    );
  };

const byFixedOrUsersCount =
  (users: SocketUser[] = []) =>
  (entry: NetworkingHubRoom) => {
    return entry.isFixed || users.length > 0;
  };

const byMetadata =
  (metadataSearch: Record<string, string[]>) => (room: NetworkingHubRoom) => {
    return Object.entries(metadataSearch).every(([key, values]) => {
      return (
        values.length > 0 &&
        room.metadata?.[key] &&
        values.includes(room.metadata[key])
      );
    });
  };

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export const NetworkingHubContent = memo(
  ({
    networkingRooms,
    hidePagination = false,
    hideRandomUserMingle,
    showParticipantNames,
    hideCreateCircle,
    sortCirclesBy,
    parentScrollRef,
    ...props
  }: NetworkingHubContentProps) => {
    const isSessionActive = useIsSessionActive();
    const showMingle = useFeatureFlag(FeatureFlag.CIRCLE_MINGLE);
    const roomUsers = useRoomUsers();
    const { setRooms } = useRooms();
    const handleInvite = useHandleInvite();

    const isXS = useMediaQuery<Theme>((theme) => theme.breakpoints.only("xs"));
    const isMobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down("lg"),
    );
    const [page, setPage] = useState(1);

    const [showCreateRoom, setShowCreateRoom] = useState(false);
    const [search, setSearch] = useState("");
    const [metadataSearch] = useState<Record<string, string[]>>({});

    const joinMingleRoom = useLatestCallback(
      (room: RequiredKeys<NetworkingHubRoom, "id">) => {
        if (roomUsers[room.id]?.find((r) => r.meta.userId === props.user.uid)) {
          toast.error("You are already in this room", {
            toastId: "mingleRoomAlreadyJoinedError",
          });
        } else {
          NavigationHelper.navigate(
            undefined,
            `/networkingHub/${encodeURIComponent(
              props.eventId,
            )}/room/${encodeURIComponent(room.id)}`,
            window.location.search,
            true,
          );

          parentScrollRef?.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
      },
    );

    const roomsFilter = props.filteredRooms;

    const filteredRooms = useMemo(
      () =>
        networkingRooms
          .filter((room) => {
            const filters: ((room: NetworkingHubRoom) => boolean)[] = [
              byFixedOrUsersCount(roomUsers[room.id]),
              (r: NetworkingHubRoom) =>
                Boolean(
                  !roomsFilter ||
                    roomsFilter.length === 0 ||
                    (r.metadata &&
                      roomsFilter.every((room) =>
                        Object.values(r.metadata || {}).includes(room),
                      )),
                ),
              bySearch(search, roomUsers[room.id]),
              byMetadata(metadataSearch),
            ];
            return filters.every((filterBy) => filterBy(room));
          })
          .map((room) => ({
            ...room,
            userCount: roomUsers[room.id]?.length || 0,
          })),
      [metadataSearch, networkingRooms, roomUsers, roomsFilter, search],
    );

    const pageSize = isMobile ? 8 : 12;
    const totalPages = Math.ceil(filteredRooms.length / pageSize);
    const currentPage = useMemo(() => {
      return totalPages < page ? totalPages : page;
    }, [page, totalPages]);

    const paginatedRooms = useMemo(() => {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;

      const sortedByUsersCount =
        sortCirclesBy === CircleSortOrder.Active
          ? orderBy(
              filteredRooms,
              ["userCount", "isFixed", "name"],
              ["desc", "desc", "asc"],
            )
          : filteredRooms;

      return hidePagination
        ? sortedByUsersCount
        : sortedByUsersCount.slice(startIndex, endIndex);
    }, [currentPage, pageSize, sortCirclesBy, filteredRooms, hidePagination]);

    useEffect(() => {
      setRooms(
        networkingRooms.map((entry) => {
          return entry.id;
        }),
      );
    }, [networkingRooms, setRooms]);

    const searchTextField = (
      <SearchRoomTextField
        fullWidth={isMobile}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onClear={() => setSearch("")}
      />
    );

    const createCircleButton = (
      <Button
        sx={{
          whiteSpace: "nowrap",
        }}
        fullWidth={isMobile}
        color="primary"
        startIcon={
          <SvgIcon>
            <PlusIcon />
          </SvgIcon>
        }
        variant="outlined"
        onClick={() => {
          setShowCreateRoom(true);
        }}
      >
        Create new circle
      </Button>
    );

    const mingleButton = (
      <MingleMenu
        hideRandomUserMingle={hideRandomUserMingle}
        fullWidth={isMobile}
        onMingleUser={handleInvite}
        onMingleCircle={() => {
          const randomRoom =
            networkingRooms[getRandomInt(networkingRooms.length)];
          joinMingleRoom(randomRoom);
        }}
      />
    );

    return (
      <div
        css={css`
          flex: 1;
          background-color: #f7f8fa;
        `}
      >
        {props.user.userRole === UserRole.Unregistered && (
          <>
            <div
              css={(theme) => css`
                bottom: 0;
                left: 0;
                position: fixed;
                right: 350px;
                top: 0;
                z-index: 9;
                ${theme.breakpoints.down("md")} {
                  display: none;
                }
              `}
            ></div>
          </>
        )}
        <Box margin={2}>
          {isMobile ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {searchTextField}
              </Grid>
              {isSessionActive && (
                <>
                  {!hideCreateCircle && (
                    <Grid item xs={6}>
                      {createCircleButton}
                    </Grid>
                  )}

                  {showMingle && (
                    <Grid item xs={6}>
                      {mingleButton}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <SearchRoomTextField
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onClear={() => setSearch("")}
                />
              </Box>
              {isSessionActive && (
                <Stack flexShrink={0} direction="row" spacing={1}>
                  {!hideCreateCircle && <>{createCircleButton}</>}
                  {showMingle && <>{mingleButton}</>}
                </Stack>
              )}
            </Box>
          )}
        </Box>
        <CreateNetworkingCircleDialog
          onSubmit={async (data) => {
            await props.onCreateRoom?.({
              ...data,
              slots: parseInt(`${data.slots}`, 10),
            });
          }}
          handleClose={() => {
            setShowCreateRoom(false);
          }}
          show={showCreateRoom}
        />

        <Box margin={2}>
          <Box
            css={(theme) => css`
              display: grid;
              padding-bottom: 10px;
              justify-content: center;
              column-gap: ${theme.spacing(1)};
              row-gap: ${theme.spacing(2)};
              grid-template-columns: 1fr;
              ${theme.breakpoints.up("sm")} {
                grid-template-columns: repeat(2, 1fr);
              }
              ${theme.breakpoints.up("md")} {
                grid-template-columns: repeat(auto-fit, 240px);
              }
            `}
          >
            {paginatedRooms.map((room) => (
              <NetworkingCircle
                key={room.id}
                active={room.id === props.room || room.name === props.room}
                userEmail={props.user.email}
                onJoinCircle={joinMingleRoom}
                room={room}
                users={roomUsers[room.id]}
                showParticipantNames={showParticipantNames}
              />
            ))}
          </Box>
          {totalPages > 1 && !hidePagination && (
            <Box marginY={3} display="flex" justifyContent="center">
              <Pagination
                size={isXS ? "small" : undefined}
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => {
                  setPage(value);
                }}
                siblingCount={1}
              />
            </Box>
          )}
        </Box>
      </div>
    );
  },
);

export default NetworkingHubContent;
