import React from "react";
import {
  Checkbox,
  IconButton,
  InputAdornment,
  SvgIcon,
  InputLabel,
  TextField,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { SendIcon } from "../icons";
import {
  QuestionApprovedStatus,
  QuestionCreateInput,
} from "../../providers/qAndA/QandAProvider";
import { Tooltip } from "@mui/material";
import { nanoid } from "nanoid";

const CheckboxStyled = styled(Checkbox)(({ theme: { palette } }) => ({
  marginRight: -12,
  color: palette.divider,
}));

const InputLabelContainer = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  marginTop: spacing(0.5),
}));

const CreateQuestionContainer = styled("form")(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing(0.5),
    paddingLeft: spacing(1.5),
    paddingRight: spacing(1.5),
    [breakpoints.down("sm")]: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
    marginTop: spacing(0.5),
  }),
);

const IconButtonStyled = styled(IconButton)(
  ({ theme: { spacing, palette } }) => ({
    padding: spacing(1),
    "&:hover": {
      backgroundColor: `${palette.primary.main}40`,
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "transparent",
    },
  }),
);

type QuestionForm = { question: string; isAnonymous?: boolean };

interface CreateQuestionGroupProps {
  submitQuestion: (data: QuestionCreateInput) => void;
  isAnonymousQnaEnabled?: boolean;
  isApproveQnaEnabled?: boolean;
}
export const CreateQuestionGroup: React.FC<CreateQuestionGroupProps> = ({
  submitQuestion,
  isAnonymousQnaEnabled = false,
  isApproveQnaEnabled = false,
}) => {
  const { errors, handleSubmit, register, reset, watch, control } =
    useForm<QuestionForm>({
      defaultValues: {
        question: "",
        isAnonymous: false,
      },
    });

  return (
    <CreateQuestionContainer
      onSubmit={handleSubmit(({ question, isAnonymous }: QuestionForm) => {
        isAnonymous = isAnonymousQnaEnabled && !!isAnonymous;
        // approve question by default if approve qna is turned off
        const approved: QuestionApprovedStatus = isApproveQnaEnabled
          ? QuestionApprovedStatus.PENDING
          : QuestionApprovedStatus.APPROVED;
        submitQuestion({
          // create ids locally for optimistic updates
          // could create a fake localId field, but we already create ids locally for anonymous questions
          id: nanoid(),
          name: question,
          isAnonymous,
          approved,
        });
        reset({ question: "", isAnonymous });
      })}
    >
      <TextField
        id="question"
        error={!!errors?.question}
        fullWidth
        helperText={errors.question?.message}
        inputRef={register({ required: "Required" })}
        InputProps={{
          sx: ({ typography, palette }) => ({
            borderRadius: 2,
            fontSize: typography.body1.fontSize,
            minHeight: "1em",
            padding: 0,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            backgroundColor: palette.grey[100],
            borderColor: palette.divider,
            borderStyle: "solid",
            borderWidth: 1,
            "&:hover": {
              backgroundColor: palette.grey[100],
              borderColor: palette.primary.main,
            },
          }),
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButtonStyled
                aria-label="submit question"
                color="secondary"
                disabled={watch("question").length < 5}
                type="submit"
                size="large"
              >
                <SvgIcon>
                  <SendIcon />
                </SvgIcon>
              </IconButtonStyled>
            </InputAdornment>
          ),
        }}
        inputProps={{
          sx: {
            minHeight: "1em",
            padding: 1,
          },
        }}
        placeholder="My question is..."
        name="question"
        required
        variant="filled"
      />

      {isAnonymousQnaEnabled && (
        <InputLabelContainer>
          <Tooltip
            placement="bottom"
            title="Checking this box will allow you to ask questions anonymously by hiding your name from other participants"
          >
            <InputLabel
              sx={{
                color: "inherit",
                marginBottom: 0,
              }}
            >
              Ask anonymously
            </InputLabel>
          </Tooltip>
          <Controller
            name="isAnonymous"
            control={control}
            render={(props) => (
              <CheckboxStyled
                {...props}
                checked={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
                color="primary"
              />
            )}
          />
        </InputLabelContainer>
      )}
    </CreateQuestionContainer>
  );
};
