import invariant from "tiny-invariant";
import uniqBy from "lodash/uniqBy";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Api } from "../../api/api";
import type { ChatUser } from "../../components/Chat/PrivateChat";
import { useSocketClient } from "../../components/Presence/SocketClientProvider";
import { emitAsPromise } from "../../components/Presence/emitAsPromise";
import { ChatUserProfile } from "../../contracts/chat/ChatUserProfile";
import { randomColor } from "../../helpers/randomColor";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { useUser } from "../UserProvider";
import { getChannelConfig } from "./getChannelConfig";
import { removeItemOnce } from "./removeItemOnce";
import { QueryKeys } from "../../api/QueryKeys";
import { useEventChatContext } from "./ChatProvider";

interface PrivateChatInfo {
  chatChannelId: string;
  userIds: string[];
}

interface PrivateChatContextValues {
  isLoading: boolean;
  chats?: {
    id: string;
    users: string[];
  }[];
  profiles: Record<string, ChatUser>;
  handleUserInvite: (
    invitedUserId: string,
  ) => Promise<{ chat: PrivateChatInfo } | undefined>;
}
const PrivateChatContext = createContext<PrivateChatContextValues | null>(null);

export const PrivateChatProvider: React.FC = ({ children }) => {
  const user = useUser();
  const cache = useQueryClient();
  const client = useSocketClient();
  const { subscribe, unsubscribe } = useEventChatContext();

  const handlePrivateChatInvite = useLatestCallback((data) => {
    cache.setQueryData<PrivateChatInfo[]>("private-chats", (value = []) =>
      uniqBy([data.chat, ...value], "chatChannelId"),
    );
    cache.setQueryData<(ChatUserProfile | null)[]>("profiles", (value = []) =>
      uniqBy([...value, ...data.profiles], "userId"),
    );
  });

  useEffect(() => {
    client.on("chat-invite", handlePrivateChatInvite);

    return () => {
      client.off("chat-invite", handlePrivateChatInvite);
    };
  }, [client, handlePrivateChatInvite]);

  const privateChatsQuery = useQuery<PrivateChatInfo[]>(
    "private-chats",
    async () => {
      const data = await Api.ChatApi.GetChats(user.uid);
      return data;
    },
    {
      enabled: Boolean(user.uid),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );

  const privateChats = useMemo(
    () =>
      privateChatsQuery.data?.map((entry) => ({
        ...entry,
        userIds: removeItemOnce(entry.userIds, user.uid),
      })),
    [privateChatsQuery.data, user.uid],
  );

  const userIds = useMemo(() => {
    const userIdsSet = new Set(
      privateChats?.flatMap((data) => data.userIds) || [],
    );
    userIdsSet.delete(user.uid);
    return Array.from(userIdsSet);
  }, [privateChats, user.uid]);

  const userProfilesQuery = useQuery(
    QueryKeys.profiles(),
    async () => {
      const profiles = await Api.ChatApi.GetUserChatProfiles(userIds);
      return profiles;
    },
    {
      enabled: Boolean(privateChats),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (!privateChats) {
      return;
    }
    const unsubscribeChats = privateChats.map((chat) => {
      const config = getChannelConfig({
        type: "private",
        channel: chat.chatChannelId,
      });
      subscribe(config as any);
      return () => {
        unsubscribe(config as any);
      };
    });
    return () =>
      unsubscribeChats.forEach((unsubscribeChat) => unsubscribeChat());
  }, [privateChats, subscribe, unsubscribe]);

  const { refetch, data } = userProfilesQuery;
  useEffect(() => {
    if (data) {
      const fetchedUserProfiles = new Set(
        data.flatMap((profile) => (profile ? [profile.userId] : [])),
      );

      const missing = userIds.some((id) => !fetchedUserProfiles.has(id));
      if (missing) {
        refetch();
      }
    }
  }, [userIds, data, refetch]);

  const { mutateAsync: handleUserInvite } = useMutation(
    async (invitedUserId: string) => {
      const { chat, profiles } = await Api.ChatApi.CreateChat(
        user.uid,
        invitedUserId,
      );
      emitAsPromise(client, "chat-invite", {
        userId: invitedUserId,
        chat,
        profiles,
      });
      return { chat, profiles };
    },
    {
      onSuccess: (result) => {
        cache.setQueryData<PrivateChatInfo[]>("private-chats", (value = []) =>
          uniqBy([result.chat, ...value], "chatChannelId"),
        );
        cache.setQueryData<(ChatUserProfile | null)[]>(
          "profiles",
          (value = []) => uniqBy([...value, ...result.profiles], "userId"),
        );
      },
    },
  );

  const contextValue = useMemo(() => {
    const isLoading = !privateChatsQuery.data || !userProfilesQuery.data;

    return {
      chats:
        !isLoading && privateChats
          ? privateChats.map((chat) => ({
              id: chat.chatChannelId,
              users: chat.userIds,
            }))
          : undefined,
      profiles: Object.fromEntries(
        userProfilesQuery.data
          ?.map(
            (entry, index) =>
              entry || {
                userId: userIds[index],
                username: "Deleted user",
                avatar: `https://ui-avatars.com/api/?background=${randomColor()}&size=128&color=fff&name=U&rounded=true`,
              },
          )
          .map((profile) => [profile.userId, profile]) || [],
      ),
      isLoading,
      handleUserInvite,
    };
  }, [
    handleUserInvite,
    privateChats,
    privateChatsQuery.data,
    userIds,
    userProfilesQuery.data,
  ]);

  return (
    <PrivateChatContext.Provider value={contextValue}>
      {children}
    </PrivateChatContext.Provider>
  );
};

export const ChatLoader: React.FC<{ fallback: React.ReactNode }> = ({
  children,
  fallback,
}) => {
  const ctx = useContext(PrivateChatContext);

  if (ctx?.isLoading || !ctx?.chats) return <>{fallback}</>;

  return <>{children}</>;
};

export const usePrivateChats = () => {
  const ctx = useContext(PrivateChatContext);

  invariant(ctx, "usePrivateChats called outside of PrivateChatContext");

  return ctx;
};
