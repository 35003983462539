import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  alpha,
  FormControlLabel,
  InputLabel,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  UserQuestion,
  QuestionAnswerEventInput,
} from "../../../providers/qAndA/QandAProvider";

export interface QuestionAnswerFormValues {
  questionId: string;
  answer: string;
  markAnswered: boolean;
}

export interface QuestionAnswerDialogProps {
  show: boolean;
  question: UserQuestion;
  handleClose?: () => void;
  onSubmit?: (question: QuestionAnswerEventInput) => void;
}
export const QuestionAnswerDialog: React.FC<QuestionAnswerDialogProps> =
  React.memo(({ show, question = {}, handleClose, onSubmit }) => {
    const { register, errors, handleSubmit, control, reset, formState } =
      useForm<QuestionAnswerFormValues>({
        mode: "onChange",
        reValidateMode: "onChange",
      });

    useEffect(() => {
      const defaultValues = {
        answer: question.answer || "",
        markAnswered: question.answered || false,
      };
      reset(defaultValues);
    }, [question.answer, question.answered, reset]);

    const handleFormSubmit = useCallback(
      (data: QuestionAnswerFormValues) => {
        handleClose?.();

        if (question?.id) {
          const answeredState = {
            id: question.id,
            answer: data.answer,
            answered: data.markAnswered,
          };

          // if data is changed => submit
          if (formState.isDirty) onSubmit?.(answeredState);
        }
      },
      [formState.isDirty, handleClose, onSubmit, question.id],
    );

    const hasErrors = !!errors?.answer;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="answer-question-dialog-title"
        aria-describedby="answer-question-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="answer-question-dialog-title">
          Answer Question
        </DialogTitle>
        <DialogContent>
          {/* asked by */}
          <DialogContentText
            variant="body2"
            color="textSecondary"
            sx={({ typography }) => ({
              marginBottom: 0,
              fontSize: typography.body2.fontSize,
              fontWeight: "normal",
              paddingBottom: ".5em",
            })}
          >
            {`${question?.owner?.username} asked `}
          </DialogContentText>

          {/* question */}
          <DialogContentText
            id="answer-question-dialog-description"
            color="textPrimary"
            variant="h4"
            sx={({ palette }) => ({
              borderLeft: `3px solid ${palette.primary.main}`,
              padding: 1,
              marginBottom: 3,
              backgroundColor: palette.grey[100],
            })}
          >
            {`"${question?.name}"`}
          </DialogContentText>

          {/* answer form */}
          <InputLabel
            sx={({ typography }) => ({
              fontSize: typography.body2.fontSize,
              paddingBottom: ".5em",
            })}
          >
            Your Answer
          </InputLabel>
          <TextField
            id="answer"
            name="answer"
            required
            autoFocus
            fullWidth
            multiline
            error={!!errors?.answer}
            helperText={errors?.answer?.message}
            InputProps={{
              sx: ({ palette, typography, transitions }: Theme) => ({
                border: "1px solid",
                borderColor: palette.common.white,
                borderRadius: 2,
                backgroundColor: "#F2F2F2",
                minHeight: "fit-content",
                fontSize: typography.body1.fontSize,
                overflow: "hidden",
                padding: "1em",
                alignItems: "start",
                transition: transitions.create(["border-color", "box-shadow"]),
              }),
              disableUnderline: true,
              placeholder: "Type Answer...",
            }}
            sx={({ palette }) => ({
              "& .MuiInput-root": {
                "&.Mui-focused": {
                  boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
                  borderColor: palette.primary.main,
                },
              },
            })}
            inputRef={register({
              maxLength: { value: 250, message: "Answer is too long" },
            })}
          />
          <Tooltip title="Marking a question as answered will move it to the answered tab">
            <FormControlLabel
              control={
                <Controller
                  name="markAnswered"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                      color="primary"
                    />
                  )}
                />
              }
              label="Mark question as answered"
            />
          </Tooltip>
        </DialogContent>
        <DialogActions sx={{ paddingLeft: 3, paddingRight: 3 }}>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            data-test-id="btn-answer-question"
            color="secondary"
            variant="contained"
            disabled={hasErrors}
            onClick={handleSubmit(handleFormSubmit)}
          >
            {question.answer ? "Edit Answer" : "Answer"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
