import { Typography, useTheme } from "@mui/material";
import React from "react";
import {
  RegistrationQuestion,
  MAX_TEXT_LONG_LENGTH,
} from "../../contracts/event/event";
import { TextInputLiveFeedback } from "../Forms/TextInputLiveFeedback";

interface Props {
  /**
   * The custom registration questions to render as input fields
   */
  customQuestions: RegistrationQuestion[];

  /**
   * The form object with contains the values associated with each input field => used for validation
   * TODO add type and limit checking
   */
  formValues: any;
}
function CustomQuestions({ customQuestions, formValues }: Props) {
  const theme = useTheme();
  return (
    <>
      {customQuestions.map((question, i) => (
        <div
          key={question.id}
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: theme.spacing(2),
          }}
        >
          <TextInputLiveFeedback
            key={question.id}
            label={question.question}
            id={question.id}
            name={question.id}
            placeholder="Enter your answer"
            inputProps={{
              maxLength: MAX_TEXT_LONG_LENGTH,
            }}
          />
          <Typography
            variant="caption"
            style={{
              textAlign: "end",
              color: theme.palette.text.secondary,
            }}
          >{`${
            formValues[question.id].length
          }/${MAX_TEXT_LONG_LENGTH}`}</Typography>
        </div>
      ))}
    </>
  );
}

export default React.memo(CustomQuestions);
