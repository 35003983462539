import { Reaction } from "@src/contracts/customization/reactions";
import Pubnub from "pubnub";
import { ChannelConfig } from "../pubnub/PubNubProvider";
import type { Message, MessageAction } from "./ChatProvider";

export const PINS_CHANNEL_SUFFIX = "-pins";
export const ANNOUNCEMENTS_CHANNEL_SUFFIX = "-announcements";

export const deleteMessageActionUpdater = (
  messages: Message[],
  event: Pubnub.MessageActionEvent,
) => {
  return messages.filter(
    (message) => message.timetoken !== event.data.messageTimetoken,
  );
};

export const reactionMessageActionUpdater = (
  messages: Message[],
  event: Pubnub.MessageActionEvent,
) => {
  return messages.map((message) => {
    // return other messages as is
    if (message.timetoken !== event.data.messageTimetoken) return message;

    const updatedMessage = { ...message };

    // add actions if message doesn't have any actions yet
    updatedMessage.actions = updatedMessage.actions || {};
    updatedMessage.actions.reactions =
      updatedMessage.actions?.reactions || ({} as MessageAction<Reaction>);

    // if adding new reaction
    if (event.event === "added") {
      const newReaction = {
        uuid: event.data.uuid,
        actionTimetoken: event.data.actionTimetoken,
      };

      updatedMessage.actions.reactions[event.data.value as Reaction] = [
        ...(updatedMessage.actions.reactions[event.data.value as Reaction] ??
          []),
        newReaction,
      ];
    }

    // if removing reaction
    else if (event.event === "removed") {
      updatedMessage.actions.reactions[event.data.value as Reaction] =
        updatedMessage.actions.reactions[event.data.value as Reaction]?.filter(
          (reaction) => reaction.actionTimetoken !== event.data.actionTimetoken,
        ) || [];
    }

    return { ...updatedMessage };
  });
};

/**
 * Gets all known channels based on the passed channel config, taking in account
 * any special channels which may be associated with an event, hub, etc
 *
 * @param config The ChannelConfig which contains the channel and information
 * @returns An array of channel ID's associated with the configuration
 */
export const getChannelsFromConfig = (config: ChannelConfig) => {
  const channelId = config.channel;
  let channels = [channelId];

  if (config.pinnable)
    channels = [...channels, `${channelId}${PINS_CHANNEL_SUFFIX}`];

  if (config.hasAnnouncements)
    channels = [...channels, `${channelId}${ANNOUNCEMENTS_CHANNEL_SUFFIX}`];

  return channels;
};
