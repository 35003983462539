import { Api } from "@src/api/api";
import { useQuery } from "react-query";

/**
 * Returns an access token for the compatibility checker conference call
 *
 * @params options additional options for the hook
 * @returns An object containing the access token and flags indicating loading/error states
 */
export const useCompatibilityCheckerToken = (
  options: { disabled?: boolean } = {},
) => {
  const { disabled = false } = options;
  const { data, isFetching, isError } = useQuery(
    ["CompatibilityToken"],
    () => Api.UserApi.getCompatibilityToken(),
    {
      retry: true,
      enabled: !disabled,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading: isFetching,
    isError,
    accessToken: data?.accessToken,
    configUrl: data?.configUrl,
    configJson: data?.configJson || null,
    conferenceId: data?.conferenceId,
  };
};

/**
 * Returns the config for the compatibility checker conference call
 *
 * @params options additional options for the hook
 * @returns A config url and flags indicating loading/error states
 */
export const useCompatibilityCheckerConfigUrl = (
  options: { disabled?: boolean } = {},
) => {
  const { disabled = false } = options;
  const { data, isFetching, isError } = useQuery(
    ["CompatibilityConfigUrl"],
    () => Api.EventApi.GetConfigUrl(),
    {
      retry: true,
      enabled: !disabled,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading: isFetching,
    isError,
    data,
  };
};
