import React, { memo, useEffect, useState } from "react";
import invariant from "tiny-invariant";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useEvent } from "@src/providers/EventProvider";
import EventInfoCountDown from "./EventInfoCountdown";
import {
  useEventState,
  useIsEventLive,
} from "@src/providers/EventStateProvider";
import { SvgIcon } from "@mui/material";
import { NetworkingHubIcon } from "@src/components/icons";
import EndNetworkingButton from "./EndNetworkingButton";
import { LeaveStageButton } from "./LeaveStageButton";
import { EndEventButton } from "./EndEventButton";
import { StartEventButton } from "./StartEventButton";
import OutlineButton from "@src/components/buttons/OutlineButton";
import JoinNetworkingHubButton from "@src/components/Event/EventStage/Header/JoinNetworkingHubButton";
import JoinGreenroomButton from "@src/components/Event/EventStage/Header/JoinGreenroomButton";
import { useShowHeaderActions } from "@src/components/Event/EventStage/Header/hooks/useShowHeaderActions";

interface Props {
  onEnterStage?: () => void;
  onEventStart?: () => void;
  onEventEnd?: () => void;
  onNetworkingStart?: () => void;
  onNetworkingEnd?: (returnToEvent: boolean) => void;
  isStreamLive: boolean;
  isGoLiveDisabled?: boolean;
}

enum LoadingState {
  NONE,
  HUB_START,
  HUB_END,
  EVENT_START,
  EVENT_END,
}

const EventActions = ({
  onEnterStage,
  onEventEnd,
  onEventStart,
  onNetworkingEnd,
  onNetworkingStart,
  isStreamLive,
  isGoLiveDisabled,
}: Props) => {
  const showHeaderActions = useShowHeaderActions({
    onEnterStage: onEnterStage,
    isStreamLive,
    isStage: true,
  });
  const isEventLive = useIsEventLive();

  const { isNetworkingLive, leaveStage } = useEventState();
  const { data: event } = useEvent();
  invariant(event, "Event is required");

  const [loading, setLoading] = useState<LoadingState>(LoadingState.NONE);

  const hasNetworking = Boolean(event.networkingHub);

  const handleNetworkingStart = () => {
    if (isNetworkingLive) return;
    setLoading(LoadingState.HUB_START);
    onNetworkingStart?.();
  };

  const handleNetworkingEnd = (returnToEvent: boolean) => {
    if (!isNetworkingLive) return;
    setLoading(LoadingState.HUB_END);
    onNetworkingEnd?.(returnToEvent);
  };

  useEffect(() => {
    setLoading((loading) =>
      loading === LoadingState.HUB_END || loading === LoadingState.HUB_START
        ? LoadingState.NONE
        : loading,
    );
  }, [isNetworkingLive]);

  useEffect(() => {
    setLoading((loading) =>
      loading === LoadingState.EVENT_END || loading === LoadingState.EVENT_START
        ? LoadingState.NONE
        : loading,
    );
  }, [isEventLive]);

  const isLoading = loading !== LoadingState.NONE;

  return (
    <Stack direction="row" spacing={1} justifyContent="center" flexWrap="wrap">
      {showHeaderActions.showActivatePreNetworking && (
        <OutlineButton
          disabled={isNetworkingLive || isLoading}
          loading={loading === LoadingState.HUB_START}
          onClick={handleNetworkingStart}
          startIcon={
            <SvgIcon>
              <NetworkingHubIcon />
            </SvgIcon>
          }
        >
          Activate Pre-Networking
        </OutlineButton>
      )}
      {showHeaderActions.showJoinNetworkingHub && (
        <JoinNetworkingHubButton disabled={isLoading} />
      )}
      {showHeaderActions.showJoinGreenroom && (
        <JoinGreenroomButton
          onEnterStage={onEnterStage as () => void}
          isStreamLive={isStreamLive}
        />
      )}
      {showHeaderActions.showEndNetworking && (
        <EndNetworkingButton
          buttonLocationId="stage-header"
          loading={loading === LoadingState.HUB_END}
          disabled={isLoading}
          onConfirm={handleNetworkingEnd}
          returnToEventId={event.uid}
        />
      )}
      {showHeaderActions.showLeaveStage && (
        <LeaveStageButton onConfirm={leaveStage} />
      )}
      {showHeaderActions.showStartEvent && (
        <StartEventButton
          loading={loading === LoadingState.EVENT_START}
          onConfirm={() => {
            setLoading(LoadingState.EVENT_START);
            onEventStart?.();
          }}
          goLiveLocked={event.goLiveLocked}
          disabled={isGoLiveDisabled || isLoading}
        />
      )}
      {showHeaderActions.showEndEvent && (
        <EndEventButton
          isNetworkingLive={isNetworkingLive}
          loading={loading === LoadingState.EVENT_END}
          disabled={isLoading}
          onConfirm={(startNetworking: boolean) => {
            setLoading(LoadingState.EVENT_END);
            onEventEnd?.();
            if (startNetworking) {
              handleNetworkingStart?.();
            }
          }}
          hasNetworking={hasNetworking}
        />
      )}
    </Stack>
  );
};

const HeaderContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: theme.palette.grey[200],
  flex: 0,
}));

const StageHeader = ({ isStreamLive, isGoLiveDisabled, ...actions }: Props) => {
  const { data: event } = useEvent();
  invariant(event, "Event is required");

  return (
    <HeaderContainer
      justifyContent="space-between"
      direction={{ xs: "column", sm: "row" }}
      padding={1}
      paddingRight={{ xs: 1, sm: 3 }}
      textAlign={{ xs: "center", sm: "left" }}
    >
      <EventInfoCountDown isStreamLive={isStreamLive} />
      <Stack
        direction="column"
        spacing={1}
        alignItems={{ xs: "center", sm: "flex-end" }}
        justifyContent="center"
        paddingLeft={{ xs: 1, sm: 3 }}
      >
        <EventActions
          isStreamLive={isStreamLive}
          isGoLiveDisabled={isGoLiveDisabled}
          {...actions}
        />
      </Stack>
    </HeaderContainer>
  );
};

export default memo(StageHeader);
