import React from "react";

import { User } from "../../contracts/user/user";
import { GettingStarted } from "../GettingStartedWizard/GettingStarted";

interface Props {
  user: User;
}

export const ProfileModal: React.FC<Props> = ({ user }) => {
  return <GettingStarted user={user} />;
};
