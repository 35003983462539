/** @jsxImportSource @emotion/react */
import React from "react";

import { css } from "@emotion/react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

import defaultImage from "../../static/networking-image.svg";
import { ActionButton } from "../buttons/ActionButton";
import { BaseButton } from "../buttons/BaseButton";
import { CloseIcon } from "../icons";

interface NetworkingModalProps {
  show: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const NetworkingStoppedDialog: React.FC<NetworkingModalProps> = ({
  show,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog
      open={show}
      fullWidth
      css={(theme) =>
        css`
          .MuiDialog-paper {
            max-width: 720px;
          }
        `
      }
    >
      <DialogContent
        css={(theme) => css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Box display="flex" justifyContent="flex-end" marginBottom={3}>
          <IconButton color="secondary" onClick={onCancel} size="large">
            <SvgIcon>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Box>

        <Box
          marginBottom={3}
          display="flex"
          justifyContent="center"
          css={({ customTheme }) =>
            css`
              background-image: url(${customTheme?.networkingHub?.returnToStage
                ?.imageUrl || defaultImage});
              background-repeat: no-repeat;
              background-position: center;
              flex: 1 1 200px;
            `
          }
        />

        <Box marginBottom={3}>
          <Typography variant="h3" align="center" color="secondary">
            The organizer is starting the virtual stage soon. Press below to
            return.
          </Typography>
        </Box>

        <Stack
          spacing={1}
          marginBottom={3}
          display="flex"
          justifyContent="center"
        >
          <BaseButton onClick={onCancel}>Not now</BaseButton>
          <ActionButton onClick={onConfirm}>Go to virtual stage</ActionButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
