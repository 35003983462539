import React, {
  memo,
  useCallback,
  useState,
  forwardRef,
  ForwardedRef,
} from "react";
import { intervalToDuration, isAfter, differenceInHours } from "date-fns";

import {
  Typography,
  Box,
  Popover,
  CircularProgress,
  styled,
  Stack,
} from "@mui/material";

import { ConfirmButton } from "../buttons/ConfirmButton";
import { DangerButton } from "../buttons/DangerButton";
import { BaseButton } from "../buttons/BaseButton";
import { useInterval, useLatest } from "react-use";

const Container = styled("div")(({ theme: { palette, spacing } }) => ({
  display: "flex",
  alignItems: "center",
  padding: spacing(2),
  backgroundColor: palette.common.white,
}));

const RecordingBadge = styled(Typography)(
  ({ theme: { palette, spacing } }) => ({
    flexShrink: 0,
    fontSize: "0.7em",
    textTransform: "uppercase",
    padding: spacing(0.25),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    borderWidth: 1,
    borderRadius: spacing(0.5),
    borderStyle: "solid",
    borderColor: palette.red?.[400],
    color: palette.common.white,
    backgroundColor: palette.red?.[400],
    marginRight: spacing(1),
  }),
);

interface Props {
  circleName: string;
  isRecording: boolean;
  isRecordingActivating: boolean;
  circleExpiration?: number;
  recordingEnabled: boolean;
  isRecordingDisabled?: boolean;
  onToggleRecording: () => void;
}

const formatCountdown = (circleExpiration: number) => {
  const now = new Date();
  const expire = new Date(circleExpiration);

  if (isAfter(now, expire)) return "Time is up!";

  const hours = differenceInHours(expire, now);
  const duration = intervalToDuration({
    start: new Date(),
    end: new Date(circleExpiration),
  });
  return `${String(hours).padStart(2, "0")}:${String(duration.minutes).padStart(
    2,
    "0",
  )}:${String(duration.seconds).padStart(2, "0")}`;
};

const Countdown = memo(
  ({ circleExpiration }: { circleExpiration: Props["circleExpiration"] }) => {
    const [circleTime, setCircleTime] = useState("");
    const latestExpiration = useLatest(circleExpiration);

    const onCircleTimeUpdate = useCallback(() => {
      setCircleTime(formatCountdown(latestExpiration.current as number));
    }, [latestExpiration]);

    useInterval(onCircleTimeUpdate, 1000);

    return (
      <Typography data-testid="circle-header-expire-time">
        {circleTime}
      </Typography>
    );
  },
);

const NetworkingHubCircleHeader = forwardRef(
  (
    {
      circleName,
      isRecording,
      isRecordingActivating,
      circleExpiration,
      recordingEnabled,
      isRecordingDisabled,
      onToggleRecording,
    }: Props,
    ref?: ForwardedRef<HTMLDivElement>,
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const popoverOpen = Boolean(anchorEl);
    const id = popoverOpen ? "record-circle-popover" : undefined;

    const onOpenPopover = useCallback(({ target }) => {
      setAnchorEl(target);
    }, []);

    const onClosePopover = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const onToggle = useCallback(() => {
      onClosePopover();
      onToggleRecording();
    }, [onToggleRecording, onClosePopover]);

    return (
      <Container ref={ref} data-testid="circle-header">
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          marginRight={1}
          overflow="hidden"
          alignItems="flex-start"
        >
          <Typography
            variant="h3"
            noWrap
            title={circleName}
            sx={{ width: "100%" }}
          >
            {circleName}
          </Typography>
          <Box display="flex">
            {isRecording && <RecordingBadge>Recording</RecordingBadge>}
            {!!circleExpiration && (
              <Countdown circleExpiration={circleExpiration} />
            )}
          </Box>
        </Box>
        {!!recordingEnabled && (
          <Box>
            {isRecording ? (
              <DangerButton
                data-testid="circle-header-stop-recording-btn"
                aria-describedby={id}
                onClick={onOpenPopover}
              >
                End Recording
              </DangerButton>
            ) : (
              <ConfirmButton
                data-testid="circle-header-start-recording-btn"
                aria-describedby={id}
                disabled={isRecordingActivating || isRecordingDisabled}
                onClick={onOpenPopover}
                startIcon={
                  isRecordingActivating && (
                    <CircularProgress size="1rem" color="inherit" />
                  )
                }
              >
                Record Session
              </ConfirmButton>
            )}
            <Popover
              id={id}
              open={popoverOpen}
              anchorEl={anchorEl}
              onClose={onClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box padding={2}>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {isRecording
                    ? "Are you sure you want to stop recording this session?"
                    : "Are you sure you want to record this session?"}
                </Typography>
                <Stack
                  marginTop={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <BaseButton
                    color="secondary"
                    onClick={onClosePopover}
                    sx={({ spacing }) => ({
                      marginRight: spacing(1),
                    })}
                  >
                    Cancel
                  </BaseButton>
                  {isRecording ? (
                    <DangerButton
                      data-testid="circle-header-stop-recording-confirm-btn"
                      onClick={onToggle}
                    >
                      End Recording
                    </DangerButton>
                  ) : (
                    <ConfirmButton
                      data-testid="circle-header-start-recording-confirm-btn"
                      onClick={onToggle}
                    >
                      Yes, Start Recording
                    </ConfirmButton>
                  )}
                </Stack>
              </Box>
            </Popover>
          </Box>
        )}
      </Container>
    );
  },
);

export default memo(NetworkingHubCircleHeader);
