import { useDeepCompareEffect, useUnmountEffect } from "@react-hookz/web";
import { MetricsClient } from "./MetricsClient";
import {
  MetricsConfig,
  MetricsUser,
  MetricsEventData,
  ObjectType,
  IMetricsEvent,
} from "./common";
import { useState } from "react";

export interface UseInitializeMetricsArgs extends MetricsConfig {
  user?: MetricsUser;
}

/**
 * Hook that creates, initializes and updates the MetricsClient with the latest provided configuration.
 *
 * _NOTE_: This hook should ONLY be called ONCE in the root component of your application.
 */
export const useInitializeMetrics = ({
  projectApiKey: apiKey,
  user,
  disabled,
  instanceId,
  ...restProps
}: UseInitializeMetricsArgs) => {
  const [isInitialized, setIsInitialized] = useState(false);
  // init Amplitude client
  useDeepCompareEffect(() => {
    const instance = MetricsClient.getInstance();
    instance.initialize({
      projectApiKey: apiKey,
      disabled,
      instanceId,
      ...restProps,
    });
    setIsInitialized(instance.isInitialized());
  }, [apiKey, disabled, instanceId, restProps]);

  // init Amplitude user if passed
  useDeepCompareEffect(() => {
    if (user) {
      MetricsClient.getInstance().setUser(user);
    }
  }, [apiKey, disabled, user]);

  useUnmountEffect(() => {
    MetricsClient.getInstance().setUser(null);
  });

  return isInitialized;
};

/**
 * Wrapper function to get `MetricsClient.logEvent`.
 *
 * _NOTE_: that this is a generic logEvent function. Be sure to add types when logging events for better typesafety.
 */
export const logEvent = <
  TGroup extends string = string,
  TData extends ObjectType = ObjectType,
  TAdditionalData extends ObjectType = ObjectType, // allow for separate data from event.data
>(
  event: IMetricsEvent<TGroup, TData>,
  additionalData: MetricsEventData<TAdditionalData> = {} as MetricsEventData<TAdditionalData>,
) => {
  MetricsClient.getInstance().logEvent(event, additionalData);
};

/**
 * Wrapper function that gets `MetricsClient.logEvent` with data appended to each call
 * @param commonData data to be appended to every `logEvent` call
 *
 * _NOTE_: that this is a generic createLogEvent function. Be sure to add types when logging events for better typesafety.
 */
export const createLogEvent = <
  TGroup extends string = string,
  TCommon extends ObjectType = ObjectType,
>(
  commonData: MetricsEventData<TCommon> = {} as MetricsEventData<TCommon>,
) => {
  const log = <
    IGroup extends TGroup = TGroup,
    TData extends ObjectType = ObjectType,
    TAdditionalData extends ObjectType = ObjectType,
  >(
    event: IMetricsEvent<IGroup, TData>,
    additionalData: MetricsEventData<TAdditionalData> = {} as MetricsEventData<TAdditionalData>,
  ) => {
    // simple merge common and additional data
    logEvent(event, { ...commonData, ...additionalData });
  };

  return log;
};

/**
 * Function to be used to create typesafe MetricsEvent map objects. By default it will accept any
 * object with IMetricsEvent values but you should pass in your custom MetricsEvent type for better typesafety.
 *
 * ex:
 * ```typescript
 * type DashboardEvent = IMetricsEvent<"dashboard">
 * const DashboardEvents = createMetricsEventMap<DashboardEvent>()({
 *  someEvent: {
 *    event: "some event",
 *    group: "dashboard" // ensure typesafety here
 *  }
 * })
 *
 * DashboardEvents.someEvent // and get intellisense here
 * ```
 */
export const createMetricsEventMap =
  <TMetricsEvent extends IMetricsEvent>() =>
  <T extends Record<keyof T, TMetricsEvent>>(arg: T) =>
    arg;
