import React from "react";
import invariant from "tiny-invariant";

import { TabTitle } from "../TabTitle";
import { useEvent } from "../../providers/EventProvider";
import { useEventState } from "../../providers/EventStateProvider";
import EditReplayContent from "./EditReplayContent";
import { Box, styled } from "@mui/material";

export interface EditReplayModalProps {
  onClose?: () => void;
}

const Modal = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "min(100vh, 500px)",
  maxHeight: "min(100vh, 500px)",
}));

export const EditReplayModal: React.FC<EditReplayModalProps> = ({
  onClose,
}) => {
  const eventState = useEventState();
  const { data: event } = useEvent();
  invariant(event, "useEvent called without an existing event Uid");

  return (
    <Modal>
      <Box
        sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}
      >
        <TabTitle>{"Edit Session Ended Experience"}</TabTitle>
      </Box>
      {
        <EditReplayContent
          event={event}
          eventState={eventState}
          onFormSave={onClose}
        />
      }
    </Modal>
  );
};
