import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";

import { User, UserRole } from "../../contracts/user/user";
import {
  Message,
  User as MessageUser,
} from "../../providers/chat/ChatProvider";
import { MessageBox } from "./MessageBox";
import { useBlockedEmails } from "../../providers/EventProvider";
import { Poll } from "../../providers/polls/PollProvider";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { Reaction } from "@src/contracts/customization/reactions";

import { MenuItem, ChatItemMenu } from "./ChatItemMenu";
import AutoScroll from "../AutoScroll";
import { useIsRoute, Route } from "@src/hooks/useIsRoute";

interface ChatCommentsProps {
  welcomeMessage?: React.ReactNode;
  messages: (Message | Poll)[];
  user: User;
  showTimestamp?: boolean;
  welcomeOwner: string;
  ownerEmails?: string[];
  showParticipantNames?: ParticipantNameOption;
  showReactions?: boolean;
  onPin: ((message: Message) => void) | null;
  onDelete: (message: Message) => void;
  onBlock: (user: MessageUser) => void;
  onReact: ((message: Message, reaction: Reaction) => Promise<void>) | null;
}

export interface MappedMessage extends Message {}

export const ChatComments = ({
  welcomeMessage,
  welcomeOwner,
  onPin,
  onDelete,
  onBlock,
  onReact,
  ownerEmails,
  showParticipantNames,
  showReactions,
  ...props
}: ChatCommentsProps) => {
  const isEvent = useIsRoute(Route.EVENT);
  const blockedEmails = useBlockedEmails();
  const [selectedMessage, setSelectedMessage] = useState<
    MappedMessage | undefined | null
  >(null);
  const isHost = props.user.userRole === UserRole.Organizer;
  const isCircleOwner = useMemo(
    () => ownerEmails?.includes(props.user.email),
    [props.user.email, ownerEmails],
  );

  const menuItems = useMemo<MenuItem[]>(() => {
    if (
      !selectedMessage ||
      // hide menu for non-event organizers
      (!isHost && !isCircleOwner) ||
      // exclude Polls from having menu options
      "owner" in selectedMessage
    ) {
      return [];
    }

    const message = selectedMessage as Message;

    // Check if we can block, they need an email
    // Try to use the email from the message, if not available, get from ID
    const { uid = "", email } = message.user;
    const uidEmail = uid.split("|||")[1];
    const blockEmail = email || uidEmail;

    const showBlock =
      isEvent &&
      isHost &&
      blockEmail &&
      props.user.uid !== uid &&
      !(blockedEmails || []).includes(blockEmail);

    const menu: MenuItem[] = [];

    if (onPin) {
      menu.push({
        label: "Pin",
        onClick: () => onPin(message),
      });
    }

    if (showBlock) {
      menu.push({
        label: "Block user",
        color: "danger",
        onClick: () =>
          onBlock({
            ...message.user,
            name: message.message.name,
            email: blockEmail,
          }),
      });
    }
    menu.push({
      label: "Delete",
      color: "danger",
      onClick: () => onDelete(message),
    });

    return menu;
  }, [
    isHost,
    isCircleOwner,
    selectedMessage,
    onPin,
    onDelete,
    onBlock,
    props.user.uid,
    blockedEmails,
    isEvent,
  ]);
  const handleClose = () => {
    setContextMenu(null);
    setSelectedMessage(null);
  };
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const handleOpenMenu = useCallback(
    (
      event: MouseEvent<HTMLButtonElement>,
      message: MappedMessage | undefined,
    ) => {
      event.preventDefault();
      setContextMenu((prev) =>
        prev === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      );
      setSelectedMessage(message);
    },
    [],
  );

  return (
    <Box position="relative" display="flex" flex="1" overflow="hidden">
      <AutoScroll
        listSx={{
          position: "relative",
          pt: 1,
          px: { xs: 1, sm: 2 },
          overflowX: "hidden",
          // these are old styles from a scrollbar class in the index.css
          // we can probably remove this later
          "&::-webkit-scrollbar": {
            width: 6,
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            boxShadow: "inset 0 0 2px rgba(0, 0, 0, 0.1)",
            background: "gray",
          },
        }}
      >
        {props.messages.length === 0 ? (
          typeof welcomeMessage === "string" ? (
            <MessageBox
              senderId={welcomeOwner || ""}
              timestamp={new Date().toISOString()}
              isOwn={false}
              message={welcomeMessage || "Welcome to the chat!"}
              username={welcomeOwner || ""}
              userId={props.user.uid}
            />
          ) : (
            welcomeMessage
          )
        ) : (
          props.messages.map((item, idx) => {
            const isPending = !!item.pending;
            const showMenu = !isPending && (isHost || isCircleOwner);
            const key = "message" in item ? item.message.uid : item.id;
            // If we have a bad message don't render it
            if (!key) return null;
            return (
              <MessageOrPollBox
                key={key}
                item={item}
                onReact={onReact}
                showTimestamp={props.showTimestamp}
                userId={props.user.uid}
                ownerEmails={ownerEmails}
                showParticipantNames={showParticipantNames}
                showReactions={!isPending && showReactions}
                isPending={isPending}
                onMenuClick={showMenu ? handleOpenMenu : undefined}
              />
            );
          })
        )}
      </AutoScroll>
      <ChatItemMenu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        items={menuItems}
      />
    </Box>
  );
};

interface MessageOrPollBoxProps
  extends Pick<ChatCommentsProps, "showTimestamp"> {
  item: MappedMessage | Poll;
  userId: string;
  ownerEmails?: string[];
  showParticipantNames?: ParticipantNameOption;
  showReactions?: boolean;
  onReact?: ((message: Message, reaction: Reaction) => void) | null;
  isPending?: boolean;
  onMenuClick?: (
    event: MouseEvent<HTMLButtonElement>,
    message: MappedMessage | undefined,
  ) => void;
}

const MessageOrPollBox = React.memo(
  ({
    item,
    showTimestamp,
    userId,
    ownerEmails,
    showParticipantNames,
    showReactions,
    onReact,
    isPending,
    onMenuClick,
  }: MessageOrPollBoxProps) => {
    if ("message" in item) {
      const { message, user } = item;

      return (
        <MessageBox
          senderId={user.uid}
          userId={userId}
          avatar={user.profilePicture}
          isOwn={message.userUid === userId}
          onMenuClick={onMenuClick}
          message={message.comment}
          item={item}
          onReact={onReact ?? undefined}
          showTimestamp={showTimestamp}
          timestamp={message.timestamp}
          username={message.name}
          userRole={user.role}
          isAnonymous={
            showParticipantNames === ParticipantNameOption.NONE ||
            (showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
              user.role !== UserRole.Organizer &&
              !ownerEmails?.includes(user.email))
          }
          showParticipantNames={showParticipantNames}
          showReactions={showReactions}
          isPending={isPending}
        />
      );
    } else {
      const { createdAt, updatedAt, owner } = item;

      // render recent event Polls in the chat list
      return (
        <MessageBox
          senderId={owner.userId}
          userId={userId}
          avatar={owner.avatar}
          isOwn={owner.userId === userId}
          poll={item}
          showTimestamp={false}
          timestamp={updatedAt || createdAt}
          username={owner.username}
          userRole={owner.userRole}
        />
      );
    }
  },
);
