import React, { memo, useEffect } from "react";
import { useField } from "formik";
import { Box, CircularProgress, Typography } from "@mui/material";

import {
  isRecentlyUploadedReplayUrl,
  useEventReplaysContext,
} from "../../providers/EventReplaysProvider";
import ReplaysPreviewList from "./ReplaysPreviewList";

export const LoadingMessage = memo(() => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4">Loading available replays...</Typography>
      <CircularProgress sx={{ mt: 1 }} />
    </Box>
  );
});

const VideoReplaysTab = () => {
  const { loadingReplays, replayUrls, updatedAt } = useEventReplaysContext();
  const [{ value: currentVodPath }, , helpers] = useField<string>("replayUrl");

  useEffect(() => {
    const recentlyUploadedReplay = replayUrls.find((urlInfo) =>
      isRecentlyUploadedReplayUrl(urlInfo.url),
    )?.url;

    // if we have a recently uploaded replay that isn't currently selected
    if (
      recentlyUploadedReplay?.length &&
      !isRecentlyUploadedReplayUrl(currentVodPath)
    ) {
      // select the recently uploaded replay
      helpers.setTouched(true);
      helpers.setValue(recentlyUploadedReplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAt]);

  return (
    <Box flexGrow={1}>
      {loadingReplays && !replayUrls.length && <LoadingMessage />}
      {!loadingReplays && !replayUrls.length ? (
        <Box margin={4}>
          <Typography>
            No on-demand replays available. After you end your event, the
            streams will be available to select here.
          </Typography>
        </Box>
      ) : (
        <ReplaysPreviewList />
      )}
    </Box>
  );
};

export default memo(VideoReplaysTab);
