import React, { memo, useCallback, useState } from "react";
import throttle from "lodash/throttle";
import { Divider, styled } from "@mui/material";

import { User } from "../../contracts/user/user";
import { uuidv4 } from "../../models/fakedata";
import { ChatMode, useChatChannel } from "../../providers/chat/useChatChannel";
import { TabTitle } from "../TabTitle";
import { ChatComments } from "./ChatComments";
import { ChatInput } from "./ChatInput";
import { ErrorDialog } from "../ErrorDialog";
import { useEventManagement } from "../../providers/EventProvider";
import {
  EventSizes,
  User as MessageUser,
} from "@src/providers/chat/ChatProvider";
import { BlockUserDialog } from "../BlockUserDialog";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { ChannelConfig } from "../../providers/pubnub/PubNubProvider";
import PinnedMessages from "./PinnedMessages";
import {
  FeatureFlag,
  useFeatureFlag,
} from "../../providers/FeatureFlagsProvider";
import { useParticipantCount } from "@src/providers/pubnub/usePubNubHereNow";
import { useLatest } from "react-use";

const ChatContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  background: theme.customTheme?.chat?.colors?.[0] || "#ffffff",
  overflowX: "hidden",
}));

export const calculateChatMode = (participantCount: number): number => {
  if (participantCount > EventSizes.LARGE) return ChatMode.SLOWEST;
  if (participantCount > EventSizes.MEDIUM) return ChatMode.SLOWER;
  if (participantCount > EventSizes.SMALL) return ChatMode.SLOW;
  return ChatMode.DEFAULT;
};

interface ChatProps {
  hidePollPreviews?: boolean;
  title?: React.ReactNode;
  label?: string;
  user: User;
  welcomeMessage?: React.ReactNode;
  className?: string;
  channelConfig: ChannelConfig;
  disableInput?: boolean;
  showTimestamp?: boolean;
  welcomeOwner: string;
  ownerEmails?: string[];
  showParticipantNames?: ParticipantNameOption;
  showReactions?: boolean;
}

export const Chat = memo(
  ({
    hidePollPreviews = false,
    title = false,
    user,
    welcomeMessage,
    className,
    channelConfig,
    label,
    disableInput,
    welcomeOwner,
    showTimestamp,
    ownerEmails,
    showParticipantNames,
  }: ChatProps) => {
    const participantCount = useParticipantCount();
    const {
      messagesAndPolls,
      sendMessage,
      deleteMessage,
      pinMessage,
      unpinMessage,
      pinnedMessages,
      chatReactionsEnabled,
      addReaction,
    } = useChatChannel(
      channelConfig,
      hidePollPreviews,
      calculateChatMode(participantCount),
    );

    const { blockEmails } = useEventManagement();
    const [showBlockDialog, setShowBlockDialog] = useState(false);
    const [showBlockErrorDialog, setShowBlockErrorDialog] = useState(false);
    const [blockedUser, setBlockUser] = useState<MessageUser | null>(null);

    const blockUser = useCallback((user: MessageUser) => {
      setShowBlockDialog(true);
      setBlockUser(user);
    }, []);

    const handleBlockUser = useCallback(async () => {
      setShowBlockDialog(false);
      try {
        await blockEmails([blockedUser?.email as string]);
      } catch (e) {
        console.error(e);
        setShowBlockErrorDialog(true);
      }
    }, [blockEmails, blockedUser?.email]);

    const latestSendMessage = useLatest(sendMessage);
    const sendChatMessage = useCallback(
      (message: string) => {
        const uiMessage = {
          comment: message,
          uid: uuidv4(),
          name: user.name,
          timestamp: new Date().toISOString(),
          userUid: user.uid,
        };

        if (uiMessage.name && uiMessage.name.indexOf("@") > -1) {
          uiMessage.name = uiMessage.name.split("@")[0];
        }

        if (typeof uiMessage.comment === "string") {
          latestSendMessage.current(uiMessage);
        }
      },
      [latestSendMessage, user.name, user.uid],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleReaction = useCallback(
      throttle(addReaction, 250, { leading: true }),
      [addReaction],
    );

    const isPinMessageEnabled = useFeatureFlag(
      FeatureFlag.PINNED_CHAT_MESSAGES,
    );

    return (
      <ChatContainer data-testid="chat-container" className={className}>
        {title && <TabTitle compact>{title}</TabTitle>}
        <PinnedMessages
          pinnedMessages={pinnedMessages}
          user={user}
          showTimestamp={showTimestamp}
          showParticipantNames={showParticipantNames}
          ownerEmails={ownerEmails}
          onUnpin={unpinMessage}
        />

        <ChatComments
          welcomeOwner={welcomeOwner}
          welcomeMessage={welcomeMessage}
          messages={messagesAndPolls}
          user={user}
          showTimestamp={showTimestamp}
          showParticipantNames={showParticipantNames}
          showReactions={chatReactionsEnabled}
          ownerEmails={ownerEmails}
          onDelete={deleteMessage}
          onBlock={blockUser}
          onPin={
            isPinMessageEnabled && channelConfig.pinnable ? pinMessage : null
          }
          onReact={
            chatReactionsEnabled ? (handleReaction as typeof addReaction) : null
          }
        />

        {!disableInput && (
          <>
            <Divider />
            <ChatInput label={label} onSubmit={sendChatMessage} />
          </>
        )}
        <BlockUserDialog
          show={showBlockDialog}
          username={blockedUser?.name as string}
          onBlock={handleBlockUser}
          onClose={() => setShowBlockDialog(false)}
        />
        <ErrorDialog
          show={showBlockErrorDialog}
          message="Unable to block the user, an unexpected error has occurred."
          onClose={() => setShowBlockErrorDialog(false)}
        />
      </ChatContainer>
    );
  },
);
