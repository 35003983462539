import React, { Suspense } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";

import { FullPageLoader } from "./components/FullPageLoader";
import { Home } from "./components/Home";
import { NetworkingHubInviteProviderNexus } from "./components/NetworkingHub/NetworkingHubInviteProviderNexus";
import {
  IntrovokeNetworkingHubPresence,
  IntrovokePresence,
} from "./components/Presence/IntrovokePresence";
import { ThemeProvider } from "./providers/ThemeProvider";
import { EventProvider } from "./providers/EventProvider";
import { NetworkingHubProvider } from "./providers/NetworkingHubProvider";
import { useUser } from "./providers/UserProvider";
import { PollProvider } from "./providers/polls/PollProvider";
import { QandAProvider } from "./providers/qAndA/QandAProvider";
import { getReturnToEventId } from "./helpers/utils";
import { PubNubProvider } from "./providers/pubnub/PubNubProvider";
import { FeatureFlagsProvider } from "./providers/FeatureFlagsProvider";
import {
  HostGuard,
  EventGuard,
  UserLimitGuard,
} from "./components/accessGuards";
import DialogProvider from "./providers/DialogProvider";
import EventPage from "./components/Event/EventPage";
import NetworkingHub from "./components/Event/NetworkingHub";

import lazyLoader from "@src/helpers/lazyLoader";
import { EventWatch } from "@src/components/Event/EventStage/EventWatch";
import { EventGreenRoom } from "@src/components/Event/EventStage/EventGreenRoom";
import { EventStage } from "@src/components/Event/EventStage/EventStage";

const CompatibilityChecker = lazyLoader(
  () => import("./components/dialogs/CompatibilityChecker"),
  "CompatibilityChecker",
);

type RouteProvidersProps =
  | {
      variant: "networking-hub";
      eventId?: string;
    }
  | {
      variant: "live-event";
      eventId?: string;
    };

export const RouteProviders: React.FC<RouteProvidersProps> = ({
  children,
  ...props
}) => {
  const user = useUser();
  const params = useParams();

  const eventId = (props.eventId || params.eventId) as string;
  const networkingHubId = params.networkingHubId as string;
  const roomId = params.roomId as string;
  const circleId = (params.circleId || roomId) as string;

  const components =
    props.variant === "networking-hub" ? (
      <UserLimitGuard
        user={user}
        eventId={eventId}
        networkingHubId={networkingHubId}
      >
        <PubNubProvider topicId={networkingHubId}>
          <NetworkingHubProvider
            networkingHubId={networkingHubId}
            circleId={circleId}
            returnToEventId={eventId}
            userId={user.uid}
          >
            <IntrovokeNetworkingHubPresence
              networkingHubId={networkingHubId}
              circleId={circleId}
            >
              <PollProvider eventId={networkingHubId} circleId={circleId}>
                <QandAProvider
                  eventId={networkingHubId}
                  circleId={circleId}
                  userId={user.uid}
                >
                  <NetworkingHubInviteProviderNexus
                    networkingHubId={networkingHubId}
                  >
                    {children}
                  </NetworkingHubInviteProviderNexus>
                </QandAProvider>
              </PollProvider>
            </IntrovokeNetworkingHubPresence>
          </NetworkingHubProvider>
        </PubNubProvider>
      </UserLimitGuard>
    ) : (
      <EventProvider eventId={eventId}>
        <UserLimitGuard user={user} eventId={eventId}>
          <IntrovokePresence id={eventId} disablePresence>
            <EventGuard fallback={<FullPageLoader />}>
              <PubNubProvider topicId={eventId}>
                <HostGuard eventId={eventId} />
                <PollProvider eventId={eventId}>
                  <QandAProvider eventId={eventId} userId={user.uid}>
                    {children}
                  </QandAProvider>
                </PollProvider>
              </PubNubProvider>
            </EventGuard>
          </IntrovokePresence>
        </UserLimitGuard>
      </EventProvider>
    );

  return components;
};

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <FeatureFlagsProvider fallback={<FullPageLoader />}>
      <ThemeProvider>
        <DialogProvider>
          <Suspense fallback={<FullPageLoader />}>
            <Routes>
              <Route
                path="/compatibility-check"
                element={<CompatibilityChecker open context="standalone" />}
              />
              <Route path="/" element={<Home />} />
              <Route
                path="/event/:eventId"
                element={
                  <RouteProviders variant="live-event">
                    <EventPage />
                  </RouteProviders>
                }
              >
                <Route path="watch" element={<EventWatch />} />
                <Route path="greenroom" element={<EventGreenRoom />} />
                <Route index element={<EventStage />} />
              </Route>
              <Route
                path="/networkingHub/:networkingHubId"
                element={
                  <RouteProviders
                    variant="networking-hub"
                    eventId={getReturnToEventId(location.search)}
                  >
                    <NetworkingHub />
                  </RouteProviders>
                }
              />
              <Route
                path="/networkingHub/:networkingHubId/room/:roomId"
                element={
                  <RouteProviders
                    variant="networking-hub"
                    eventId={getReturnToEventId(location.search)}
                  >
                    <NetworkingHub />
                  </RouteProviders>
                }
              />
              <Route
                path="/networkingHub/:networkingHubId/circle/:circleId"
                element={
                  <RouteProviders
                    variant="networking-hub"
                    eventId={getReturnToEventId(location.search)}
                  >
                    <NetworkingHub />
                  </RouteProviders>
                }
              />
              <Route element={<Home invalidUrl />} />
            </Routes>
          </Suspense>
        </DialogProvider>
      </ThemeProvider>
    </FeatureFlagsProvider>
  );
};
