interface RandomNumberArgs {
  /**
   * The minimum value for the random integer, defaults to 0
   */
  min: number;
  /**
   * The maximum value for the random integer, defaults to 1
   */
  max: number;
}

/**
 * Returns a random integer between the provided min/max values
 */
export const randomInteger = (args: RandomNumberArgs) => {
  const { max = 1, min = 0 } = args;
  return Math.floor(Math.random() * (max - min + 1) + min);
};
