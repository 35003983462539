import React from "react";
import {
  TextField,
  InputAdornment,
  Button,
  ButtonProps,
  styled,
} from "@mui/material";
import { Virtuoso } from "react-virtuoso";
import { useLatest } from "react-use";

import SearchIcon from "@mui/icons-material/Search";
import LoadMoreIcon from "@mui/icons-material/Add";

import Participant, {
  ParticipantType,
  Props as ParticipantProps,
} from "./Participant";
import { Props as MenuItemProps } from "../Menu";

export const DEFAULT_MAX_LENGTH = 50;

export interface Props {
  /**
   * A list of participants to display
   */
  participants: ParticipantType[];

  /**
   * The search box placeholder string
   */
  searchPlaceholder: string;

  /**
   * The current search value
   */
  search?: string;

  /**
   * The search change listener
   */
  onSearchChange?: (search: string) => void;

  /**
   * Callback function to custom render participants
   */
  renderParticipant?: (
    participantProps: ParticipantProps,
  ) => React.ReactNode | null;

  /**
   * Callback when a user selects a participant
   */
  onParticipantSelected?: (participant: ParticipantType) => void;

  /**
   * Callback when the user has requested more participants be rendered
   */
  onMaxLengthChanged?: (maxLength: number) => void;

  /**
   * Callback to create menu items for the participant
   */
  createMenuItems?: (
    participant: ParticipantType,
  ) => MenuItemProps["menuItems"] | null;
}

const Container = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "stretch",
  overflowX: "hidden",
  minHeight: 0,
});

const SearchContainer = styled("div")(
  ({ theme: { spacing, palette, breakpoints } }) => ({
    borderColor: palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    padding: spacing(1),
    paddingLeft: spacing(1.5),
    paddingRight: spacing(1.5),
    [breakpoints.down("sm")]: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
  }),
);

const SearchInput = styled(TextField)(({ theme: { spacing, palette } }) => ({
  backgroundColor: palette.grey[100],
  padding: spacing(0.5),
  paddingLeft: spacing(1),
  paddingRight: spacing(1),
  borderColor: palette.divider,
  borderStyle: "solid",
  borderWidth: 1,
  borderRadius: 4,
  "&:hover": {
    borderColor: palette.primary.main,
  },
}));

const ListContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: 0,
});

const ListEndButton = styled(Button)(({ theme: { spacing, breakpoints } }) => ({
  textAlign: "center",
  width: "100%",
  padding: spacing(1),
  paddingLeft: spacing(1.5),
  paddingRight: spacing(1.5),
  paddingBottom: spacing(4),
  [breakpoints.down("sm")]: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const SearchIconStyled = styled(SearchIcon)(({ theme: { palette } }) => ({
  color: palette.base?.[400],
}));

const ListFooter = React.memo(function ListFooter(
  props: ButtonProps & {
    visible: boolean;
    onLoadMore: () => void;
  },
) {
  if (!props.visible) {
    return null;
  }
  return (
    <ListEndButton
      variant="text"
      color="primary"
      onClick={props.onLoadMore}
      startIcon={<LoadMoreIcon />}
    >
      Load More
    </ListEndButton>
  );
});

const ParticipantList = (props: Props) => {
  const {
    participants: rawParticipants,
    searchPlaceholder,
    search,
    onSearchChange,
    renderParticipant,
    onParticipantSelected,
    createMenuItems,
    onMaxLengthChanged,
  } = props;

  const [maxLength, setMaxLength] = React.useState(DEFAULT_MAX_LENGTH);

  const latestOnMaxLengthChanged = useLatest(onMaxLengthChanged);
  React.useEffect(() => {
    latestOnMaxLengthChanged.current?.(maxLength);
  }, [maxLength, latestOnMaxLengthChanged]);

  const { participants, isMaxParticipants } = React.useMemo(
    () => ({
      participants: rawParticipants.slice(0, maxLength),
      isMaxParticipants: rawParticipants.length > maxLength,
    }),
    [rawParticipants, maxLength],
  );

  return (
    <Container>
      <SearchContainer>
        <SearchInput
          fullWidth
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIconStyled />
              </InputAdornment>
            ),
          }}
          placeholder={searchPlaceholder}
          onChange={(event) => {
            onSearchChange?.(event.target.value);
          }}
          value={search}
        />
      </SearchContainer>

      <ListContainer>
        <Virtuoso
          totalCount={participants.length}
          itemContent={(index) => {
            const participant = participants[index];
            const participantProps = {
              key: participant.userId,
              ...participant,
              onSelected:
                onParticipantSelected &&
                (() => onParticipantSelected(participant)),
              menuItems: createMenuItems?.(participant),
            };
            return renderParticipant ? (
              renderParticipant(participantProps)
            ) : (
              <Participant {...participantProps} />
            );
          }}
          components={{
            Footer: () => (
              <ListFooter
                visible={isMaxParticipants}
                onLoadMore={() => setMaxLength(maxLength + DEFAULT_MAX_LENGTH)}
              />
            ),
          }}
        />
      </ListContainer>
    </Container>
  );
};

export default React.memo(ParticipantList);
