import { useFormik } from "formik";
import React from "react";
import { useQueryClient } from "react-query";

import { QueryKeys } from "../../api/QueryKeys";
import { Api } from "../../api/api";
import { User } from "../../contracts/user/user";
import General from "../Profile/General";
import { useDialog } from "@src/providers/DialogProvider";

interface GettingStartedProps {
  user: User;
}

export const GettingStarted: React.FC<GettingStartedProps> = ({ user }) => {
  const cacheClient = useQueryClient();
  const { hideDialog } = useDialog();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: user,
    onSubmit: async (values) => {
      try {
        const result = await Api.UserApi.createUpdate({
          uid: user.uid,
          originalId: user.originalId,
          name: values.name,
          email: values.email,
          profilePicture: values.profilePicture,
          updated: true,
        });
        cacheClient.setQueryData(QueryKeys.user(user.uid), result);
        cacheClient.invalidateQueries(QueryKeys.user(user.uid));
        hideDialog("profile-modal");
      } catch (err) {
        return;
      }
    },
    initialStatus: {
      step: 0,
    },
  });

  return (
    <General
      onSubmit={form.handleSubmit}
      onClose={() => {
        form.handleSubmit();
        hideDialog("profile-modal");
      }}
      userUpdated={(user: any) => {
        form.setValues(user);
      }}
      user={user}
    />
  );
};
