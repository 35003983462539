import { TabList, TabPanel } from "@mui/lab";
import { styled, Tab } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: 0,
  alignItems: "stretch",
});

export const CreatePollWrapper = styled("div")(({ theme: { spacing } }) => ({
  marginTop: spacing(1),
  padding: spacing(1),
  paddingLeft: spacing(1),
  paddingRight: spacing(1),
}));

export const StyledMuiTabList = styled(TabList)(
  ({ theme: { spacing, palette } }) => ({
    minHeight: spacing(4),
    marginTop: spacing(2),
    "& .MuiTabs-flexContainer": {
      alignItems: "center",
      justifyContent: "space-evenly",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: `${palette.primary.main}40`,
      color: palette.primary.main,
      borderRadius: spacing(1),
      height: "100%",
    },
  }),
);

export const StyledMuiTab = styled(Tab)(({ theme: { palette, spacing } }) => ({
  padding: 0,
  minHeight: spacing(4),
}));

export const StyledMuiTabPanel = styled(TabPanel)(({ theme: { spacing } }) => ({
  padding: `${spacing(2)} 0`,
  height: "100%",
}));
