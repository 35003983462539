export type ObjectType = Record<string, unknown>;

export enum MetricsInstanceIds {
  ADMIN_DASHBOARD = "AdminDashboard",
  DEMO_SITE = "DemoSite",
  EMBED = "Embed",
  TEST = "Test",
}

export interface MetricsConfig {
  instanceId: MetricsInstanceIds;
  /**
   * The api key of the amplitude project to log the events to
   */
  projectApiKey: string;
  /**
   * The internal api endpoint to send the tracked events to (e.g.: `https://dev-api.sequel.io`)
   */
  internalApiEndpoint: string;
  /**
   * If `true`, will not send logs to amplitude. By default only `true` in the production env
   * and `false` in all other environments.
   */
  disabled?: boolean;
  /**
   * Enabling this will log events to the this._logger. By default only `true` in the development env
   * and `false` in all other environments
   */
  logEvents?: boolean;
  /**
   * Set this to `true` when in test mode to disable all logs
   */
  test?: boolean;
  /**
   * Enabling this will log extra details when logging events in the this._logger
   */
  verbose?: boolean;
  /**
   * An optional list of events to track internally in our own database when logged
   */
  trackedEvents?: IMetricsEvent[];
}

/**
 * An event to be sent to the `MetricsClient`.
 *
 * This is the default interface type. for use within projects, please be sure to
 * define your own MetricsEvent to enforce the group types like so:
 * ```ts
 * export type MetricsEvent<TData extends ObjectType=ObjectType> = IMetricsEvent<YourGroupType, TData>;
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IMetricsEvent<
  TGroup extends string = string,
  TData extends ObjectType = ObjectType,
> {
  /**
   * The name of the event
   */
  event: string;
  /**
   * The group which the event belongs to
   */
  group?: TGroup;
  /**
   * Any additional data associated with the event
   */
  data?: MetricsEventData<TData>;
}

/**
 * Any additional data associated with an event
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MetricsEventData<T extends ObjectType = ObjectType> = T & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  /**
   * Where the action took place (filename, component, page...)
   */
  module?: string;
  /**
   * The method/function which triggered the action
   */
  method?: string;
};

export type MetricsUser = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  user_id?: string;
  user_name?: string;
  user_email?: string;
  company_name?: string;
  company_id?: string;
  company_billing_plan?: string;
};

export type MetricsEventMapType<
  TEvent extends IMetricsEvent = IMetricsEvent,
  Keys extends string = string,
> = Record<Keys, TEvent>;

/**
 * @returns a formatted string of the event name as `Group_Name - Event_Name`
 */
export const formatEvent = (event: IMetricsEvent): string =>
  `${event.group ? `${event.group} - ` : ""}${event.event}`;

export enum MetricsEventGroups {
  CHAT = "Chat",
  NETWORKING_HUB = "Networking_Hub",
  VIRTUAL_STAGE = "Virtual_Stage",
}

export type MetricsEvent<TData extends ObjectType = ObjectType> = IMetricsEvent<
  MetricsEventGroups,
  TData
>;
