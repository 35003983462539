import { useCallback, useMemo } from "react";
import { useLatestCallback } from "@src/hooks/useLatestCallback";
import Pubnub from "pubnub";
import { useTheme } from "@mui/material";

import { FeatureFlag, useFeatureFlag } from "../FeatureFlagsProvider";
import {
  Message,
  MessageAction,
  MessageActionTypes,
  User,
} from "./ChatProvider";
import { ChatReaction, Reaction } from "@src/contracts/customization/reactions";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { EventStatus } from "@src/contracts/event/event";
import { useEventStatus } from "../EventProvider";
import { QueryKeys } from "@src/api/QueryKeys";
import { Api } from "@src/api/api";
import { toast } from "react-toastify";
import { useRouteParams } from "@src/hooks/useRouteParams";

export interface UseEnableChatReactionsOptions
  extends UseMutationOptions<
    EventStatus,
    unknown,
    boolean,
    { previousEventStatus?: EventStatus }
  > {}

export interface UseEnableChatReactionsValue {
  /**
   * Whether chat reactions are currently enabled in the `EventStatus` object
   */
  isChatReactionsOn: boolean;
  /**
   * Enables chat reactions on the `EventStatus` object
   */
  enableChatReactions: (enabled: boolean) => Promise<EventStatus>;
  /**
   * The `react-query` `mutation` object responsible for enabling chat reactions
   */
  mutation: UseMutationResult<
    EventStatus,
    unknown,
    boolean,
    {
      previousEventStatus?: EventStatus | undefined;
    }
  >;
}

/**
 * Hook to retrieve and enable/disable chat reactions on the `EventStatus` object
 */
export const useEnableChatReactions = (
  eventId: string,
  options: UseEnableChatReactionsOptions = {},
): UseEnableChatReactionsValue => {
  const status = useEventStatus();
  const isChatReactionsOn = status?.isChatReactionsOn ?? true;

  const { onError, ...rest } = options;

  const queryClient = useQueryClient();
  const queryKey = QueryKeys.eventStatus(eventId);

  const mutation = useMutation<
    EventStatus,
    unknown,
    boolean,
    { previousEventStatus?: EventStatus }
  >((enabled: boolean) => Api.EventApi.EnableChatReactions(eventId, enabled), {
    // Optimistically update event status value
    onMutate: (enabled: boolean) => {
      // cancel existing queries
      queryClient.cancelQueries(queryKey);

      // update local event status
      const previousEventStatus =
        queryClient.getQueryData<EventStatus>(queryKey);

      const updatedEventStatus = {
        ...previousEventStatus,
        isChatReactionsOn: enabled,
      } as EventStatus;

      // optimistically perform local update
      queryClient.setQueryData<EventStatus>(queryKey, updatedEventStatus);

      return { previousEventStatus };
    },
    // Rollback on error
    onError: (err, variables, context) => {
      // reset optimistic update
      queryClient.setQueryData<EventStatus | undefined>(
        queryKey,
        context?.previousEventStatus,
      );
      onError?.(err, variables, context);
    },
    onSettled: () => {
      // invalidate the event status query
      queryClient.invalidateQueries(queryKey);
    },
    ...rest,
  });

  const enableChatReactions = useCallback(
    (enabled: boolean) => mutation.mutateAsync(enabled),
    [mutation],
  );

  return {
    mutation,
    isChatReactionsOn,
    enableChatReactions,
  };
};

/**
 * Checks if the user has reacted to the provided message
 *
 * @param message The message for which to check if the user has reacted
 * @param userId The uuid of the user
 *
 * @returns the `PubNub.MessageAction` with the `Reaction` if user has reacted and `undefined` otherwise
 */
export const findReaction = (message: Message, userId: string) => {
  const reactions = (message?.actions?.reactions ||
    []) as MessageAction<Reaction>;
  const reactionKeys = Object.keys(reactions) as Reaction[];

  // using regular for loop for early break
  for (let k = 0; k < reactionKeys.length; k++) {
    const key = reactionKeys[k];

    const existingReaction = reactions[key]?.find((reaction) => {
      return reaction.uuid === userId;
    });

    if (existingReaction) {
      return { ...existingReaction, reaction: key };
    }
  }
};

export interface UseChatReactionsValue
  extends Pick<
    UseEnableChatReactionsValue,
    "isChatReactionsOn" | "enableChatReactions"
  > {
  /**
   * Whether chat reactions are enabled. Only true if chat reactions are enabled in the theme, feature flag and `EventStatus`.
   */
  enabled: boolean;
  /**
   * Whether chat reactions are enabled on both the theme and in feature flags
   */
  featureEnabled: boolean;
  /**
   * The chat reactions received from `theme.chatReactions.reactions`
   */
  chatReactions: ChatReaction[];
  /**
   * A map that returns the `ChatReaction` object from the `Reaction` value
   */
  ReactionToChatReactionMap: Record<Reaction, ChatReaction>;
}

export const useChatReactionsFeatureEnabled = () => {
  const { customTheme } = useTheme();
  const flagEnabled = useFeatureFlag(FeatureFlag.CHAT_REACTIONS);

  return !!(
    flagEnabled &&
    customTheme?.chatReactions?.enabled &&
    customTheme?.chatReactions?.reactions?.length
  );
};

export const useChatReactions = (): UseChatReactionsValue => {
  const { customTheme } = useTheme();
  const { eventId } = useRouteParams();
  const featureEnabled = useChatReactionsFeatureEnabled();

  // only for events
  const { isChatReactionsOn, enableChatReactions } = useEnableChatReactions(
    eventId as string,
    {
      onSuccess: (status, enabled) => {
        toast.success(
          `Chat reactions are now ${enabled ? "enabled" : "disabled"}`,
        );
      },
      onError: () => {
        toast.error("An error occurred toggling chat reactions");
      },
    },
  );

  const enabled = !!(isChatReactionsOn && featureEnabled);

  return useMemo(() => {
    const chatReactions = customTheme?.chatReactions?.reactions || []; // will come from the default theme if not present on the theme object

    const ReactionToChatReactionMap = chatReactions?.reduce(
      (obj, curr) => ({ ...obj, [curr.reaction]: curr }),
      {} as Record<Reaction, ChatReaction>,
    );

    return {
      enabled,
      featureEnabled,
      chatReactions,
      ReactionToChatReactionMap,
      isChatReactionsOn,
      enableChatReactions,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enabled,
    featureEnabled,
    customTheme?.chatReactions?.reactions,
    isChatReactionsOn,
    enableChatReactions,
  ]);
};

export interface UsePubnubChatReactionsValue
  extends Pick<
    UseChatReactionsValue,
    "enabled" | "chatReactions" | "ReactionToChatReactionMap"
  > {
  /**
   * Reacts to a given message with the provided reaction as follows:
   * - Reacts with the new reaction if the user has not previously reacted to the mesage
   * - Removes the reaction if the user has previously reacted to the message with the same reaction
   * - Replaces the existing reaction with the new reaction if the user
   * has previously reacted to the message with a different reaction
   */
  addReaction: (message: Message, reaction: Reaction) => Promise<void>;
  /**
   * Used by `addReaction` internally and in most cases should not be called directly
   */
  removeReaction: (message: Message, actionTimetoken: string) => Promise<void>;
}

export const usePubnubChatReactions = ({
  pubnub,
  user,
}: {
  pubnub: Pubnub;
  user: User;
}): UsePubnubChatReactionsValue => {
  const { enabled, chatReactions, ReactionToChatReactionMap } =
    useChatReactions();

  const addReaction = useLatestCallback(
    async (message: Message, reaction: Reaction) => {
      if (!enabled) return;

      try {
        const existingReaction = findReaction(message, user.uid);

        // if user has already reacted
        if (existingReaction) {
          // remove existing reaction
          await removeReaction(message, existingReaction.actionTimetoken);
        }

        // if reacting with the same reaction (unreacting)
        if (existingReaction?.reaction === reaction) {
          return;
        }

        // react with new reaction
        await pubnub.addMessageAction({
          channel: message.channel,
          messageTimetoken: String(message.timetoken),
          action: {
            type: MessageActionTypes.REACTIONS,
            value: reaction,
          },
        });
      } catch (e) {
        // ignore for now
      }
    },
  );

  const removeReaction = useLatestCallback(
    async (message: Message, actionTimetoken: string) => {
      try {
        await pubnub.removeMessageAction({
          channel: message.channel,
          messageTimetoken: String(message.timetoken),
          actionTimetoken: String(actionTimetoken),
        });
      } catch (e) {
        // ignore for now
      }
    },
  );

  return {
    enabled,
    chatReactions,
    ReactionToChatReactionMap,
    addReaction,
    removeReaction,
  };
};
