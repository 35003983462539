import React, { useMemo } from "react";
import { Form, FormikProvider } from "formik";
import { Box, Button, ButtonGroup, styled, Tooltip } from "@mui/material";

import { Event, ReplayConfig } from "../../contracts/event/event";
import { EventReplaysProvider } from "../../providers/EventReplaysProvider";
import {
  ReplayFormConfig,
  useEditReplayFormController,
} from "./useEditReplayFormController";
import { CustomTextPlaceholder } from "./CustomTextPlaceholder";
import { ShowCountdownContent } from "./ShowCountdownContent";
import VideoReplaysTab from "./VideoReplaysTab";
import { SubmitButton } from "./ReplayFormActions";
import { ButtonTabs } from "./common";

const StyledForm = styled(Form)(() => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  width: "100%",
}));

interface Props {
  event: Event;
  eventState: ReplayConfig;
  onFormSave?: () => void;
}

export default function EditReplayContent({
  event,
  eventState,
  onFormSave,
}: Props) {
  // countdowns only appear for upcoming events
  const showCountdownTab = event.startDate > new Date();

  const initialValues = useMemo<ReplayFormConfig>(() => {
    const replayEnabled = eventState.replayEnabled ?? true;
    const replayUrl = eventState.replayUrl;
    const streamPlaceholderMessage = eventState.streamPlaceholderMessage;

    const showReplay = replayEnabled && replayUrl?.length;
    const showCountdown =
      showCountdownTab && !eventState.streamPlaceholderMessage?.length;

    const activeTab =
      !showReplay && streamPlaceholderMessage?.length // use custom text if no replay and custom text is set
        ? ButtonTabs.ShowCustomText
        : !showReplay && showCountdown // use countdown if no replay and start date in the future
        ? ButtonTabs.ShowCountdown
        : ButtonTabs.SelectReplay; // default to select replay

    return {
      activeTab,
      replayEnabled,
      replayUrl,
      showCountdown,
      streamPlaceholderMessage,
    };
  }, [
    eventState.replayEnabled,
    eventState.replayUrl,
    eventState.streamPlaceholderMessage,
    showCountdownTab,
  ]);

  const { form } = useEditReplayFormController(
    event?.uid,
    initialValues,
    onFormSave,
  );

  const selectedTab = form.values.activeTab;

  const renderTabButton = (label: string, tabId: ButtonTabs) => (
    <Button
      onClick={() => onSelectTab(tabId)}
      variant={selectedTab === tabId ? "contained" : "outlined"}
    >
      {label}
    </Button>
  );

  const onSelectTab = (tabId: ButtonTabs) => {
    form.resetForm();
    form.setFieldValue("replayEnabled", tabId === ButtonTabs.SelectReplay);
    form.setFieldValue("showCountdown", tabId === ButtonTabs.ShowCountdown);
    form.setFieldValue("activeTab", tabId);
  };

  return (
    <EventReplaysProvider>
      <FormikProvider value={form}>
        <StyledForm>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Tooltip title="Specify which replay to show attendees after event. A custom replay can be added in the Admin Dashboard">
              {renderTabButton(
                "Select On-Demand Replay",
                ButtonTabs.SelectReplay,
              )}
            </Tooltip>

            <Tooltip title="Customize message text shown to attendees while event is not live.">
              {renderTabButton(
                "Display Custom Text",
                ButtonTabs.ShowCustomText,
              )}
            </Tooltip>

            {showCountdownTab && (
              <Tooltip title="Show attendees a live countdown timer until event starts.">
                {renderTabButton("Show Countdown", ButtonTabs.ShowCountdown)}
              </Tooltip>
            )}
          </ButtonGroup>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 3,
              width: "100%",
              flexGrow: 1,
            }}
          >
            <TabContents tab={selectedTab} />
            <SubmitButton event={event} selectedTab={selectedTab} />
          </Box>
        </StyledForm>
      </FormikProvider>
    </EventReplaysProvider>
  );
}

function TabContents({ tab }: { tab: number }) {
  switch (tab) {
    case ButtonTabs.SelectReplay:
      return <VideoReplaysTab />;
    case ButtonTabs.ShowCustomText:
      return <CustomTextPlaceholder />;
    case ButtonTabs.ShowCountdown:
      return <ShowCountdownContent />;
    default:
      return null;
  }
}
