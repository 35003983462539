/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import invariant from "tiny-invariant";

import { usePrevious } from "react-use";

import { UserRole } from "../../../contracts/user/user";
import { useEvent } from "../../../providers/EventProvider";
import {
  useEventState,
  useEventVideoType,
} from "../../../providers/EventStateProvider";
import { useUser } from "../../../providers/UserProvider";
import PromotedConfirmation from "../../dialogs/content/PromotedConfirmation";
import { VideoType } from "@src/models/eventType";
import { useDialog } from "@src/providers/DialogProvider";
import { EventVideoPlayer } from "@src/components/Event/EventVideoPlayer";
import { useVideoSource } from "./useVideoSource";
import { useUpdateVideoView } from "./useUpdateVideoView";

import BadBrowserAlert from "@src/components/Event/EventStage/BadBrowserAlert";
import Header from "@src/components/Event/EventStage/Header/Header";
import StageHeader from "@src/components/Event/EventStage/Header/StageHeader";

export interface Props {
  name: string;
  eventName: string;
  profilePicture: string;
  eventPicture: string;
  userRole: UserRole;
  startDate: Date;
  onEnterStage?: () => void;
}

export const ViewerLayout = () => {
  const { openDialog } = useDialog();
  const { userRole } = useUser();
  const {
    setPromoteStatus,
    promoteStatus,
    enterStage,
    isEventLive,
    replayEnabled,
  } = useEventState();
  const [startingSoon, setStartingSoon] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isStreamLive, setIsStreamLive] = useState(isEventLive);
  const { data: event } = useEvent();
  invariant(event, "Event is required");

  let videoType = useEventVideoType();
  if (isStreamLive) {
    videoType = VideoType.Live;
  }

  const handleUpdateVideoView = useUpdateVideoView(videoType);

  const { src, reset, isLoading } = useVideoSource({
    videoType,
    disabled: userRole === UserRole.Unregistered,
  });

  const previousSource = usePrevious(src);
  const hasNewVideoView =
    src &&
    previousSource !== src &&
    videoType !== VideoType.Upcoming &&
    userRole !== UserRole.Unregistered;
  useEffect(() => {
    if (hasNewVideoView) {
      handleUpdateVideoView();
    }
  }, [handleUpdateVideoView, hasNewVideoView]);

  useEffect(() => {
    setStartingSoon(isLoading);
  }, [isLoading]);

  // Fixes issue where livestream ends too soon
  // we internally need to change the type after the stream stops playing
  // as the flag can end before the stream actually ends
  const lastIsEventLive = usePrevious(isEventLive);
  useEffect(() => {
    if (lastIsEventLive && !isEventLive && isVideoPlaying) {
      // When we switch from live to not live, make sure we wait for the live video feed to end
      return;
    }

    setIsStreamLive(isEventLive);
  }, [isEventLive, isVideoPlaying]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * If the event is currently live, or they've enabled replays, show the video player and stage
   */
  const showReplay = replayEnabled || !!event.streamInfo.videoLoopUrl;
  const showLive = isStreamLive || isEventLive;

  const showStageContents =
    userRole !== UserRole.Unregistered && // Unregistered users can't view live event stage contents
    !startingSoon && // verifies streaming URL is working
    (showLive || showReplay); // show replays (when enabled)

  useEffect(() => {
    if (promoteStatus === "promoted") {
      openDialog(
        "PromotedConfirmation",
        <PromotedConfirmation
          role={UserRole.Presenter}
          onConfirm={() => setPromoteStatus("accepted")}
          onCancel={() => setPromoteStatus("canceled")}
        />,
        {
          maxWidth: "xs",
          priority: "high",
          onClose: () => setPromoteStatus("canceled"),
        },
      );
    }
  }, [openDialog, promoteStatus, setPromoteStatus]);

  return (
    <>
      <BadBrowserAlert />
      {showStageContents ? (
        <>
          <StageHeader
            isStreamLive={isEventLive}
            onEnterStage={
              userRole === UserRole.Presenter || userRole === UserRole.Organizer
                ? enterStage
                : undefined
            }
          />
          <EventVideoPlayer
            src={src}
            loop={videoType === VideoType.OnDemand}
            videoType={videoType}
            sx={{ display: showStageContents ? "flex" : "none" }}
            onPlayingStateChange={setIsVideoPlaying}
            onError={() => reset()}
          />
        </>
      ) : (
        <>
          <Header
            startingSoon={startingSoon}
            isStreamLive={isStreamLive}
            onEnterStage={
              userRole === UserRole.Presenter || userRole === UserRole.Organizer
                ? enterStage
                : undefined
            }
          />
        </>
      )}
    </>
  );
};
