/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import debounce from "lodash/debounce";
import { useConfigValue } from "../providers/config";
import { styled } from "@mui/material/styles";

const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  width: "100%",
  alignItems: "stretch",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const ContentContainer = styled("div")(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  flex: "1 1 70%",
  minHeight: 0,
  minWidth: 0,
}));

interface RootLayoutProps {
  content: React.ReactNode;
  sidebar: React.ReactNode;
}
export const RootLayout = ({ sidebar, content }: RootLayoutProps) => {
  const config = useConfigValue();
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  // Using a specific height to fix 100vh not working on mobile
  // In mobile browsers 100vh does not reduce w/ address bar
  React.useEffect(() => {
    const resize = debounce(() => {
      setWindowHeight(window.innerHeight);
    }, 250);
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const rootStyles = useMemo(
    () => ({
      height: `${windowHeight}px`,
      minHeight: `${windowHeight}px`,
    }),
    [windowHeight],
  );

  return config.cleanUI || !sidebar ? (
    <RootContainer sx={rootStyles}>
      <ContentContainer>{content}</ContentContainer>
    </RootContainer>
  ) : config.hybridMode ? (
    <RootContainer sx={rootStyles}>{sidebar}</RootContainer>
  ) : (
    <RootContainer sx={rootStyles}>
      <ContentContainer>{content}</ContentContainer>
      {sidebar}
    </RootContainer>
  );
};
