import { forwardProps } from "@src/helpers/utils";
import React, {
  useState,
  ReactNode,
  PropsWithChildren,
  useRef,
  useEffect,
  useCallback,
} from "react";
import uniqBy from "lodash/uniqBy";
import Dialog, { DialogContentProps } from "./Dialog";
import DialogContext, {
  DialogPriorityMap,
  DialogQueueItem,
  OpenDialogOptions,
} from "./DialogContext";
import { useDialog } from "./useDialog";

export { useDialog };

/**
 * Sort dialogs by highest priority (`critical` > `high` > `normal` > `low`).
 *
 * If same priority, the last added dialog takes precedence.
 */
const sortDialogs = (dialogs: DialogQueueItem[]) => {
  return dialogs.sort((prev, next) => {
    if (prev.priority === next.priority) {
      return next.internalId - prev.internalId;
    }
    return DialogPriorityMap[next.priority] - DialogPriorityMap[prev.priority];
  });
};

const DialogProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isOpen, setIsOpen] = useState(false);
  const dialogsRef = useRef<DialogQueueItem[]>([]);
  const internalIdRef = useRef(0);

  // queue sorted by priority and internalId
  const [queue, setQueue] = useState<DialogQueueItem[]>(dialogsRef.current);

  const openDialog = useCallback(
    (id: string, content: ReactNode, options: OpenDialogOptions = {}) => {
      const newContent: DialogQueueItem = {
        ...options,
        id,
        internalId: internalIdRef.current++,
        priority: options.priority ?? "normal",
        content: forwardProps<DialogContentProps>(content, {
          dialogId: id,
        }),
      };

      setQueue((prev) => {
        // remove duplicates and sort
        return sortDialogs(uniqBy([...prev, newContent], "id"));
      });

      return id;
    },
    [],
  );

  const hideDialog = useCallback((id: string) => {
    // immediately set to false to avoid empty dialog
    setIsOpen(false);
    // remove dialog from queue
    setQueue((prev) => prev.filter((dialog) => dialog.id !== id));
  }, []);

  useEffect(() => {
    // show dialog if there is something to show
    if (queue.length > 0) {
      setIsOpen(true);
    }
    // hide dialog if nothing is open
    if (queue.length === 0) {
      setIsOpen(false);
    }

    // update ref
    dialogsRef.current = queue;
  }, [queue]);

  return (
    <DialogContext.Provider
      value={{
        isOpen,
        openDialog,
        hideDialog,
        currentDialog: queue[0],
        dialogQueue: queue,
      }}
    >
      <Dialog />
      {children}
    </DialogContext.Provider>
  );
};

export default React.memo(DialogProvider);
