import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  isMatchingUrl,
  isRecentlyUploadedReplayUrl,
  useEventReplaysContext,
} from "../../providers/EventReplaysProvider";
import { Event } from "../../contracts/event/event";
import type { ReplayFormConfig } from "./useEditReplayFormController";

const REPLAY_OVERWRITE_WARNING = `NOTE: This will overwrite existing uploaded`;

enum ButtonTabs {
  SelectReplay,
  ShowCustomText,
  ShowCountdown,
}

interface Props {
  event: Event;
  selectedTab: number;
}

export function SubmitButton({ event, selectedTab }: Props) {
  const { replayUrls } = useEventReplaysContext();

  const { isValid, errors, handleSubmit, isSubmitting, isValidating, values } =
    useFormikContext<ReplayFormConfig>();
  const loading = isValidating || isSubmitting;
  const hasValidVideo =
    selectedTab !== ButtonTabs.SelectReplay || !!values.replayUrl?.length;

  const submissionDisabled =
    !isValid || loading || !!errors.streamPlaceholderMessage || !hasValidVideo;

  // When a user "saves" a custom replay, we overwrite the existing "custom" replay
  // This prevents users from having multiple uploaded replays
  // There's no use-case for multiple replays and this could easily lead to confusion if there were small differences
  const showReplayOverwriteWarning = useMemo(
    () =>
      selectedTab === ButtonTabs.SelectReplay && // only show on Select Replay Tab
      isRecentlyUploadedReplayUrl(values.replayUrl) && // Only show if the url is a recently uploaded "custom" replay
      // only show if the selected replay has changed
      !isMatchingUrl(values.replayUrl, event.liveEventManagement.replayUrl) &&
      replayUrls.filter((r) => r.isCustomReplay).length > 1, // only show if there is an existing "custom" replay that will be overwritten
    [
      event.liveEventManagement.replayUrl,
      replayUrls,
      selectedTab,
      values.replayUrl,
    ],
  );

  const loadingIndicator = loading ? (
    <Box sx={{ position: "relative", left: "-.5em", display: "inline-flex" }}>
      <CircularProgress color="secondary" size={20} />
    </Box>
  ) : null;

  return (
    <>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          type="submit"
          disabled={submissionDisabled}
          color="primary"
          onClick={() => handleSubmit}
          variant="contained"
        >
          <>
            {loadingIndicator}
            {`Publish`}
          </>
        </Button>
      </Box>
      {showReplayOverwriteWarning && (
        <Typography color="error" sx={{ mt: 1 }}>
          {REPLAY_OVERWRITE_WARNING}
        </Typography>
      )}
    </>
  );
}
