import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React from "react";

import { ClassNames } from "@emotion/react";
import { Menu, MenuItem, SvgIcon } from "@mui/material";

import { MingleIcon } from "../icons";
import { logEvent, MetricsEvents } from "@src/metrics";
import { useInviteQuery } from "./NetworkingHubInviteProviderNexus";
import Button from "@src/components/buttons/Button";

export const MingleMenu = (props: {
  onMingleUser?: () => void;
  onMingleCircle?: () => void;
  fullWidth?: boolean;
  hideRandomUserMingle?: boolean;
}) => {
  const inviteQuery = useInviteQuery();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `mingle-menu`,
  });

  return (
    <>
      <ClassNames>
        {({ css }) => (
          <Button
            classes={{
              // @ts-ignore // TODO remove please
              label: css`
                white-space: nowrap;
              `,
            }}
            fullWidth={props.fullWidth}
            variant="contained"
            color="primary"
            startIcon={
              <SvgIcon>
                <MingleIcon />
              </SvgIcon>
            }
            {...bindTrigger(popupState)}
            disabled={inviteQuery.isLoading}
          >
            Let's mingle!
          </Button>
        )}
      </ClassNames>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          disabled={!props.onMingleCircle}
          onClick={() => {
            popupState.close();
            props.onMingleCircle?.();
            logEvent(MetricsEvents.NetworkingHub.LETS_MINGLE, {
              mingleType: "circle",
            });
          }}
        >
          Mingle random Circle
        </MenuItem>
        {!props.hideRandomUserMingle && (
          <MenuItem
            disabled={!props.onMingleUser}
            onClick={() => {
              popupState.close();
              props.onMingleUser?.();
              logEvent(MetricsEvents.NetworkingHub.LETS_MINGLE, {
                mingleType: "attendee",
              });
            }}
          >
            Mingle random Attendee
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
