import React, { useMemo, useState } from "react";
import { Box, TextField, Theme, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles, { ClassKeyInferable } from "@mui/styles/withStyles";

import { BaseButton } from "../buttons/BaseButton";
import { User } from "../../contracts/user/user";
import PictureUpload from "./PictureUpload";

export interface GeneralProps extends WithStyles<ClassKeyInferable<any, any>> {
  disableCard?: boolean;
  user: User;
  userUpdated: (user: User) => void;
  onSubmit?: () => void;
  onClose?: () => void;
  disableActions?: boolean;
  disableSubmit?: boolean;
}

export interface GeneralState {
  user: User;
  imageChanged: boolean;
}

const General = (props: GeneralProps) => {
  const [user, setUser] = useState(props.user);

  const handlePictureChange = (imageUrl: string) => {
    const updatedUser = { ...user, profilePicture: imageUrl };
    setUser(updatedUser);
    props.userUpdated(updatedUser);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedUser = { ...user, name: event.target.value };
    setUser(updatedUser);
    props.userUpdated(updatedUser);
  };

  const displayNameHelperText = useMemo(() => {
    const name = user.name;
    if (!name) {
      return "Display name required";
    }

    if (name.length > 50) {
      return "Display name must be less than 50 characters";
    }

    return null;
  }, [user]);

  const saveDisabled = !user.name || user.name.length > 50;

  return (
    <Box sx={{ mb: 0 }}>
      <Typography textAlign="center" variant="body2" sx={{ mb: 2 }}>
        Your profile
      </Typography>

      <Box>
        <Typography textAlign="center" variant="body1" sx={{ mb: 2 }}>
          {user.name}
        </Typography>

        <PictureUpload
          currentImage={user.profilePicture}
          imageUploaded={handlePictureChange}
        />

        <>
          <Box>
            <Box>
              <TextField
                id="name"
                onChange={handleNameChange}
                value={user.name}
                fullWidth
                sx={{ mt: 2 }}
                variant="filled"
                name="name"
                required
                color="primary"
                label="Display Name"
                error={!user.name || user.name.length > 50}
                helperText={displayNameHelperText}
              />
            </Box>
            {!props.disableActions && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <BaseButton
                  variant="outlined"
                  type="submit"
                  className={props.classes.cancelButton}
                  onClick={props.onClose}
                >
                  Close
                </BaseButton>
                <BaseButton
                  onClick={props.onSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={props.classes.saveButton}
                  disabled={props.disableSubmit || saveDisabled}
                >
                  Save Changes
                </BaseButton>
              </Box>
            )}
          </Box>
        </>
      </Box>
    </Box>
  );
};

const styles = (theme: Theme) => ({
  saveButton: {},
  cancelButton: {
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles, { withTheme: true })(General);
