import queryString from "query-string";
import words from "lodash/words";
import { isValidElement, cloneElement } from "react";
import { useNavigate } from "react-router-dom";

export class Util {
  public static GetUTCDate() {
    const currentDate = new Date();
    return currentDate.toISOString();
  }
}

/**
 * helper function to be used ONLY in extractIdsFromUrl
 */
function getUidFromUrl(type: "event" | "networkingHub"): string {
  const pathArray = window.location.pathname.split("/");
  const eventIndex = pathArray.indexOf(type);
  if (eventIndex > 0) {
    return pathArray[eventIndex + 1] || "";
  } else {
    return "";
  }
}

/**
 * Removes the provided key from the query string of the url
 *
 * @param key The query key or keys to remove
 * @param navigate Navigate function
 */
export const removeQueryKeyFromUrl = (
  navigate: ReturnType<typeof useNavigate>,
  key: string | string[],
) => {
  const keys = typeof key === "string" ? [key] : key;

  const urlParams = new URLSearchParams(window.location.search);

  keys.forEach((key) => urlParams.delete(key));

  if (navigate) {
    return navigate({ search: urlParams.toString() });
  }
};

export function isNetworkingHub(): boolean {
  return window.location.pathname.indexOf("networkingHub") >= 0;
}

export function extractUidFromUserId(userId: string): string {
  return userId.includes("|||") ? userId.split("|||")[0] : userId;
}

export function shortenLargeNumber(num: number, digits: number) {
  var units = ["k", "M", "G", "T", "P", "E", "Z", "Y"],
    decimal;

  for (var i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }

  return num;
}

/**
 * Extract the event ID and networking hub ID from the current window location
 */
export const extractIdsFromUrl = (): {
  eventId?: string;
  networkingHubId?: string;
} => {
  if (isNetworkingHub()) {
    return {
      networkingHubId: getUidFromUrl("networkingHub"),
      eventId: getReturnToEventId(window.location.search),
    };
  }

  return {
    eventId: getUidFromUrl("event"),
  };
};

export const getReturnToEventId = (
  searchString: string,
): string | undefined => {
  const searchParam = queryString.parse(searchString).returnToEventId;
  if (searchParam && typeof searchParam === "string") {
    return searchParam;
  }
  return undefined;
};

export const appendReturnToEvent = (
  searchString: string,
  eventId: string,
): string => {
  if (eventId.length) {
    const parsed = queryString.parse(searchString);
    parsed.returnToEventId = eventId;
    return queryString.stringify(parsed);
  }
  return searchString;
};

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * Returns the initials for a string.
 *
 * Can be used with the names or email addresses.
 */
export const getInitials = (s: string | undefined | null) => {
  if (!s) return "";
  return words(s.split("@")[0])
    .slice(0, 2)
    .reduce((acc, w) => (acc += w.substr(0, 1)), "")
    .toUpperCase();
};

/**
 * Forwards props to a single component
 */
export function forwardProps<T>(
  component: React.ReactNode,
  props?: T & React.Attributes,
) {
  return isValidElement(component) ? cloneElement(component, props) : null;
}
