const CLOUNDINARY_BUCKET_URL =
  "https://res.cloudinary.com/introvoke/image/upload/";

interface TransformImageArgs {
  url: string;
  width: number;
  height: number;
  alwaysFill?: boolean;
}

/**
 * optimally transforms the image url
 */
export const transformImageUrl = ({
  url,
  width,
  height,
  alwaysFill,
}: TransformImageArgs): string => {
  if (!url.startsWith(CLOUNDINARY_BUCKET_URL)) return url;

  const imageUrlSuffix = url.replace(CLOUNDINARY_BUCKET_URL, "");

  if (imageUrlSuffix.split("/").length > 2) {
    return url;
  }

  const [imageIdentifier] = imageUrlSuffix.split(".");

  const standardParams = `h_${height},w_${width},q_auto`;

  if (alwaysFill) {
    return `${CLOUNDINARY_BUCKET_URL}c_fill,${standardParams}/${imageIdentifier}.png`;
  } else {
    // this should detect if the image is likely to be a vector logo. and will use cover/pad over contain/fill
    return `${CLOUNDINARY_BUCKET_URL}if_ils_gt_0.5/c_pad,${standardParams}/if_else/c_fill,${standardParams}/if_end/${imageIdentifier}.png`;
  }
};
