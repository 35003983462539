import React, { memo, MouseEvent, useMemo, useState } from "react";
import Linkify from "react-linkify";
import clsx from "clsx";

import { Avatar, Typography, IconButton, styled, Box } from "@mui/material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

import { componentDecorator } from "./componentDecorator";
import { getInitials } from "../../helpers/utils";
import ParticipantRoleTag from "../Participants/ParticipantRoleTag";
import { UserRole } from "../../contracts/user/user";
import { Poll } from "../../providers/polls/PollProvider";
import { PollPreviewBox } from "./PollPreviewBox";
import { stringToColor } from "../../helpers/stringToColor";
import TimeAgo from "../TimeAgo";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import ReactionPicker from "./reactions/ReactionPicker";
import ReactionCounts from "./reactions/ReactionCounts";
import { Message, MessageAction } from "../../providers/chat/ChatProvider";
import type { MappedMessage } from "./ChatComments";
import { Reaction } from "@src/contracts/customization/reactions";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { transformImageUrl } from "@src/helpers/image";

const UI_AVATAR_REGEX = /^https?:\/\/ui-avatars\.com/i;

const MessageBoxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  gap: theme.spacing(1),
  marginTop: 0,
  marginBottom: theme.spacing(1),
  "&.is-own-message": {
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    marginTop: 1,
  },
  "&.is-pending": {
    opacity: 0.6,
  },
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  height: 36,
  width: 36,
}));

const MessageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  flex: 1,
  width: 200,
  "&.is-own-message": {
    alignItems: "flex-end",
  },
}));

const MessageTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  "&.is-own-message": {
    flexDirection: "row-reverse",
  },
}));

const StyledMessage = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.spacing(1.5),
  borderTopLeftRadius: 0,
  borderTopRightRadius: theme.spacing(1.5),
  padding: theme.spacing(1),
  marginLeft: 0,
  marginRight: theme.spacing(2),
  overflowWrap: "break-word",
  wordBreak: "break-word",
  // Get contrasting font colors based on theme background
  backgroundColor:
    theme.customTheme?.chat?.colors?.[1] || theme.palette.base[50],
  color:
    theme.customTheme?.chat?.colors?.[2] ||
    theme.palette.getContrastText(
      theme.customTheme?.chat?.colors?.[1] ||
        (theme.palette.base[50] as string),
    ),
  " a": {
    // All links should keep the same color
    // this will help w/ contrast, use underline to denote link
    color: "inherit",
    textDecoration: "underline",
    cursor: "pointer",
  },

  "&.is-own-message": {
    borderTopLeftRadius: theme.spacing(1.5),
    borderTopRightRadius: 0,
    marginRight: 0,
    backgroundColor:
      theme.customTheme?.chat?.colors?.[3] || theme.palette.secondary.main,
    // Get contrasting font colors based on theme background
    color:
      theme.customTheme?.chat?.colors?.[4] ||
      theme.palette.getContrastText(
        theme.customTheme?.chat?.colors?.[3] || theme.palette.secondary.main,
      ),
  },
  "&.is-pinned": {
    backgroundColor: theme.palette.common.white,
  },
  "&.is-own-message:not(.has-menu)": {
    marginLeft: theme.spacing(2),
  },
  "&.has-menu": {
    marginRight: 0,
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  alignSelf: "flex-start",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}40`,
  },
  "&.is-open": {
    color: theme.palette.secondary.main,
    backgroundColor: `${theme.palette.primary.main}40`,
  },
}));

const TimestampContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  alignSelf: "stretch",
}));

export type MessageOrPoll =
  | {
      message: string;
      onReact?: (message: Message, reaction: Reaction) => void;
    }
  | { poll: Poll };

type MessageBoxProps = {
  /** The uuid of the user who sent the message */
  senderId: string;
  /** The uuid of the local user */
  userId: string;
  username: string;
  timestamp: string | number;
  isPinned?: boolean;
  isOwn: boolean;
  avatar?: string;
  hideAvatar?: boolean;
  showTimestamp?: boolean;
  userRole?: UserRole;
  onMenuClick?: (
    event: MouseEvent<HTMLButtonElement>,
    message: MappedMessage | undefined,
  ) => void;
  isAnonymous?: boolean;
  showParticipantNames?: ParticipantNameOption;
  showReactions?: boolean;
  item?: MappedMessage | Poll;
  isPending?: boolean;
} & MessageOrPoll;

export const MessageBox = memo(
  ({
    senderId,
    userId,
    isOwn,
    avatar,
    hideAvatar,
    username,
    userRole,
    timestamp,
    showTimestamp,
    isAnonymous,
    isPinned,
    showParticipantNames = ParticipantNameOption.ALL,
    showReactions,
    item,
    isPending,
    onMenuClick,
    ...props
  }: MessageBoxProps) => {
    const [hovered, setHovered] = useState(false);

    const avatarImg = useMemo(
      () =>
        isAnonymous || (!hideAvatar && avatar && UI_AVATAR_REGEX.test(avatar))
          ? undefined
          : avatar,
      [isAnonymous, hideAvatar, avatar],
    );

    const initials = useMemo(
      () => (isAnonymous ? null : getInitials(username)),
      [isAnonymous, username],
    );

    const avatarColors = useMemo(() => {
      if (hideAvatar || isAnonymous) {
        return undefined;
      }

      if (!avatar) {
        // If no avatar, generate a color from their id
        return {
          backgroundColor: `#${stringToColor(senderId)}`,
        };
      }
      if (UI_AVATAR_REGEX.test(avatar)) {
        // If this is a ui-avatar, pull out the color value to avoid rate limit errors
        const u = new URL(avatar);
        const bg = u.searchParams.get("background");
        const font = u.searchParams.get("color");
        return {
          backgroundColor: bg ? `#${bg}` : undefined,
          color: font ? `#${font}` : undefined,
        };
      }
      // Use the picture the user uploaded
      return undefined;
    }, [hideAvatar, isAnonymous, avatar, senderId]);

    const isMessage = "message" in props;
    const messageItem = item as MappedMessage;
    const canReact =
      showReactions &&
      isMessage &&
      messageItem?.message &&
      "onReact" in props &&
      typeof props.onReact === "function";
    const showReactionCounts = canReact && messageItem?.actions?.reactions;

    const handleReactionSelect = useLatestCallback((reaction: Reaction) => {
      if (canReact) {
        props.onReact?.(item as Message, reaction);
      }
    });

    const handleCloseReactionPicker = useLatestCallback(() =>
      setHovered(false),
    );

    return (
      <MessageBoxContainer
        data-testid="chat-item-container"
        className={clsx({ "is-pending": isPending, "is-own-message": isOwn })}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={handleCloseReactionPicker}
      >
        {!isOwn && !hideAvatar && (
          <UserAvatar
            data-testid="chat-message-user-avatar"
            alt={isAnonymous ? undefined : username}
            src={transformImageUrl({
              url: avatarImg || "",
              width: 72,
              height: 72,
            })}
            style={avatarColors}
          >
            {initials}
          </UserAvatar>
        )}

        <MessageContainer className={clsx({ "is-own-message": isOwn })}>
          {!isOwn && (
            <Box
              sx={{
                display: "flex",
                overflow: "hidden",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 0.5,
                width: "100%",
              }}
            >
              <Typography
                data-testid="chat-message-user-name"
                noWrap
                sx={{
                  margin: 0,
                  marginRight: 1,
                  maxHeight: "3em",
                  overflowWrap: "break-word",
                }}
              >
                {isAnonymous ? "Anonymous User" : username}
              </Typography>
              {!isAnonymous && <ParticipantRoleTag userRole={userRole} />}
            </Box>
          )}

          <MessageTextContainer
            className={clsx({ "is-own-message": isOwn, "is-pinned": isPinned })}
          >
            {/* Display chat messages or recent Polls */}
            {"message" in props ? (
              <StyledMessage
                className={clsx({
                  "is-own-message": isOwn,
                  "has-menu": !!onMenuClick,
                })}
              >
                <Typography
                  data-testid="chat-message-text"
                  margin={0}
                  color="inherit"
                >
                  <Linkify componentDecorator={componentDecorator}>
                    {props.message}
                  </Linkify>
                </Typography>
                {canReact && (
                  <ReactionPicker
                    in={hovered}
                    onSelect={handleReactionSelect}
                    onClose={handleCloseReactionPicker}
                  />
                )}
              </StyledMessage>
            ) : (
              <PollPreviewBox {...props.poll} />
            )}

            {onMenuClick && (
              <MenuButton
                data-testid="chat-item-menu-button"
                size="small"
                onClick={(event) => onMenuClick(event, item as MappedMessage)}
              >
                <MoreHoriz />
              </MenuButton>
            )}
          </MessageTextContainer>

          {showReactionCounts && (
            <ReactionCounts
              userId={userId}
              reactions={
                messageItem.actions.reactions as MessageAction<Reaction>
              }
              onSelect={handleReactionSelect}
            />
          )}

          <TimestampContainer>
            {showTimestamp && (
              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                  paddingRight: 1,
                  marginRight: isOwn ? 0 : "auto",
                }}
              >
                <TimeAgo date={timestamp} />
              </Typography>
            )}
          </TimestampContainer>
        </MessageContainer>
      </MessageBoxContainer>
    );
  },
);
