import React, { memo } from "react";
import TimeAgo from "react-timeago";

import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  SvgIcon,
  Typography,
  Menu,
  MenuItem as MuiMenuItem,
  styled,
} from "@mui/material";
import {
  bindTrigger,
  bindMenu,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { MoreIcon } from "../icons";
import { UserRole } from "../../contracts/user/user";
import ParticipantRoleTag from "./ParticipantRoleTag";
import { Message } from "../../providers/chat/ChatProvider";
import { transformImageUrl } from "@src/helpers/image";

interface MenuAction {
  label: string;
  /**
   * Custom color, currently only supports "danger" value
   */
  color?: string;
  onClick: () => void;
}

interface ParticipantViewProps {
  username: string;
  userRole?: UserRole;
  avatar: string;
  onClick?: () => void;
  menuActions?: MenuAction[] | undefined;
  badge?: string | number;
  recentMessage?: Message | null;
}

const MenuStyles = styled(Menu)(({ theme: { spacing } }) => ({
  "	.MuiMenu-paper": {
    borderRadius: spacing(1.5),
    borderBottomRightRadius: 0,
    background: "white",
  },
}));

export const ParticipantView = memo(
  ({
    username,
    userRole,
    avatar,
    onClick,
    menuActions,
    badge,
    recentMessage,
  }: ParticipantViewProps) => {
    const popupState = usePopupState({
      variant: "popover",
      popupId: `participant-menu`,
    });

    const bindTriggerProps = bindTrigger(popupState);
    const bindMenuProps = bindMenu(popupState);

    return (
      <>
        <ListItem
          sx={({ palette }) => ({
            justifyContent: "space-between",
            padding: ".5vh 1vh",
            "&:hover + .MuiDivider-root": {
              backgroundColor: palette.primary.main,
            },
          })}
          button
          onClick={onClick}
        >
          <Box display="flex" alignItems="center" flex="1" overflow="hidden">
            <Box marginRight={3} marginLeft={1}>
              <Avatar
                sx={{
                  height: "min(4.5vh, 40px)",
                  width: "min(4.5vh, 40px)",
                }}
                src={transformImageUrl({ url: avatar, width: 80, height: 80 })}
              />
            </Box>
            <Box flex="1" minWidth={0}>
              <Box
                display="flex"
                flex="1"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="row">
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.text.primary,
                      fontWeight: 600,
                      whiteSpace: "normal",
                    })}
                    noWrap
                  >
                    {username}
                  </Typography>
                  <ParticipantRoleTag userRole={userRole} />
                </Box>
                {recentMessage && (
                  <Typography noWrap variant="caption">
                    <TimeAgo date={recentMessage.message.timestamp} />
                  </Typography>
                )}
              </Box>
              {recentMessage && (
                <Typography
                  sx={({ palette }) => ({
                    color: palette.grey[700],
                    flexWrap: "nowrap",
                  })}
                  noWrap
                  variant="caption"
                >
                  {recentMessage.message.comment.slice(0, 55)}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" flexGrow={0}>
            {badge !== undefined && (
              <Badge
                badgeContent={badge}
                color="primary"
                style={{
                  display: "inline-block",
                  position: "static",
                  transform: "none",
                }}
              />
            )}
          </Box>
          {!menuActions?.length ? null : (
            <ListItemSecondaryAction
              sx={{
                position: "relative",
                transform: "none",
                right: 0,
              }}
            >
              <IconButton
                aria-label="More options"
                {...bindTriggerProps}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  bindTriggerProps.onClick(event);
                }}
                size="large"
              >
                <SvgIcon fontSize="small">
                  <MoreIcon />
                </SvgIcon>
              </IconButton>
            </ListItemSecondaryAction>
          )}
          <MenuStyles
            {...bindMenuProps}
            onClose={(event: any) => {
              event.stopPropagation();
              event.preventDefault();
              bindMenuProps.onClose();
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {menuActions
              ? menuActions.map(({ label, color, onClick }, index) => (
                  <div key={label}>
                    {index !== 0 && <Divider />}
                    <MuiMenuItem
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onClick();
                        popupState.setOpen(false);
                      }}
                      sx={({ palette }) => ({
                        color:
                          color === "danger" ? palette.danger.main : undefined,
                      })}
                    >
                      {label}
                    </MuiMenuItem>
                  </div>
                ))
              : null}
          </MenuStyles>
        </ListItem>
        <Divider
          sx={{
            marginLeft: "8vh",
          }}
        />
      </>
    );
  },
);
