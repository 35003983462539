import React, { Suspense, useMemo, useRef, useState } from "react";
import isEqual from "lodash/isEqual";
import {
  Typography,
  InputLabel,
  TextField,
  CircularProgress,
  Button as MuiButton,
  styled,
  Theme,
} from "@mui/material";
import { useAsyncFn } from "react-use";
import { useQueryClient } from "react-query";

import { Api } from "../../api/api";
import { QueryKeys } from "../../api/QueryKeys";
import { User } from "../../contracts/user/user";
import PictureUpload from "./PictureUpload";
import Button from "../buttons/Button";
import ConfirmationDialog from "../ConfirmationDialog";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLogoutUser } from "@src/providers/UserProvider";
import { useIsUserInStage } from "@src/providers/EventStateProvider";
import { useIsUserInCircle } from "@src/providers/NetworkingHubProvider";
import { logEvent, MetricsEvents } from "@src/metrics";
import type { UserUpdate } from "@src/contracts/user/user";

import lazyLoader from "@src/helpers/lazyLoader";

const CompatibilityChecker = lazyLoader(
  () => import("../dialogs/CompatibilityChecker"),
  "CompatibilityChecker",
);

interface Props {
  /**
   * The id of the event or networking hub
   */
  entityId: string;
  /**
   * The user object
   */
  user: User;

  /**
   * The variant to display for the compatibility checker
   */
  variant: "live" | "networking";
}

const Container = styled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
  minHeight: 0,
  overflow: "auto",
  padding: spacing(1),
  paddingLeft: spacing(1.5),
  paddingRight: spacing(1.5),
  [breakpoints.down("sm")]: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const PictureUploadContainer = styled("div")(({ theme: { spacing } }) => ({
  textAlign: "center",
  marginTop: spacing(1),
  marginBottom: spacing(1),
}));

const ProfileTab = (props: Props) => {
  const { user, variant } = props;
  const isUserInStage = useIsUserInStage();
  const isUserInCircle = useIsUserInCircle();
  const initialRef = useRef({
    name: user.name || "",
    companyName: user.companyName || "",
    title: user.title || "",
    profilePicture: user.profilePicture,
  });
  const [form, setForm] = useState(initialRef.current);
  const [showCompatibilityChecker, setShowCompatibilityChecker] =
    React.useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const logout = useLogoutUser();

  const queryClient = useQueryClient();

  const [saveState, onSave] = useAsyncFn(async () => {
    const updates: UserUpdate = {
      uid: user.uid,
      originalId: user.originalId,
      name: form.name,
      companyName: form.companyName,
      title: form.title,
      email: user.email,
      profilePicture: form.profilePicture,
      updated: true,
      eventId: props.variant === "live" ? props.entityId : undefined,
    };

    await Api.UserApi.createUpdate(updates);
    queryClient.invalidateQueries(QueryKeys.user(user.uid));
    initialRef.current = form;
  }, [user.uid, user.originalId, form, user.email]);

  const displayNameHelperText = useMemo(() => {
    if (!form.name) {
      return "Display name required";
    }

    if (form.name.length > 50) {
      return "Display name must be less than 50 characters";
    }

    return null;
  }, [form]);

  const saveDisabled =
    !form.name || form.name.length > 50 || isEqual(initialRef.current, form);

  const inputSX = useMemo(
    () =>
      ({ palette }: Theme) => ({
        backgroundColor: palette.grey[100],
        padding: 0.5,
        paddingLeft: 1,
        paddingRight: 1,
        borderColor: palette.divider,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 2,
        "&:hover": {
          borderColor: palette.primary.main,
        },
      }),
    [],
  );

  return (
    <Container>
      <Typography
        sx={{ paddingBottom: 1, overflowWrap: "break-word", width: "100%" }}
        variant="h3"
      >
        {user.name}
      </Typography>

      <PictureUploadContainer>
        <PictureUpload
          currentImage={form.profilePicture}
          imageUploaded={(profilePicture) =>
            setForm((f) => ({
              ...f,
              profilePicture,
            }))
          }
        />
      </PictureUploadContainer>

      <TextField
        sx={{ marginTop: 1 }}
        fullWidth
        InputProps={{
          disableUnderline: true,
          sx: inputSX,
        }}
        label="Display Name"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Enter name..."
        onChange={(event) => {
          setForm((f) => ({
            ...f,
            name: event.target.value,
          }));
        }}
        value={form.name}
        error={!form.name || form.name.length > 50}
        helperText={displayNameHelperText}
      />

      <TextField
        sx={{ marginTop: 1 }}
        fullWidth
        InputProps={{
          disableUnderline: true,
          sx: inputSX,
        }}
        label="Company Name"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Enter company name..."
        onChange={(event) => {
          setForm((f) => ({
            ...f,
            companyName: event.target.value,
          }));
        }}
        value={form.companyName}
      />

      <TextField
        sx={{ marginTop: 1 }}
        fullWidth
        InputProps={{
          disableUnderline: true,
          sx: inputSX,
        }}
        label="Title"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Enter title..."
        onChange={(event) => {
          setForm((f) => ({
            ...f,
            title: event.target.value,
          }));
        }}
        value={form.title}
      />

      <MuiButton
        color="primary"
        variant="contained"
        onClick={onSave}
        disabled={saveDisabled || saveState.loading}
        fullWidth
        sx={{ marginY: 1 }}
        endIcon={
          saveState.loading && <CircularProgress size={20} color="inherit" />
        }
      >
        Save Changes
      </MuiButton>

      <InputLabel shrink sx={{ alignSelf: "flex-start", marginTop: 1 }}>
        System check{" "}
      </InputLabel>
      <Button
        variant="text"
        onClick={() => {
          logEvent(MetricsEvents.VirtualStage.COMPATIBILITY_CHECKER);
          setShowCompatibilityChecker(true);
        }}
        startIcon={<NetworkCheckIcon />}
        disabled={isUserInStage || isUserInCircle}
        sx={{
          alignSelf: "flex-start",
          ml: 1,
          p: 0.5,
          textAlign: "left",
        }}
      >
        Run Compatibility Checker
      </Button>

      <InputLabel shrink sx={{ alignSelf: "flex-start", marginTop: 1 }}>
        Having trouble with your profile?{" "}
      </InputLabel>
      <Button
        variant="text"
        onClick={() => setShowLogoutDialog(true)}
        startIcon={<LogoutIcon />}
        data-testid="logout-btn"
        sx={{
          alignSelf: "flex-start",
          ml: 1,
          p: 0.5,
          textAlign: "left",
        }}
      >
        Logout
      </Button>
      <Suspense fallback={<></>}>
        <CompatibilityChecker
          context={variant === "live" ? "event" : "networking"}
          open={showCompatibilityChecker}
          onClose={() => setShowCompatibilityChecker(false)}
          onComplete={() => setShowCompatibilityChecker(false)}
          onCancel={() => setShowCompatibilityChecker(false)}
          maxWidth="sm"
          fullWidth
        />
      </Suspense>

      {/* logout dialog */}
      <ConfirmationDialog
        open={showLogoutDialog}
        title="Are you sure you want to logout?"
        body="This action will log you out of all your events and clear your profile information."
        confirmText="Logout"
        onConfirm={logout}
        onClose={() => setShowLogoutDialog(false)}
      />
    </Container>
  );
};

export default React.memo(ProfileTab);
