/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import invariant from "tiny-invariant";

import {
  Box,
  Card,
  Divider,
  Tooltip,
  Typography,
  CardContent,
} from "@mui/material";
import { Alert } from "@mui/material";
import { format } from "date-fns";
import { usePrevious } from "react-use";

import { UserRole } from "../../../contracts/user/user";
import { useEvent, useHasEventEnded } from "../../../providers/EventProvider";
import {
  useEventState,
  useEventVideoType,
} from "../../../providers/EventStateProvider";
import { useUser } from "../../../providers/UserProvider";
import PromotedConfirmation from "../../dialogs/content/PromotedConfirmation";
import { StageHeaderLegacy } from "./StageHeaderLegacy";
import { CountdownClock } from "./Clock";
import EnterPasscodeModalButton from "../../buttons/EnterPasscodeModalButton";
import EditReplayButton from "../../EventReplayManagement/EditReplayButton";
import CountdownMessageLegacy from "./CountdownMessageLegacy";
import { VideoType } from "@src/models/eventType";
import { useHandleJoinNetworking } from "./useHandleJoinNetworking";
import Button, { Props as ButtonProps } from "@src/components/buttons/Button";
import { NetworkingHubIcon } from "../../icons";
import { isOptimalBrowser } from "../../../helpers/browser";
import { useDialog } from "@src/providers/DialogProvider";
import { EventVideoPlayer } from "@src/components/Event/EventVideoPlayer";
import { useVideoSource } from "./useVideoSource";
import { useUpdateVideoView } from "./useUpdateVideoView";
import { atcb_action } from "add-to-calendar-button";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import { utcToZonedTime } from "date-fns-tz";
import { Event } from "@src/contracts/event/event";
import { JoinCodeDialog } from "@src/components/SideBar/JoinCodeDialog";
import { useAuthParams } from "@src/providers/QueryParamsProvider";

const JoinNetworkingButton = React.memo(({ onClick }: ButtonProps) => {
  return (
    <Button
      sx={{ px: 3, height: "36px", width: "176px" }}
      onClick={onClick}
      startIcon={<NetworkingHubIcon width={16} height={16} />}
      size="small"
      variant="outlined"
    >
      Join Networking
    </Button>
  );
});

export interface Props {
  name: string;
  eventName: string;
  profilePicture: string;
  eventPicture: string;
  userRole: UserRole;
  startDate: Date;
  onEnterStage?: () => void;
}

interface AddToCalendarButtonProps {
  event?: (Event & { goLiveLocked: boolean; playbackLocked: boolean }) | null;
  joinCode?: string;
}

export const AddToCalendarButton = ({
  event,
  joinCode,
}: AddToCalendarButtonProps) => {
  if (!event || !event.startDate || !event.endDate || !joinCode) {
    return null;
  }

  // Always redirect users to the custom url or current one
  const location =
    event.registration?.customUrl ||
    window.location.origin + window.location.pathname;
  const joinUrl = `${location}?joinCode=${joinCode}`;
  const startDateToTimezone = utcToZonedTime(event.startDate, event.timezone);
  const endDateToTimezone = utcToZonedTime(event.endDate, event.timezone);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        sx={{ fontWeight: "500" }}
        onClick={(e) => {
          try {
            e.preventDefault();
            if (joinCode) {
              atcb_action({
                name: event.name,
                size: "4",
                description: `You are successfully registered for ${event.name}. We look forward to seeing you soon. <br>Join event here: [url]${joinUrl}[/url]`,
                startDate: format(startDateToTimezone, "yyyy-MM-dd"),
                endDate: format(endDateToTimezone, "yyyy-MM-dd"),
                startTime: format(startDateToTimezone, "HH:mm"),
                endTime: format(endDateToTimezone, "HH:mm"),
                location: joinUrl,
                options: [
                  "Apple",
                  "Google",
                  "iCal",
                  "Microsoft365",
                  "Outlook.com",
                  "Yahoo",
                ],
                timeZone: event.timezone,
                iCalFileName: "Reminder-Event",
              });
            }
          } catch (error) {
            // ignore error, nothing we can do
          }
        }}
      >
        <>
          <CalendarMonth sx={{ mr: 1 }} />
          Add to calendar
        </>
      </Button>
    </>
  );
};

interface EventNotLiveProps {
  onEnterStage?: Props["onEnterStage"];
  isStreamLive: boolean;
  replayEnabled: boolean;
}

export const EventNotLive = ({
  onEnterStage,
  isStreamLive,
  replayEnabled,
}: EventNotLiveProps) => {
  const { openDialog } = useDialog();
  const { data: event } = useEvent();
  const { userRole, name } = useUser();
  const { isNetworkingLive } = useEventState();
  const handleNetworkingJoin = useHandleJoinNetworking();

  const params = useAuthParams();

  // get join code from params (prioritized as access method below)
  const joinCode = params.joinCode;

  const disableBrowserCheck = useFeatureFlag(
    FeatureFlag.VS_DISABLE_BROWSER_CHECK,
  );
  const optimalBrowser = disableBrowserCheck || isOptimalBrowser();

  const showJoinNetworking =
    isNetworkingLive && userRole < UserRole.Unregistered;
  const isUnregistered = userRole === UserRole.Unregistered;

  const showSecondDivider =
    // our registration form is disabled and they are not registered or need to use a code
    (!!event?.registration?.outsideOfAppEnabled && isUnregistered) ||
    // they are authenticated AND networking has started, they can enter the green room, or they can elevate their role
    (!isUnregistered &&
      (showJoinNetworking ||
        onEnterStage ||
        (userRole === UserRole.Viewer && !!event?.isRegistrationModeEnabled)));

  const eventHasEnded = useHasEventEnded();

  // please typescript
  if (!event) return null;

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        paddingLeft: 1,
        paddingRight: 1,
      }}
    >
      <Card
        sx={{
          my: 2,
          width: "100%",
          maxWidth: 500,
        }}
      >
        <CardContent sx={{ p: 3 }}>
          <EditReplayButton sx={{ mt: 0, mb: 2 }} />
          <Box>
            <Typography variant="h3" mb={2} sx={{ wordBreak: "break-word" }}>
              {event.name}
            </Typography>
            {!eventHasEnded && (
              <>
                <Typography my={1}>
                  {format(event.startDate, "EEEE, MMMM d, yyyy")} |{" "}
                  {event.startDate.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
                <AddToCalendarButton joinCode={joinCode} event={event} />
              </>
            )}
          </Box>
          {eventHasEnded ? (
            <>
              <Typography>
                This event was live:{" "}
                {format(event.startDate, "EEEE, MMMM d, yyyy")}
              </Typography>
              {replayEnabled && (
                <Typography mt={2}>
                  On-demand video available, register to watch
                </Typography>
              )}
            </>
          ) : (
            <>
              <Divider sx={{ mx: -3, my: 2 }} />
              <CountdownClock date={event.startDate} backwards>
                {(countdownProps) => (
                  <CountdownMessageLegacy {...countdownProps} />
                )}
              </CountdownClock>
            </>
          )}
          {/* use a -3 margin to get the divider to reach the card edge since padding is 3 */}
          {showSecondDivider && <Divider sx={{ mx: -3, my: 2 }} />}
          {onEnterStage && (
            <Box sx={{ backgroundColor: "common.white" }}>
              <Box my={2}>
                <Typography mb={0.5}>{name}</Typography>
                <Typography fontWeight="bold" mb={0.5}>{`You are a ${
                  userRole === UserRole.Organizer ? "host" : "presenter"
                }`}</Typography>
              </Box>
              <Tooltip
                title={
                  !optimalBrowser ? (
                    <>
                      {
                        "Your browser is not compatible. If you wish to join virtually, please download the "
                      }
                      <a
                        href="https://www.google.com/chrome/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        latest version of Chrome.
                      </a>
                    </>
                  ) : (
                    ""
                  )
                }
              >
                <div>
                  <Button
                    onClick={onEnterStage}
                    data-testid="countdown-enter-stage-btn"
                    color="primary"
                    variant="contained"
                    disabled={!optimalBrowser}
                    sx={{ px: 3, height: "36px", width: "176px" }}
                    size="small"
                  >
                    {isStreamLive ? "Join Backstage" : "Enter Greenroom"}
                  </Button>
                </div>
              </Tooltip>
            </Box>
          )}
          {showJoinNetworking ? (
            <Box mt={1}>
              <JoinNetworkingButton onClick={handleNetworkingJoin} />
            </Box>
          ) : null}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              "& > *, & > button": {
                mt: 1,
              },
            }}
          >
            {event?.isRegistrationModeEnabled && <EnterPasscodeModalButton />}
            {event?.registration?.outsideOfAppEnabled && isUnregistered && (
              <Typography
                sx={{
                  mb: 2,
                  mt: { xs: 2, md: 0 },
                }}
              >
                {"Already registered for the event? join by entering your "}
                <Typography
                  component="span"
                  onClick={() =>
                    openDialog("join-code-modal", <JoinCodeDialog />)
                  }
                  fontWeight="bold"
                  color="primary"
                  sx={{
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  {"JOIN CODE"}
                </Typography>
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export const ViewerLayout = () => {
  const { openDialog } = useDialog();
  const { userRole } = useUser();
  const {
    setPromoteStatus,
    promoteStatus,
    enterStage,
    isEventLive,
    replayEnabled,
  } = useEventState();
  const [startingSoon, setStartingSoon] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isStreamLive, setIsStreamLive] = useState(isEventLive);
  const { data: event } = useEvent();
  invariant(event, "Event is required");

  const optimalBrowser = isOptimalBrowser();
  const disableBrowserCheck = useFeatureFlag(
    FeatureFlag.VS_DISABLE_BROWSER_CHECK,
  );
  const showBadBrowser =
    [UserRole.Organizer, UserRole.Presenter].includes(userRole) &&
    !disableBrowserCheck &&
    !optimalBrowser;

  let videoType = useEventVideoType();
  if (isStreamLive) {
    videoType = VideoType.Live;
  }

  const handleUpdateVideoView = useUpdateVideoView(videoType);

  const { src, reset, isLoading } = useVideoSource({
    videoType,
    disabled: userRole === UserRole.Unregistered,
  });

  const previousSource = usePrevious(src);
  const hasNewVideoView =
    src &&
    previousSource !== src &&
    videoType !== VideoType.Upcoming &&
    userRole !== UserRole.Unregistered;
  useEffect(() => {
    if (hasNewVideoView) {
      handleUpdateVideoView();
    }
  }, [handleUpdateVideoView, hasNewVideoView]);

  useEffect(() => {
    setStartingSoon(isLoading);
  }, [isLoading]);

  // Fixes issue where livestream ends too soon
  // we internally need to change the type after the stream stops playing
  // as the flag can end before the stream actually ends
  const lastIsEventLive = usePrevious(isEventLive);
  useEffect(() => {
    if (lastIsEventLive && !isEventLive && isVideoPlaying) {
      // When we switch from live to not live, make sure we wait for the live video feed to end
      return;
    }

    setIsStreamLive(isEventLive);
  }, [isEventLive, isVideoPlaying]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * If the event is currently live, or they've enabled replays, show the video player and stage
   */
  const showReplay = replayEnabled || !!event.streamInfo.videoLoopUrl;
  const showLive = isStreamLive || isEventLive;

  const showStageContents =
    userRole !== UserRole.Unregistered && // Unregistered users can't view live event stage contents
    !startingSoon && // verifies streaming URL is working
    (showLive || showReplay); // show replays (when enabled)

  useEffect(() => {
    if (promoteStatus === "promoted") {
      openDialog(
        "PromotedConfirmation",
        <PromotedConfirmation
          role={UserRole.Presenter}
          onConfirm={() => setPromoteStatus("accepted")}
          onCancel={() => setPromoteStatus("canceled")}
        />,
        {
          maxWidth: "xs",
          priority: "high",
          onClose: () => setPromoteStatus("canceled"),
        },
      );
    }
  }, [openDialog, promoteStatus, setPromoteStatus]);

  return (
    <>
      {showBadBrowser && (
        <Alert
          style={{
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
          }}
          severity="warning"
        >
          {
            "Your browser is not compatible. If you wish to join virtually, please download the "
          }
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            latest version of Chrome.
          </a>
        </Alert>
      )}
      {showStageContents && <StageHeaderLegacy isStreamLive={isStreamLive} />}
      {/* If unregistered do not play videos */}
      {showStageContents && (
        <EventVideoPlayer
          src={src}
          loop={videoType === VideoType.OnDemand}
          videoType={videoType}
          sx={{ display: showStageContents ? "flex" : "none" }}
          onPlayingStateChange={setIsVideoPlaying}
          onError={() => reset()}
        />
      )}
      {!showStageContents && (
        <EventNotLive
          onEnterStage={
            userRole === UserRole.Presenter || userRole === UserRole.Organizer
              ? enterStage
              : undefined
          }
          replayEnabled={showReplay}
          isStreamLive={isStreamLive}
        />
      )}
    </>
  );
};
