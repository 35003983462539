import { UserRole } from "@src/contracts/user/user";
import { useRouteParams } from "@src/hooks/useRouteParams";
import { useEvent } from "@src/providers/EventProvider";
import {
  EventScheduledTimelineStatus,
  useEventScheduledTimelineStatus,
  useEventState,
} from "@src/providers/EventStateProvider";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { useUser } from "@src/providers/UserProvider";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface ShouldHostBeInGreenroomArgs {
  isEventLive: boolean;
  delayedScheduledStatus: EventScheduledTimelineStatus;
  showReplay: boolean;
}
const shouldHostBeInGreenroom = ({
  isEventLive,
  delayedScheduledStatus,
  showReplay,
}: ShouldHostBeInGreenroomArgs) => {
  if (isEventLive) return true;
  if (showReplay) return false;
  if (delayedScheduledStatus === EventScheduledTimelineStatus.AFTER)
    return false;
  return true;
};

export const useNavigateUsers = () => {
  const { data: event } = useEvent();
  const user = useUser();
  const { eventId } = useRouteParams();
  const routingRefactor = useFeatureFlag(FeatureFlag.ENABLE_ROUTING_REFACTOR);
  const delayedScheduledStatus = useEventScheduledTimelineStatus(60 * 30);

  const { replayEnabled, isEventLive } = useEventState();

  const location = useLocation();
  const navigate = useNavigate();

  const showReplay = replayEnabled || !!event?.streamInfo.videoLoopUrl;

  useEffect(() => {
    const isOnWatchPage = location.pathname.endsWith("/watch");
    const isOnGreenroomPage = location.pathname.endsWith("/greenroom");
    const isOnEventPage = !isOnWatchPage && !isOnGreenroomPage;
    const shouldBeInGreenroom = shouldHostBeInGreenroom({
      isEventLive,
      delayedScheduledStatus,
      showReplay,
    });

    if (routingRefactor) {
      switch (user.userRole) {
        case UserRole.Viewer:
          if (!isOnWatchPage) {
            navigate(
              { pathname: `/event/${eventId}/watch`, search: location.search },
              { replace: true },
            );
          }
          break;
        case UserRole.Presenter:
        case UserRole.Organizer:
          if (!shouldBeInGreenroom && !isOnWatchPage) {
            navigate(
              {
                pathname: `/event/${eventId}/watch`,
                search: location.search,
              },
              { replace: true },
            );
          } else if (shouldBeInGreenroom && !isOnGreenroomPage) {
            navigate(
              {
                pathname: `/event/${eventId}/greenroom`,
                search: location.search,
              },
              { replace: true },
            );
          }
          break;
        case UserRole.Unregistered:
        case UserRole.None:
          if (!isOnEventPage) {
            navigate(
              { pathname: `/event/${eventId}`, search: location.search },
              { replace: true },
            );
          }
          break;
      }
    } else {
      if (!isOnEventPage) {
        return navigate(
          { pathname: `/event/${eventId}`, search: location.search },
          { replace: true },
        );
      }
    }
  }, [
    eventId,
    isEventLive,
    location,
    user.userRole,
    routingRefactor,
    navigate,
    delayedScheduledStatus,
    showReplay,
  ]);
};
