import { UserRole } from "../contracts/user/user";
import Cookies from "js-cookie";
import { setAuthToken } from "./authSession";
import { isCookieStorageEnabled, isLocalStorageEnabled } from "./browser";

export const GENERAL_ACCESS_COOKIE = "generalAccessCookie";

export const LOCALSTORAGE_PREFIX = "sequel:access:";

// Overrides for default cookie settings
const OPTIONS: Cookies.CookieAttributes = {
  sameSite: "none",
  secure: true, // default to secure to fix Chrome cookie bug: https://stackoverflow.com/a/45095345
  expires: 31, // 31 days ~ 1 month from creation
};

// Override cookie defaults using OPTIONS above
Cookies.defaults = {
  ...Cookies.defaults,
  ...OPTIONS,
};

export interface AuthCookie {
  authKey: string;
  userRole: UserRole;
}

export interface GeneralAccessCookie extends AuthCookie {
  accessId: string;
}

const isValidCookieRole = (userRole?: UserRole): boolean => {
  return typeof userRole === "number" && !isNaN(userRole);
};

/**
 * @param uid The uid of the event or networkingHub
 * @returns the auth cookie id for the event or networking hub
 */
export const getAuthId = (uid: string): string => `auth_${uid}`;

/**
 * @param key The name of the stored cookie
 * @param defaults The value to return if the cookie is not found
 * @returns the stored cookie with the provided `key` or `defaults` otherwise
 */
export const getCookie = <T>(key: string, defaults?: Partial<T>): T => {
  if (isCookieStorageEnabled()) {
    return Cookies.getJSON(key) ?? defaults;
  } else if (isLocalStorageEnabled()) {
    const item = window.localStorage.getItem(`${LOCALSTORAGE_PREFIX}${key}`);
    return item ? JSON.parse(item) : defaults;
  }
  console.warn("Unable to get item from cookies or localstorage:", key);
  return defaults as any;
};

/**
 * Saves a cookie in the user's cookies
 * @param key The name of the cookie to save
 * @param value the value of the cookie to save
 */
export const setCookie = (
  key: string,
  value: any,
  options?: Cookies.CookieAttributes,
) => {
  if (isCookieStorageEnabled()) {
    Cookies.set(key, value, options);
  } else if (isLocalStorageEnabled()) {
    window.localStorage.setItem(
      `${LOCALSTORAGE_PREFIX}${key}`,
      JSON.stringify(value),
    );
  } else {
    console.error("Unable to set item in cookies or localstorage:", key);
  }
};

/**
 * Deletes the stored cookie with the provided `key`
 */
export const deleteCookie = (key: string) => {
  if (isCookieStorageEnabled()) {
    Cookies.remove(key);
  } else if (isLocalStorageEnabled()) {
    window.localStorage.removeItem(`${LOCALSTORAGE_PREFIX}${key}`);
  } else {
    console.error("Unable to remove item from cookies or localstorage:", key);
  }
};

/**
 * Deletes all the cookies the domain has access to
 */
export const deleteAllCookies = () => {
  if (isCookieStorageEnabled()) {
    Object.keys(Cookies.get()).forEach((cookie) => Cookies.remove(cookie));
  } else if (isLocalStorageEnabled()) {
    Object.keys(window.localStorage).forEach(
      (key) =>
        key.includes(LOCALSTORAGE_PREFIX) &&
        window.localStorage.removeItem(key),
    );
  } else {
    console.error("Unable to remove items from cookies or localstorage");
  }
};

/**
 * @param uid The uid of the event or networkingHub
 * @returns the stored auth cookie of the user (if any) with respect to the event or networking hub
 */
export const getAuthCookie = (uid?: string) => {
  if (!uid) {
    return {
      authKey: "",
      userRole: UserRole.Unregistered,
    } as AuthCookie;
  }

  const authId = getAuthId(uid);
  const authCookie = getCookie<AuthCookie>(authId, {});

  return {
    authKey:
      authCookie?.authKey && typeof authCookie?.authKey === "string"
        ? authCookie.authKey
        : "",
    userRole: isValidCookieRole(authCookie?.userRole)
      ? authCookie.userRole
      : UserRole.Unregistered,
  } as AuthCookie;
};

/**
 * @param userRole The role of the user to store in the cookie
 * @param uid The uid of the event or networkingHub
 * @param authKey New authKey to override exisiting authKey in cookies
 * @param options Optional cookies options to override cookie defaults
 */
export const saveAuthCookie = (
  userRole: UserRole,
  uid: string,
  authKey: string,
  options?: Cookies.CookieAttributes,
): void => {
  const authId = getAuthId(uid);
  const authCookie: AuthCookie = getAuthCookie(uid);

  // check if supplied role is valid
  userRole = isValidCookieRole(userRole) ? userRole : UserRole.Unregistered;

  const newAuthCookie: AuthCookie = {
    ...authCookie,
    authKey: authKey || authCookie?.authKey || "",
    userRole: Math.min(userRole, UserRole.Unregistered),
  };

  setCookie(authId, newAuthCookie, options);
  newAuthCookie.authKey && setAuthToken(newAuthCookie.authKey);
};

/**
 * Saves a generalAccess authCookie with companyId.
 *
 * Allows the cookie to be reusable across events belonging to the same company.
 */
export const saveGeneralAccessCookie = ({
  accessId,
  authKey,
  userRole,
}: GeneralAccessCookie) => {
  const newGeneralAccessCookie: GeneralAccessCookie = {
    accessId,
    userRole: isValidCookieRole(userRole) ? userRole : UserRole.Unregistered,
    authKey,
  };

  // Always overwrite with the new accessId
  setCookie(GENERAL_ACCESS_COOKIE, newGeneralAccessCookie);
};

/**
 * @param uid The uid of the Event or Networking Hub
 */
export const deleteAuthCookie = (uid: string) => deleteCookie(getAuthId(uid));

export { getAuthCookie as getUserRoleCookie, saveAuthCookie as saveUserRole };
