import { UserRole } from "@src/contracts/user/user";

/**
 * Events that are emitted to the parent frame when actions occur
 */
export enum PostMessageEvent {
  /**
   * Indicates the embed application has been initialized and is ready to receive events
   */
  INITIALIZED = "initialized",
  /**
   * Indicates the session has ended due to closing the window, refreshing the page, or another event.
   *
   * Posting messages to the frame will no longer have any effect. No events will be published.
   */
  DISCONNECTED = "disconnected",
  /**
   * Emitted with updates to the user, for data shape see: `PostMessageEventDataShape[PostMessageEvent.USER_UPDATED]`
   */
  USER_UPDATED = "user-updated",
  USER_REGISTERED = "user-registered",
}

export interface PostMessageEventDataShape {
  [PostMessageEvent.INITIALIZED]: {
    /**
     * Flag indicating whether the app is initialized
     */
    initialized: boolean;
  };
  [PostMessageEvent.USER_UPDATED]: {
    /**
     * The current role of the user in the application
     */
    role?: UserRole;
    /**
     * `true` if the user is on the virtual stage (backstage, green room, live stage), `false` if the user is not currently in the stage (audience view)
     */
    isOnLiveStage?: boolean;
  };
  [PostMessageEvent.DISCONNECTED]: never;
  [PostMessageEvent.USER_REGISTERED]: {
    email: string;
  };
}

/**
 * Commands sent from the parent frame when receiving a message.
 *
 * _Note_: Some commands may only work for allowed origins
 */
export enum MessageCommand {
  /**
   * Set the session ID used for isolated communication between a parent frame and the embed frame.
   *
   * Useful for controlling multiple embed frames within a single parent.
   */
  SET_SESSION_ID = "set-session-id",
  /**
   * Sets the role of the user in the embed application (temporary, runtime only change), for data shape see: `MessageCommandDataShape[MessageCommand.SET_USER_ROLE]`
   */
  SET_USER_ROLE = "set-user-role",
}

export interface MessageCommandDataShape {
  [MessageCommand.SET_USER_ROLE]: {
    /**
     * The new role for the user
     */
    role: UserRole | null;
  };
  [MessageCommand.SET_SESSION_ID]: {
    /**
     * The session ID to use
     */
    sessionId: string;
  };
}
