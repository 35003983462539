import invariant from "tiny-invariant";
import difference from "lodash/difference";
import PubNub from "pubnub";
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";

import { useLatestCallback } from "../../hooks/useLatestCallback";
import { useConfigValue } from "../config";
import { isValidUser } from "./isValidUser";
import { UserRole } from "../../contracts/user/user";
import { ChannelConfig, ChannelType } from "../pubnub/PubNubProvider";
import { TypeOptions as ToastTypeOptions } from "react-toastify/dist/types";
import { toast, Slide } from "react-toastify";
import ToastContent from "../../components/ToastContent";
import { timeSinceLastAnnouncement } from "./timeSinceLastAnnouncement";
import { Reaction } from "@src/contracts/customization/reactions";
import {
  ANNOUNCEMENTS_CHANNEL_SUFFIX,
  deleteMessageActionUpdater,
  getChannelsFromConfig,
  PINS_CHANNEL_SUFFIX,
  reactionMessageActionUpdater,
} from "./helpers";

export interface User {
  uid: string;
  profilePicture: string;
  name: string;
  role: UserRole;
  email: string;
}

export interface Message {
  pending?: boolean;
  channel: string;
  message: {
    comment: string;
    name: string;
    timestamp: string;
    uid: string;
    userUid: string;
  };
  user: User;
  timetoken?: string;
  actions: {
    // add our actions here so we can keep track of them
    deleted?: MessageAction<DeleteMessageActionValues>;
    reactions?: MessageAction<Reaction>;
  };
}

/**
 * Value is the different possible values that the action can have
 */
export type MessageAction<value extends string> = {
  [v in value]?: {
    actionTimetoken: string;
    uuid: string;
  }[];
};

export enum MessageActionTypes {
  DELETED = "deleted",
  REACTIONS = "reactions",
}
export enum DeleteMessageActionValues {
  SINGLE = ".",
  CLEARED = "c",
}

export interface PinnedMessage {
  user: User;
  timetoken?: string;
  timestamp: string;
  message: Message;
}

/**
 * Announcements are used to send notifications to users subscribed to a channel, which has announcements enabled
 */
export interface Announcement {
  /** The optional id of the announcement. Used to override existing toasts for announcements. */
  id?: string;
  title?: string;
  subtitle: string;
  type?: ToastTypeOptions;
  /** Specify who should see the announcement */
  to?: {
    /** Restrict announcement to only users with these roles */
    roles?: UserRole[];
    [property: string]: any;
  };
  /** Show the announcement only if the last announcement with same signature exceeds the specified time in seconds (defaults to 60s). */
  timeSinceLast?: number;
  /** hide the announcement for the user who sent it (defaults to `true`). */
  hideSelf?: boolean;
}

/**
 * Represents an announcement in PubNub message format
 */
export interface AnnouncementMessage {
  /** The optional id of the announcement. Used to override existing toasts for announcements. */
  id?: string;
  timetoken?: string;
  timestamp: string;
  message: Message;
  type: ToastTypeOptions;
  /** Specify who should see the announcement */
  to?: {
    /** Restrict announcement to only users with these roles */
    roles?: UserRole[];
    [prop: string]: any;
  };
  /** Show the announcement only if the last announcement with same name exceeds the specified time in seconds (defaults to 60s). */
  timeSinceLast: number;
  /** Hide the announcement for the user who sent it (defaults to `true`). */
  hideSelf: boolean;
}

export const CHAT_CHARACTER_LIMIT = 500;
export const SEND_TIMEOUT_MS = 1000;

export enum EventSizes {
  SMALL = 50,
  MEDIUM = 250,
  LARGE = 1000,
}

export enum ChatTimeouts {
  STANDARD = SEND_TIMEOUT_MS,
  MEDIUM = SEND_TIMEOUT_MS * 1.5,
  SLOW = SEND_TIMEOUT_MS * 2,
}

export interface IntrovokeChatContextValues {
  user: User;
  pubnub: PubNub;
  subscribe: (config: ChannelConfig) => void;
  unsubscribe: (config: ChannelConfig) => void;
  handleMessage: (messageEvent: PubNub.MessageEvent) => void;
  handleMessageAction: (event: PubNub.MessageActionEvent) => void;
  clear: (channelId: string) => void;
  channels: Channel[];
  activeChat: string | null;
  setActiveChat: (value: string | null) => void;
  activeChannelIds: string[];
  backgroundChannelIds: string[];
}

export interface NewChatCounts {
  newPrivateChatCount: number;
  totalNewChatCount: number;
}

export const getQueryKey = (channelId: string | undefined | null) => [
  "chat",
  channelId,
];

export const useChatsCount = () => {
  const { channels } = useEventChatContext();

  return useMemo(
    () =>
      channels.reduce(
        (reduced, channel) => {
          if (channel.type === "private") {
            reduced.newPrivateChatCount += channel?.newMessages ?? 0;
          }
          // increment total new messages
          reduced.totalNewChatCount += channel?.newMessages ?? 0;

          return reduced;
        },
        {
          newPrivateChatCount: 0,
          totalNewChatCount: 0,
        } as NewChatCounts,
      ),
    [channels],
  );
};

export const IntrovokeChatContext =
  React.createContext<IntrovokeChatContextValues | null>(null);

interface Channel {
  type: ChannelType;
  id: string;
  newMessages: number;
}

const isPinChannel = (channelId: string) =>
  channelId.endsWith(PINS_CHANNEL_SUFFIX);

const isAnnouncementChannel = (channelId: string) =>
  channelId.endsWith(ANNOUNCEMENTS_CHANNEL_SUFFIX);

/**
 * @param previous The current channels array (not modified)
 * @param channelId The id of the main chat channel to add (event-chat)
 * @param channels The main chat channel + subchannels to add (event-chat, event-chat-pins...)
 * @returns the array with all the `channels` added
 */
export const addToChannels = (
  previous: string[],
  channelId: string,
  channels: string[],
) => (!previous.includes(channelId) ? [...previous, ...channels] : previous);

/**
 * @param previous The current channels array (not modified)
 * @param channelId The id of the main chat channel to remove (event-chat)
 * @param channels The main chat channel + subchannels to remove (event-chat, event-chat-pins...)
 * @returns the array with all the `channels` removed
 */
export const removeFromChannels = (
  previous: string[],
  channelId: string,
  channels: string[],
) =>
  previous.includes(channelId)
    ? previous.filter((previousId) => !channels.includes(previousId))
    : previous;

export const IntrovokeChatProvider: React.FC<{
  pubnub: PubNub;
  user: User;
}> = memo(({ pubnub, user, children }) => {
  const { test } = useConfigValue();
  const [activeChat, setActiveChat] = useState<string | null>(null);
  const cache = useQueryClient();

  const [channelIds, setChannelIds] = useState<string[]>([]);
  const [backgroundChannelIds, setBackgroundChannelIds] = useState<string[]>(
    [],
  );
  const [channelData, setChannelData] = useState<Record<string, Channel>>({});
  const subscribedChannelIds = useRef<string[]>([]);

  const allChannelIds = useMemo(
    () => [...channelIds, ...backgroundChannelIds],
    [backgroundChannelIds, channelIds],
  );

  /**
   * Adds the channel to the active channels.
   *
   * Active channels contribute to chat counts
   */
  const subscribe = useLatestCallback((config: ChannelConfig) => {
    const channelId = config.channel;
    const channels = getChannelsFromConfig(config);

    // add to active channels
    setChannelIds((previous) => {
      return addToChannels(previous, channelId, channels);
    });

    // remove from background channels
    setBackgroundChannelIds((previous) =>
      removeFromChannels(previous, channelId, channels),
    );

    setChannelData((previous) => ({
      ...previous,
      [channelId]: {
        id: channelId,
        type: config.type,
        newMessages: 0,
      },
    }));
  });

  /**
   * Moves the channel from the active channels to the background channels.
   *
   * Background channels still remain subscribed through `PubNub` and receive updates but do not contribute to chat counts
   */
  const unsubscribe = useLatestCallback((config: ChannelConfig) => {
    const channelId = config.channel;
    const channels = getChannelsFromConfig(config);

    // remove from active channels
    setChannelIds((previous) =>
      removeFromChannels(previous, channelId, channels),
    );

    // if user is no longer organizer/presenter => don't add to background channels
    if (channelId.includes("presenter") && user.role > UserRole.Presenter)
      return;

    // add to background channels
    setBackgroundChannelIds((previous) =>
      addToChannels(previous, channelId, channels),
    );
  });

  const handleMessage = useLatestCallback(
    (messageEvent: PubNub.MessageEvent) => {
      const messageUser = messageEvent.userMetadata?.user;
      const channelId = messageEvent.channel;
      const messageType = messageEvent.message.type || "message";

      if (messageType !== "announcement" && !isValidUser(messageUser)) {
        return;
      }

      switch (messageType) {
        case "message": {
          if (messageUser.uid !== user.uid && activeChat !== channelId) {
            setChannelData((previous) => ({
              ...previous,
              [channelId]: {
                ...(previous[channelId] || {}),
                newMessages: (previous[channelId].newMessages ?? 0) + 1,
              },
            }));
          }

          cache.setQueryData<Message[]>(getQueryKey(channelId), (prev = []) => {
            if (prev.length > 200) {
              // Slice once we double the chat size.
              prev = prev.slice(-100);
            }
            return [
              ...prev,
              {
                channel: messageEvent.channel,
                message: messageEvent.message,
                user: messageUser,
                timestamp: messageEvent.message.timestamp,
                timetoken: messageEvent.timetoken,
                actions: {},
              },
            ];
          });
          break;
        }
        case "pin": {
          const pinnedMessage = {
            message: messageEvent.message.message,
            user: messageUser,
            timestamp: messageEvent.message.pinTimestamp,
            timetoken: messageEvent.timetoken,
          };
          cache.setQueryData<PinnedMessage[]>(
            getQueryKey(channelId),
            (prev = []) => [...prev, pinnedMessage],
          );
          break;
        }
        case "announcement": {
          const announcementMessage: AnnouncementMessage = {
            id: messageEvent.message.id,
            message: messageEvent.message.message,
            timestamp: messageEvent.message.announcementTimestamp,
            timetoken: messageEvent.timetoken,
            to: messageEvent.message.to,
            type: messageEvent.message.announcementType,
            timeSinceLast: messageEvent.message.timeSinceLast,
            hideSelf: messageEvent.message.hideSelf,
          };

          const announcements =
            cache.getQueryData<AnnouncementMessage[]>(getQueryKey(channelId)) ||
            [];

          // check if timeSinceLast is satisfied to avoid spamming
          if (
            announcementMessage.timeSinceLast > 0 &&
            timeSinceLastAnnouncement(announcementMessage, announcements) <
              announcementMessage.timeSinceLast
          ) {
            break;
          }

          // don't show toast to the user who sent the announcement
          if (
            announcementMessage.hideSelf &&
            announcementMessage.message.message.userUid === user.uid
          ) {
            break;
          }

          // check if the user's role can see the announcement
          if (
            Array.isArray(announcementMessage.to?.roles) &&
            !announcementMessage.to?.roles?.includes(user.role)
          ) {
            break;
          }

          // update announcement if same id
          const toastId = announcementMessage.id;
          const toastContent = (
            <ToastContent
              title={announcementMessage.message.message.name}
              message={announcementMessage.message.message.comment}
            />
          );
          const toastOptions = {
            toastId,
            autoClose: 5000,
            type: announcementMessage.type || "info",
            pauseOnFocusLoss: false,
          };

          if (toastId && toast.isActive(toastId)) {
            // Dismiss any duplicate toasts
            toast.update(toastId, {
              render: toastContent,
              transition: Slide,
              ...toastOptions,
            });
          } else {
            toast(toastContent, toastOptions);
          }

          cache.setQueryData<AnnouncementMessage[]>(
            getQueryKey(channelId),
            (prev = []) => [...prev, announcementMessage],
          );

          break;
        }
      }
    },
  );

  const handleMessageAction = useLatestCallback(
    (event: PubNub.MessageActionEvent) => {
      const channelId = event.channel;
      const queryKey = getQueryKey(channelId);

      // if delete action
      if (event.data.type === MessageActionTypes.DELETED) {
        if (
          !isPinChannel(channelId) &&
          !isAnnouncementChannel(channelId) &&
          event.data.uuid !== user.uid &&
          activeChat !== channelId
        ) {
          setChannelData((previous) => ({
            ...previous,
            [channelId]: {
              ...(previous[channelId] || {}),
              newMessages: Math.max(
                0,
                (previous[channelId]?.newMessages ?? 0) - 1,
              ),
            },
          }));
        }

        cache.setQueryData<Message[]>(queryKey, (prev = []) =>
          deleteMessageActionUpdater(prev, event),
        );
      }

      // if reactions action
      if (event.data.type === MessageActionTypes.REACTIONS) {
        // need to reset cache manually since objects and arrays are deeply nested
        const prev = cache.getQueryData<Message[]>(queryKey) || [];
        cache.setQueryData<Message[]>(queryKey, []);
        cache.setQueryData<Message[]>(queryKey, () =>
          reactionMessageActionUpdater(prev, event),
        );
      }
    },
  );

  useEffect(() => {
    const toSubscribeIds = difference(
      allChannelIds,
      subscribedChannelIds.current,
    );
    const toUnsubscribeIds = difference(
      subscribedChannelIds.current,
      allChannelIds,
    );

    if (toSubscribeIds.length) {
      pubnub.subscribe({
        channels: toSubscribeIds,
      });
    }

    if (toUnsubscribeIds.length) {
      pubnub.unsubscribe({
        channels: toUnsubscribeIds,
      });
    }

    subscribedChannelIds.current = allChannelIds;
  }, [allChannelIds, pubnub]);

  useEffect(() => {
    const listener = {
      message: handleMessage,
      messageAction: handleMessageAction,
    };

    // use local variable to provide cleanup consistency
    const currentSubscribedChannelIds = subscribedChannelIds.current;

    if (!test) {
      pubnub.addListener(listener);
      if (currentSubscribedChannelIds.length) {
        pubnub.subscribe({
          channels: currentSubscribedChannelIds,
        });
      }
    }

    return () => {
      if (!test) {
        pubnub.removeListener(listener);
        if (currentSubscribedChannelIds.length) {
          pubnub.unsubscribe({
            channels: currentSubscribedChannelIds,
          });
        }
      }
    };
  }, [handleMessage, handleMessageAction, pubnub, test]);

  const clear = useLatestCallback((channelId: string) => {
    setChannelData((previous) => ({
      ...previous,
      [channelId]: {
        ...(previous[channelId] || {}),
        newMessages: 0,
      },
    }));
  });

  const contextValue = useMemo(() => {
    const activeChannelData = Object.values(channelData).filter(
      (channelData) => !backgroundChannelIds.includes(channelData.id),
    );

    return {
      user,
      pubnub,
      subscribe,
      unsubscribe,
      handleMessage,
      handleMessageAction,
      clear,
      channels: activeChannelData,
      activeChat,
      setActiveChat,
      activeChannelIds: channelIds,
      backgroundChannelIds,
    };
  }, [
    activeChat,
    channelIds,
    backgroundChannelIds,
    channelData,
    clear,
    user,
    pubnub,
    subscribe,
    unsubscribe,
    handleMessage,
    handleMessageAction,
  ]);

  return (
    <IntrovokeChatContext.Provider value={contextValue}>
      {children}
    </IntrovokeChatContext.Provider>
  );
});

export const useEventChatContext = () => {
  const ctx = useContext(IntrovokeChatContext);

  invariant(ctx, "useEventChatContext called outside of IntrovokeChatContext");

  return ctx;
};
