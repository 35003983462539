import axios from "axios";

import { dbUser } from "../contracts/user/dbUser";
import type { UserUpdate } from "../contracts/user/user";
import { ApiConfig } from "./api-config";

const createUpdate = async (userUpdate: UserUpdate): Promise<dbUser> => {
  const configURL = ApiConfig.GetPrefix() + "/api/v2user/createUpdate";
  const response = await axios.post(
    configURL,
    userUpdate,
    ApiConfig.GetHeaderWithToken(),
  );
  const data: dbUser = response.data;
  return data;
};

const getUserWithToken = async (
  authToken: string,
  eventId?: string,
): Promise<dbUser | null> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2user/parseToken`;
  try {
    const response = await axios.post(
      configURL,
      { eventId },
      ApiConfig.GetHeaderWithToken({
        Authorization: `Bearer ${authToken}`,
      }),
    );

    const data: dbUser = response.data;

    if (response.status === 401) {
      throw new Error("Invalid or expired token");
    }

    return data;
  } catch {
    return null;
  }
};

const getUser = async (
  userId: string,
  eventId?: string,
): Promise<dbUser | null> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2user/user/${userId}?eventId=${eventId}`;
  try {
    const response = await axios.get(configURL, ApiConfig.GetHeaderWithToken());
    const data: dbUser = response.data;
    return data;
  } catch {
    return null;
  }
};

const getUserToken = async (
  eventId: string,
  userId: string,
  moderator: boolean,
  beta: boolean,
): Promise<{ jwt: string; configUrl: string; configJson?: string | null }> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2user/getStageToken`;
  const payload = {
    eventId,
    userId,
    moderator,
    beta,
  };

  const response = await axios.post(
    configURL,
    payload,
    ApiConfig.GetHeaderWithToken(),
  );
  return response.data;
};

/**
 * Returns the JWT token used for accessing a circle video conferencing session
 *
 * @returns An object containing the access token
 */
const getCircleToken = async ({
  hubId,
  circleId,
  userId,
  moderator,
  beta,
}: {
  hubId: string;
  circleId: string;
  userId: string;
  moderator: boolean;
  beta: boolean;
}) => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2user/getCircleToken`;
  const payload = {
    circleId,
    hubId,
    userId,
    moderator,
    beta,
  };

  const response = await axios.post(
    configURL,
    payload,
    ApiConfig.GetHeaderWithToken(),
  );
  return response.data as {
    accessToken: string;
    configUrl: string;
    configJson: string | null;
  };
};

/**
 * Returns the JWT token used for accessing the compatibility checker
 *
 * @returns An object containing the access token
 */
const getCompatibilityToken = async () => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2user/getCompatibilityToken`;

  const response = await axios.get(configURL, {
    ...ApiConfig.GetHeaderWithToken(),
    params: {
      // cache-bust this request so browser will always call the server
      _t: Date.now(),
    },
  });
  return response.data as {
    accessToken: string;
    configUrl: string;
    configJson?: string | null;
    conferenceId: string;
  };
};

const getUserAvatar = async (userId: string) => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2user/user/${userId}/avatar`;
  const response = await axios.get(configURL, ApiConfig.GetHeaderWithToken());
  const data: string = response.data;
  return data;
};

export const UserApi = {
  getUserAvatar,
  createUpdate,
  getUser,
  getUserToken,
  getUserWithToken,
  getCircleToken,
  getCompatibilityToken,
};
