import {
  CircleRecordingStart,
  CircleRecordingStatus,
  CircleRecordingStop,
} from "@src/api/networking-hub-api";
import { useLifecycleGuard } from "@src/hooks/useLifecycleGuard";
import { useCallback, useEffect } from "react";

export enum ICircleRecordingStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
  ACTIVATING = "activating",
}

interface UseCircleRecordingOptions {
  enabled: boolean;
  hubId: string;
  circleId?: string | null;
  onRecordingStarted: () => void;
  onRecordingStopped: () => void;
  onRecordingActivating: () => void;
  circleRecordingStatus: ICircleRecordingStatus;
}

interface UseCircleRecordingResult {
  onToggleRecording: () => void;
}

const useCircleRecording = ({
  enabled,
  hubId,
  circleId,
  onRecordingStarted,
  onRecordingStopped,
  onRecordingActivating,
  circleRecordingStatus,
}: UseCircleRecordingOptions): UseCircleRecordingResult => {
  const withLifecycleGuard = useLifecycleGuard();

  const checkRecordingStatus = useCallback(async () => {
    const { isRecording } = await CircleRecordingStatus(
      hubId,
      circleId as string,
    );

    if (
      circleRecordingStatus !== ICircleRecordingStatus.ACTIVE &&
      isRecording
    ) {
      onRecordingStarted();
    }
    if (
      circleRecordingStatus === ICircleRecordingStatus.ACTIVE &&
      !isRecording
    ) {
      onRecordingStopped();
    }
  }, [
    circleRecordingStatus,
    hubId,
    circleId,
    onRecordingStarted,
    onRecordingStopped,
  ]);

  useEffect(() => {
    if (!hubId || !circleId) return;
    withLifecycleGuard(() => checkRecordingStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubId, circleId]);

  useEffect(() => {
    if (!hubId || !circleId || !enabled) return;

    const intervalTimer = setInterval(
      () => {
        withLifecycleGuard(() => checkRecordingStatus());
      },
      // checks if the recorder has crashed to stop the recording in UI every 10 seconds
      // but if its activating we're waiting for the recording status to be true, so we need to check more often
      circleRecordingStatus === ICircleRecordingStatus.ACTIVATING
        ? 1000
        : 10000,
    );
    return () => {
      clearInterval(intervalTimer);
    };
  }, [
    hubId,
    circleId,
    circleRecordingStatus,
    enabled,
    checkRecordingStatus,
    withLifecycleGuard,
  ]);

  const onToggleRecording = () => {
    if (circleRecordingStatus === ICircleRecordingStatus.ACTIVE) {
      CircleRecordingStop(hubId, circleId as string);
      onRecordingStopped();
    } else {
      CircleRecordingStart(hubId, circleId as string);
      onRecordingActivating();
    }
  };

  return { onToggleRecording };
};

export default useCircleRecording;
