import {
  Box,
  Button,
  Checkbox,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDialog } from "@src/providers/DialogProvider";
import { PollResultsType, PollStatus } from "@src/providers/polls/PollProvider";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  StyledMuiDialogContent,
  StyledMuiDialogActions,
  SettingsWrapper,
  OptionItem,
} from "./CreatePollForm.styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export interface PollFormValues {
  name: string;
  options: { name: string }[];
  queuePoll: boolean;
  delayResults: boolean;
  resultsType: PollResultsType;
}

export interface CreatePollFormProps {
  onSubmit?: (
    poll: Omit<PollFormValues, "resultsAsPercent"> & {
      status: PollStatus;
    },
  ) => void;
}

export const CreatePollForm = ({ onSubmit }: CreatePollFormProps) => {
  const { hideDialog } = useDialog();

  const { register, errors, handleSubmit, control, reset } =
    useForm<PollFormValues>({
      mode: "onBlur",
      reValidateMode: "onBlur",
      defaultValues: {
        name: "",
        options: [{ name: "" }, { name: "" }],
        queuePoll: false,
        delayResults: false,
        resultsType: PollResultsType.VOTES,
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  const handleClose = () => {
    hideDialog("CreatePollDialog");
    reset();
  };

  const handleFormSubmit = (data: PollFormValues) => {
    handleClose();
    onSubmit?.({
      ...data,
      status: PollStatus.ACTIVE,
    });
  };

  const handlePendingFormSubmit = (data: PollFormValues) => {
    handleClose();
    onSubmit?.({
      ...data,
      status: PollStatus.PENDING,
    });
  };

  const disableSubmit = !!(
    errors?.name ||
    errors?.options?.reduce((prev, cur) => {
      return !!(prev || cur?.name);
    }, false)
  );

  return (
    <Box data-testid="create-poll-form">
      <DialogTitle>
        <Typography id="create-poll-dialog-title" variant="h4" align="center">
          Create New Poll
        </Typography>
      </DialogTitle>
      <TextField
        error={!!errors?.name}
        fullWidth
        helperText={errors.name?.message}
        id="poll-name-input"
        data-testid="poll-name-input"
        inputRef={register({
          required: "Required",
          validate: (value) => !!value.trim() || "Required",
        })}
        variant="filled"
        name="name"
        required
        color="primary"
        label="What is your question?"
      />
      <StyledMuiDialogContent>
        {fields.map((field, index, { length }) => (
          <OptionItem key={field.id}>
            <Box display="flex" alignItems="center">
              <TextField
                id={`options-${index}`}
                data-testid="poll-option-input"
                variant="standard"
                defaultValue={field.name} // make sure to include defaultValue
                fullWidth
                error={!!errors.options?.[index]?.name}
                helperText={errors.options?.[index]?.name?.message}
                InputProps={{
                  sx: {
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                    paddingRight: 1.5,
                  },
                  endAdornment: fields.length > 2 && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={`delete option ${index + 1}`}
                        data-testid="poll-delete-option-button"
                        disabled={length <= 2}
                        onClick={() => remove(index)}
                        edge="end"
                        size="large"
                      >
                        <DeleteOutlineIcon sx={{ cursor: "pointer" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  placeholder: "Enter Option",
                }}
                inputRef={register({
                  required: "Required",
                  validate: (value) => !!value.trim() || "Required",
                })}
                key={field.id}
                name={`options[${index}].name`}
                required
              />
            </Box>
          </OptionItem>
        ))}

        <Button
          sx={{ width: "120px" }}
          variant="text"
          color="primary"
          data-testid="poll-add-option-button"
          disabled={fields.length >= 10}
          onClick={() => append({ name: "" }, fields.length as any)}
          startIcon={<AddIcon />}
        >
          Add Option
        </Button>
        <Typography
          variant="body1"
          sx={{ mt: 3, mb: 1 }}
          color="text.secondary"
        >
          Advanced Settings
        </Typography>
        <SettingsWrapper>
          <Tooltip title="This allows you to publish the poll results at your convenience instead of being available immediately upon voting">
            <FormControlLabel
              control={
                <Controller
                  name="delayResults"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      data-testid="poll-delay-checkbox"
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                      color="primary"
                    />
                  )}
                />
              }
              label="Delay showing poll results"
            />
          </Tooltip>

          <Tooltip title="This will hide the actual number of poll participants from attendees">
            <FormControlLabel
              control={
                <Controller
                  name="resultsType"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      data-testid="poll-hide-count-checkbox"
                      checked={props.value !== PollResultsType.VOTES}
                      onChange={(e) =>
                        props.onChange(
                          e.target.checked
                            ? PollResultsType.PERCENT
                            : PollResultsType.VOTES,
                        )
                      }
                      color="primary"
                    />
                  )}
                />
              }
              label="Hide total number of poll participants"
            />
          </Tooltip>
        </SettingsWrapper>
      </StyledMuiDialogContent>
      <StyledMuiDialogActions>
        <Button
          data-testid="poll-save-later-button"
          onClick={handleSubmit(handlePendingFormSubmit)}
          color="primary"
        >
          Save for later
        </Button>
        <Button
          data-testid="poll-publish-button"
          color="primary"
          disabled={disableSubmit}
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Publish Now
        </Button>
      </StyledMuiDialogActions>
    </Box>
  );
};
