import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * The currently known routes
 */
export enum Route {
  EVENT = "/event/",
  NETWORKING_HUB = "/networkingHub/",
  COMPATIBILITY_CHECKER = "/compatibility-checker",
}

/**
 * Simple hook which checks if the current route is the provided known route
 */
export function useIsRoute(route: Route) {
  const { pathname } = useLocation();
  return useMemo(() => pathname.includes(route), [pathname, route]);
}
