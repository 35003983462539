import "emoji-mart/css/emoji-mart.css";

import "video.js/dist/video-js.min.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { Providers } from "./Providers";

(window as any).DEBUG = "IntrovokeStage";
ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById("root"),
);
