import { useCallback } from "react";
import { User, UserRole } from "../../contracts/user/user";
import { atom, useRecoilState } from "recoil";
import { useAnnouncementChannel } from "../../providers/chat/useAnnouncementChannel";
import { getChannelConfig } from "../../providers/chat/getChannelConfig";
import { Api } from "../../api/api";
import {
  useFeatureFlag,
  FeatureFlag,
} from "../../providers/FeatureFlagsProvider";
import { useQuery, useQueryClient } from "react-query";
import { Announcement } from "../../providers/chat/ChatProvider";
import { QueryKeys } from "@src/api/QueryKeys";
import { CanJoinEventResponse } from "@src/contracts/event/event";
import { useUser } from "@src/providers/UserProvider";

export const showHostGuardAtom = atom({
  key: "showHostGuard",
  default: {
    /** Whether the user has already been blocked as a host */
    blocked: false,
    /** Setting this to true will show the host guard dialog */
    show: false,
  },
});

export const showUserLimitGuardAtom = atom({
  key: "showUserLimitGuard",
  default: {
    /** Whether the user has already been blocked as an attendee */
    blocked: false,
    /** Setting this to true will show the user limits guard dialog */
    show: false,
  },
});

const defaultCanJoinResponse: CanJoinEventResponse = {
  host: {
    canJoin: true,
  },
  presenter: {
    canJoin: true,
  },
  attendee: {
    canJoin: true,
    isGraceUser: false,
  },
};

interface HandleJoinBlockedOptions {
  name?: string;
  showModal?: boolean;
}

const useCheckCanJoin = ({
  eventId,
  user,
}: {
  eventId?: string;
  user: User;
}) => {
  const queryKey = QueryKeys.checkCanJoin(eventId);
  const queryClient = useQueryClient();
  const enforceLimits = useFeatureFlag(FeatureFlag.EVENT_ENFORCE_LIMITS);

  const checkCanJoin = useQuery(
    queryKey,
    () => Api.EventApi.CheckCanJoin(eventId as string, user.uid),
    {
      // wait for user id to be generated properly since we generate a random uid for the user until their data is fetched
      enabled: !!(enforceLimits && eventId && user.uid?.includes("|||")),
      retry: false, // retry done on the server
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ["data"],
      staleTime: Infinity,
      placeholderData: defaultCanJoinResponse, // let all users join initially (required because of provider ordering)
      onError: () => {
        // choose happy path => allow users to join
        queryClient.setQueryData(queryKey, defaultCanJoinResponse);
      },
    },
  );

  return checkCanJoin;
};

/**
 * used to check if a user can join the event and notify other hosts on failed attempts
 */
export const useCanJoin = ({
  eventId,
  networkingHubId,
}: {
  eventId?: string;
  networkingHubId?: string;
}) => {
  const user = useUser();
  const [showHostLimitGuard, setShowHostGuard] =
    useRecoilState(showHostGuardAtom);
  const [showUserLimitGuard, setShowUserLimitGuard] = useRecoilState(
    showUserLimitGuardAtom,
  );

  // get announcemnt channel for VS
  const { sendAnnouncement } = useAnnouncementChannel(
    eventId
      ? getChannelConfig({
          type: "event-presenters",
          event: eventId,
        })
      : null,
  );

  // get announcement channel to linked networking hub
  const { sendAnnouncement: sendHubAnnouncement } = useAnnouncementChannel(
    networkingHubId
      ? getChannelConfig({
          type: "event",
          event: networkingHubId,
        })
      : null,
  );

  const checkCanJoin = useCheckCanJoin({ eventId, user });

  /** Behaves as a no-op if show modal=true and user has already been blocked as a host */
  const handleJoinAsHostBlocked = useCallback(
    (options: HandleJoinBlockedOptions = {}) => {
      const { name, showModal = true } = options;

      // if already blocked => ignore
      if (showModal && showHostLimitGuard.blocked) return;

      const announcement: Announcement = {
        title: "Host Limit Reached",
        subtitle: `${
          name || user.name || "Someone"
        } was unable to join as a host since the maximum number of hosts has been reached`,
        to: { roles: [UserRole.Organizer] },
        type: "warning",
      };

      // send announcement to hosts in VS
      sendAnnouncement(announcement);

      // send announcement to hosts in NH
      // this will not show 2 announcements since we unsubscribe from VS chats in NHs and vice versa
      sendHubAnnouncement(announcement);

      // if failed to join as host => show host guard to user
      return setShowHostGuard((prev) => ({
        ...prev,
        show: showModal,
        blocked: true,
      }));
    },
    [
      sendAnnouncement,
      sendHubAnnouncement,
      setShowHostGuard,
      showHostLimitGuard.blocked,
      user.name,
    ],
  );

  /** Behaves as a no-op if showModal=true and user has already been blocked as an attendee */
  const handleJoinAsAttendeeBlocked = useCallback(
    (options: HandleJoinBlockedOptions = {}) => {
      const { showModal = true } = options;

      // if already blocked => ignore
      if (showModal && showUserLimitGuard.blocked) return;

      const announcement: Announcement = {
        title: "Attendee Limit Reached",
        subtitle:
          "Your event is now at capacity. Please upgrade your plan to increase the attendee limit.",
        to: { roles: [UserRole.Organizer] },
        type: "warning",
      };

      // send announcement to hosts in VS
      sendAnnouncement(announcement);

      // send announcement to hosts in NH
      // this will not show 2 announcements since we unsubscribe from VS chats in NHs and vice versa
      sendHubAnnouncement(announcement);

      // show user limit guard to user
      return setShowUserLimitGuard({ show: showModal, blocked: true });
    },
    [
      sendAnnouncement,
      sendHubAnnouncement,
      setShowUserLimitGuard,
      showUserLimitGuard.blocked,
    ],
  );

  return {
    showHostGuard: showHostLimitGuard,
    setShowHostGuard,
    showUserLimitGuard,
    setShowUserLimitGuard,
    checkCanJoin,
    handleJoinAsHostBlocked,
    handleJoinAsAttendeeBlocked,
  };
};
