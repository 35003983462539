import React from "react";
import { css } from "@emotion/react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export interface UserBlockedDialogProps {
  show: boolean;
}

export const UserBlockedDialog: React.FC<UserBlockedDialogProps> = ({
  show,
}) => {
  return (
    <Dialog
      open={show}
      fullWidth
      data-testid="blocked-message"
      css={(theme) =>
        css`
          .MuiDialog-paper {
            max-width: 720px;
            border-radius: ${theme.spacing(4)};
          }
        `
      }
    >
      <DialogContent>
        <Box marginBottom={4} marginTop={3}>
          <Typography variant="h3" align="center" color="secondary">
            You have been blocked from the event
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
