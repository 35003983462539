/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";

import { useEvent } from "../../../providers/EventProvider";
import { EventStageContent } from "./components/EventStageContent";
import Box from "@mui/material/Box";
import { transformImageUrl } from "@src/helpers/image";
import { NetworkingDialog } from "@src/components/Event/EventStage/NetworkingDialog";
import { StageDevTools } from "@src/components/Event/EventStage/StageDevTools";

const DEFAULT_IMAGE_URL =
  "https://res.cloudinary.com/introvoke/image/upload/v1682107617/zyzc23vjrjscrrcgl3w9.png";

export const EventStage = () => {
  const { data: event } = useEvent();
  const imageUrl = useMemo(() => {
    return transformImageUrl({
      url: event?.picture || DEFAULT_IMAGE_URL,
      width: window.screen.width,
      height: window.screen.height,
      alwaysFill: true,
    });
  }, [event?.picture]);
  return (
    <>
      {process.env.NODE_ENV === "development" && <StageDevTools />}
      <NetworkingDialog />
      <Box
        data-testid="event-content-container"
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "auto",
          flex: 1,
          minHeight: 0,
          minWidth: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <EventStageContent />
      </Box>
    </>
  );
};
