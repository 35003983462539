import React, { useMemo } from "react";
import constate from "constate";
import { addSeconds, isAfter, isBefore } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useMutation, UseMutationOptions } from "react-query";
import invariant from "tiny-invariant";

import { Api } from "../../api/api";
import { useSocketClient } from "../../components/Presence/SocketClientProvider";
import { emitAsPromise } from "../../components/Presence/emitAsPromise";
import { UserRole } from "../../contracts/user/user";
import {
  useEvent,
  useEventManagement,
  useEventStageToken,
  useEventStatus,
} from "../EventProvider";
import { useUser, useSetUserRole, useUserApiRole } from "../UserProvider";
import { isLegacyEvent } from "../EventReplaysProvider";
import { isOptimalBrowser } from "../../helpers/browser";
import { useAnnouncementChannel } from "../chat/useAnnouncementChannel";
import { getChannelConfig } from "../chat/getChannelConfig";
import { toast } from "react-toastify";
import { validateEnvironment } from "@src/helpers/validateEnvironment";
import { Announcement } from "../chat/ChatProvider";
import { useDialog, ModalProviderProps } from "../DialogProvider";
import ConnectionErrors, {
  connectionErrorOpenOptions,
} from "@src/components/dialogs/content/ConnectionErrors";
import { usePubNubContext } from "../pubnub/PubNubProvider";
import { VideoType } from "@src/models/eventType";
import { useTheme } from "@mui/material";
import { FeatureFlag, useFeatureFlag } from "../FeatureFlagsProvider";
import { SocketClient } from "@src/graphql/SocketClient/SocketClient";
import { ParticipantType } from "@src/components/ParticipantList/Participant";
import { Actions } from "@src/graphql/schemas/actions";
import { PostMessageEvent, useEmitEvent } from "../embed";

import { StageType } from "./types";
import { useIsEventLive } from "./useIsEventLive";
import { eventStateAtom, eventStateSelectorFamily } from "./atoms";
import useGlobalInterval from "@src/hooks/useGlobalInterval";

export { StageType, useIsEventLive };

export interface ChangeUserStageActionPayload {
  userId?: string;
  stage: "backstage" | "livestage";
}

export type UserActions = {
  type: "move-user";
  payload: ChangeUserStageActionPayload;
};

export enum EventStreamingStatus {
  Loading = "waiting-for-live",
  NotLive = "not-live",
  Live = "live-streaming",
  Networking = "networking",
}

/**
 * Hook used to detect whether the user is currently in the stage view
 */
export const useIsUserInStage = () => {
  const stage = useRecoilValue(eventStateSelectorFamily("stage")) as StageType;
  return stage !== StageType.Viewer;
};

/**
 * Hook that returns whether a replay is enabled on the event
 */
export const useIsReplayEnabled = () =>
  useRecoilValue(eventStateSelectorFamily("replayEnabled")) as boolean;

export enum EventScheduledTimelineStatus {
  BEFORE = "before",
  DURING = "during",
  AFTER = "after",
}

const getEventScheduledTimelineStatus = (
  start: Date,
  end: Date,
): EventScheduledTimelineStatus => {
  const now = new Date();
  if (isBefore(now, start)) return EventScheduledTimelineStatus.BEFORE;
  if (isAfter(now, end)) return EventScheduledTimelineStatus.AFTER;
  return EventScheduledTimelineStatus.DURING;
};

/**
 * Hook to return the current event timeline status, based on the event dates.
 * @param endDateOffsetSeconds - Optional offset to add to the end date
 */
export const useEventScheduledTimelineStatus = (
  endDateOffsetSeconds?: number,
) => {
  const { data: event } = useEvent();

  const endDate = useMemo(
    () => addSeconds(event?.endDate as Date, endDateOffsetSeconds || 0),
    [event?.endDate, endDateOffsetSeconds],
  );

  const [timelineStatus, setTimelineStatus] = useState(() =>
    getEventScheduledTimelineStatus(event?.startDate as Date, endDate),
  );

  const checkIfTimeExpired = useCallback(() => {
    const newTimelineStatus = getEventScheduledTimelineStatus(
      event?.startDate as Date,
      endDate,
    );
    if (newTimelineStatus !== timelineStatus) {
      setTimelineStatus(newTimelineStatus);
    }
  }, [event?.startDate, endDate, timelineStatus]);

  useGlobalInterval({ callback: checkIfTimeExpired });

  return timelineStatus;
};

export interface CreateHandlePromoteEffectArgs {
  userName: string;
  userUid: string;
  optimalBrowser: boolean;
  promoteStatus: "promoted" | "accepted" | "canceled" | "not-promoted";
  setPromoteStatus: React.Dispatch<
    React.SetStateAction<"promoted" | "accepted" | "canceled" | "not-promoted">
  >;
  enterStage: () => void;
  setUserRole: (value: UserRole) => void;
  sendAnnouncement: (announcement: Announcement) => void;
  openDialog: ModalProviderProps["openDialog"];
}
export const createHandlePromotionEffect = (
  args: CreateHandlePromoteEffectArgs,
): [React.EffectCallback, React.DependencyList] => [
  () => {
    const {
      userName,
      userUid,
      optimalBrowser,
      promoteStatus,
      enterStage,
      setUserRole,
      setPromoteStatus,
      sendAnnouncement,
      openDialog,
    } = args;
    const commonAnnouncementProps = {
      id: `promote-user-response-${userUid}`,
      to: [UserRole.Organizer],
      timeSinceLast: 0,
    };

    const handlePromoteFailed = (errors: string[]) => {
      let sharedError = "";

      if (errors[0].includes("incompatible browser")) {
        sharedError = "an incompatible browser";
      } else {
        sharedError = "a network error";
      }

      // send notification to hosts that user ran into an error
      sendAnnouncement({
        ...commonAnnouncementProps,
        title: `Oops! Unable to promote ${userName} to presenter`,
        subtitle: `${userName} accepted the invite but we weren't able to promote them due to ${sharedError}.`,
        type: "error",
      });

      // notify user
      openDialog(
        "ConnectionErrors",
        <ConnectionErrors errors={errors} />,
        connectionErrorOpenOptions,
      );
    };

    const handlePromoteCanceled = async () => {
      // send notification to hosts that user declined invite
      sendAnnouncement({
        ...commonAnnouncementProps,
        type: "info",
        subtitle: `${userName} has declined the invite to be a presenter.`,
      });
    };

    const handlePromoteAccepted = async () => {
      const { hasErrors, errors } = await validateEnvironment(
        UserRole.Presenter,
      );

      // check if user can accept
      if (!optimalBrowser) {
        return handlePromoteFailed([
          "It seems that you're using an incompatible browser. Please make sure you're on the latest version of Chrome.",
          ...errors,
        ]);
      }

      if (hasErrors) {
        return handlePromoteFailed(errors);
      }

      // promote user
      setUserRole(UserRole.Presenter);
      enterStage();

      // send notification to hosts that user accepted invite
      sendAnnouncement({
        ...commonAnnouncementProps,
        type: "success",
        subtitle: `${userName} has accepted the invite to be a presenter.`,
      });

      // notify user
      toast.success("You are a presenter now!", {
        toastId: "promotion-accepted",
      });
    };

    if (promoteStatus === "accepted") {
      handlePromoteAccepted();
      // check if user can accept
    } else if (promoteStatus === "canceled") {
      handlePromoteCanceled();
    }
    setPromoteStatus("not-promoted");
  },
  Object.values(args),
];

export const useIsLiveReactionsEnabled = () => {
  const featureEnabled = useFeatureFlag(FeatureFlag.EVENT_LIVE_REACTIONS);
  const { customTheme } = useTheme();
  const isEventLive = useIsEventLive();
  const status = useEventStatus();
  // TODO: check if the event contains a flag which is currently disabling the live reactions
  return (
    !!featureEnabled &&
    !!customTheme?.liveReactions?.enabled &&
    (status?.isLiveReactionsOn ?? true) &&
    isEventLive
  );
};

export const useLiveReactionsSubscription = () => {
  const { subscribe, unsubscribe } = usePubNubContext();
  const enabled = useIsLiveReactionsEnabled();
  const { data: event } = useEvent();
  const isOnStage = useIsUserInStage();

  useEffect(() => {
    if (!enabled || !event?.uid || isOnStage) return;
    const config = getChannelConfig({
      type: "live-reactions",
      event: event?.uid as string,
    });
    subscribe(config);
    return () => {
      unsubscribe(config);
    };
  }, [subscribe, unsubscribe, enabled, event?.uid, isOnStage]);
};

export const [
  EventStateProvider,
  useEventState,
  useEventName,
  useEventStreamingStatus,
  useSetEventStreamingStatus,
] = constate(
  () => {
    const { openDialog } = useDialog();
    const optimalBrowser = isOptimalBrowser();
    const [promoteStatus, setPromoteStatus] = useState<
      "promoted" | "accepted" | "canceled" | "not-promoted"
    >("not-promoted");

    const [{ stage }, eventStateSetter] = useRecoilState(eventStateAtom);
    const setStage = useCallback(
      (stageType: StageType) => {
        eventStateSetter((s) => ({
          ...s,
          stage: stageType,
        }));
      },
      [eventStateSetter],
    );

    useEmitEvent(
      {
        event: PostMessageEvent.USER_UPDATED,
        data: {
          isOnLiveStage: stage === StageType.LiveStage,
        },
      },
      [stage],
    );

    const client = useSocketClient();
    const { data: event } = useEvent();
    const user = useUser();
    const setUserRole = useSetUserRole();
    const apiUserRole = useUserApiRole();

    invariant(event, "Event is required");

    const { name } = event;

    const { status: eventStatus } = useEventManagement();

    const isLegacyReplayEvent = isLegacyEvent(event);

    const { sendAnnouncement } = useAnnouncementChannel(
      getChannelConfig({
        type: "event-presenters",
        event: event.uid,
      }),
    );

    /** replays are only enabled if the replayUrl has also been setup
     *  If replay configs are setup, recognize them over legacy event replay setting */
    const isReplayConfigured =
      eventStatus &&
      "replayEnabled" in eventStatus &&
      "replayUrl" in eventStatus;

    const {
      isEventLive,
      eventLivestreamStartTime = null,
      eventLivestreamEndTime = null,
      isNetworkingLive,
      replayEnabled,
      replayUrl = null,
      streamPlaceholderMessage = null,
    } = {
      isEventLive: false,
      isNetworkingLive: false,
      ...eventStatus,
      replayEnabled: isReplayConfigured
        ? !!(eventStatus?.replayEnabled && eventStatus.replayUrl?.length)
        : // enabled replays by default for legacy events
          isLegacyReplayEvent,
    };

    const hasNetworking = Boolean(event.networkingHub);
    const [eventStreamingStatus, setEventStreamingStatus] =
      useState<EventStreamingStatus>(
        isEventLive ? EventStreamingStatus.Live : EventStreamingStatus.NotLive,
      );

    useEffect(() => {
      setEventStreamingStatus(
        isEventLive ? EventStreamingStatus.Live : EventStreamingStatus.NotLive,
      );
    }, [isEventLive]);

    // Update our recoil state
    useEffect(() => {
      eventStateSetter((s) => ({ ...s, isEventLive, replayEnabled }));
    }, [eventStateSetter, isEventLive, replayEnabled]);

    const { accessToken: token, configUrl, configJson } = useEventStageToken();

    const enterStage = useCallback(() => {
      setStage(isEventLive ? StageType.Backstage : StageType.LiveStage);
    }, [isEventLive, setStage]);

    const leaveStage = useCallback(() => {
      setStage(StageType.Viewer);
    }, [setStage]);

    const enterLiveStage = () => setStage(StageType.LiveStage);
    const enterBackstage = () => setStage(StageType.Backstage);

    // Subscribe for live reactions during an event
    useLiveReactionsSubscription();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
      ...createHandlePromotionEffect({
        userName: user.name,
        userUid: user.uid,
        optimalBrowser,
        promoteStatus,
        setPromoteStatus,
        enterStage,
        setUserRole,
        sendAnnouncement,
        openDialog,
      }),
    );

    useEffect(() => {
      const handleStageUpdate = ({ stage }: { stage: StageType }) => {
        setStage(stage);
      };

      const handlePromote = () => setPromoteStatus("promoted");

      client.on(`stage-update`, handleStageUpdate);
      client.on(`promote`, handlePromote);

      return () => {
        client.off(`stage-update`, handleStageUpdate);
        client.off(`promote`, handlePromote);
      };
    }, [client, user.uid, setStage]);

    const { mutate } = useMutation(
      async ({ data }: { data: ChangeUserStageActionPayload }) => {
        if (!data.userId && data.stage === "backstage") {
          enterBackstage();
          return;
        }
        emitAsPromise(client, "move-user", data);
      },
    );

    const handleUserAction = (action: UserActions) => {
      if (action.type === "move-user") {
        mutate({ data: action.payload });
      }
    };

    /**
     * Removes the current user from the stage.
     *
     * If the user was promoted from an attendee to presenter,
     * they will be demoted back to an attendee.
     *
     * Presenters are unaffected.
     */
    const handleRemovedFromStage = () => {
      // Don't demote if the user is a host or presenter via emails
      if (Number.isInteger(apiUserRole) && apiUserRole > UserRole.Presenter) {
        // Only those who have been promoted to presenter from viewer can be demoted back to viewer (this includes access codes).
        setUserRole(UserRole.Viewer);
      }
      leaveStage();
    };

    const startNetworking = useCallback(async () => {
      invariant(hasNetworking, "Event must have networking hub linked");
      await Api.EventApi.StartNetworking(event.uid);
    }, [hasNetworking, event.uid]);

    const stopNetworking = useCallback(
      async (returnToEvent: boolean) => {
        invariant(hasNetworking, "Event must have networking hub linked");
        await Api.EventApi.StopNetworking(event.uid, returnToEvent);
      },
      [hasNetworking, event.uid],
    );

    return {
      startNetworking,
      stopNetworking,
      isUserPresentOnLiveStage: stage === StageType.LiveStage,
      enterStage,
      leaveStage,
      setPromoteStatus,
      promoteStatus,
      hasEnteredStage: stage !== "viewer",
      setStage,
      stage,
      handleGoLivestage: enterLiveStage,
      handleGoBackstage: enterBackstage,
      handleUserAction,
      eventId: event.uid,
      isEventLive,
      userToken: token,
      configUrl,
      configJson,
      eventLivestreamStartTime: eventLivestreamStartTime
        ? new Date(eventLivestreamStartTime)
        : null,
      eventLivestreamEndTime: eventLivestreamEndTime
        ? new Date(eventLivestreamEndTime)
        : null,
      name,
      eventStreamingStatus,
      setEventStreamingStatus,
      isNetworkingLive,
      hasNetworking,
      networkingHub: event.networkingHub,
      replayEnabled,
      replayUrl,
      streamPlaceholderMessage,
      handleRemovedFromStage,
    };
  },
  (props) => props,
  (props) => props.name,
  (props) => props.eventStreamingStatus,
  (props) => props.setEventStreamingStatus,
);

/**
 * Returns the current event video type based on the current
 * state of the event and live event management
 */
export const useEventVideoType = () => {
  const isEventLive = useIsEventLive();
  const isReplayEnabled = useIsReplayEnabled();
  const { data: event } = useEvent();

  if (isEventLive) {
    return VideoType.Live;
  } else if (
    (!!event?.endDate && new Date(event.endDate.getTime()) < new Date()) ||
    isReplayEnabled
  ) {
    return VideoType.OnDemand;
  }
  return VideoType.Upcoming;
};

export const usePromoteViewer = (
  {
    client,
    viewer,
    eventId,
  }: {
    client: SocketClient;
    viewer: ParticipantType;
    eventId?: string;
  },
  options: UseMutationOptions = {},
) => {
  const mutation = useMutation(
    () =>
      emitAsPromise(client, Actions.PROMOTE_USER, {
        eventId,
        userId: viewer.userId,
        email: viewer.userId.split("|||")[1],
        username: viewer.username,
        avatar: viewer.avatar,
      }),
    options,
  );

  return useMemo(() => ({ mutation }), [mutation]);
};
