import { Api } from "@src/api/api";
import { QueryKeys } from "@src/api/QueryKeys";
import { dbUser } from "@src/contracts/user/dbUser";
import type { UserUpdate } from "@src/contracts/user/user";
import { setAuthToken } from "@src/helpers/authSession";
import { getUserBaseAvatar } from "@src/helpers/getUserBaseAvatar";
import { useMemo } from "react";
import { useQuery } from "react-query";
import type { UserProps } from "../UserProvider";
export interface UserQuery
  extends Pick<
    dbUser,
    | "email"
    | "jwtToken"
    | "meetToken"
    | "meetTokenModerator"
    | "jwtTokenModerator"
    | "profilePicture"
    | "name"
    | "companyName"
    | "companyId"
    | "title"
    | "updated"
    | "profileSet"
  > {}

export interface UserQueryResponse {
  data: UserQuery | null;
  status: "idle" | "error" | "loading" | "success";
}

/**
 * Creates and or updates the user via the api
 * Adapts the response from out api into our expected {UserQuery} format
 * @param {UserProp} user
 * @returns { UserQueryResponse }
 */
export const useUserQuery = ({
  userProp,
  eventId,
}: {
  userProp?: UserProps;
  eventId?: string;
}): UserQueryResponse => {
  const { data, status } = useQuery<dbUser | undefined>(
    QueryKeys.user(userProp?.userId || "null"),
    async () => {
      const user = await Api.UserApi.getUser(
        userProp?.userId as string,
        eventId,
      );

      if (user) {
        if (user.binaryProfilePicture) {
          user.profilePicture = user.binaryProfilePicture.includes("data:image")
            ? user.binaryProfilePicture
            : `data:image/jpeg;base64,${user.binaryProfilePicture}`;
        } else if (user.profilePicture.includes("gravatar")) {
          user.profilePicture = getUserBaseAvatar(user.uid, user.name);
        }
        return user;
      } else {
        const userCreate: UserUpdate = {
          uid: userProp?.userId as string,
          originalId: userProp?.originalId as string,
          email: userProp?.userEmail as string,
          name: userProp?.userName as string,
          profilePicture: userProp?.userProfile as string,
          eventId,
        };

        return Api.UserApi.createUpdate(userCreate);
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: true,
      enabled: !!userProp,
    },
  );

  const adaptedUser = useMemo(() => {
    if (status !== "success" || !data) {
      setAuthToken(null);
      return null;
    }

    // If call returned an auth token, then set the token
    data.authToken && setAuthToken(data.authToken);

    return {
      email: data.email || data.privateUserInfo.email || "null",
      jwtToken: data.jwtToken,
      meetToken: data.meetToken,
      meetTokenModerator: data.meetTokenModerator,
      jwtTokenModerator: data.jwtTokenModerator,
      profilePicture: data.binaryProfilePicture
        ? `data:image/jpeg;base64,${data.binaryProfilePicture}`
        : data.profilePicture,
      name: data.name,
      companyName: data.companyName,
      companyId: data.companyId === null ? undefined : data.companyId,
      title: data.title,
      updated: data.updated || userProp?.originalId === "null",
      profileSet: data.profileSet || userProp?.originalId === "null",
    };
  }, [data, status, userProp?.originalId]);

  return {
    data: adaptedUser,
    status,
  };
};
