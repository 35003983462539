import { Duration, intervalToDuration } from "date-fns";
import React, { useEffect, useState } from "react";

const isNumber = (value: any): value is number => typeof value === "number";

export const formatTimeLeft = (duration: Duration): string | null => {
  const times = [duration.hours, duration.minutes, duration.seconds];

  if (times.every((time) => !isNumber(time) || time === 0)) {
    return null;
  }

  return times
    .filter(isNumber)
    .filter(
      (value, index, array) =>
        value > 0 ||
        array.slice(0, index).some((prev) => prev > 0) ||
        array.length - 2 <= index,
    )
    .map((value) => value.toString().padStart(2, "0"))
    .join(":");
};

export const useTimer = (
  expiresAt: number,
  refreshInterval: number,
): Duration => {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const id = setInterval(() => {
      setNow(Date.now());
    }, refreshInterval);

    return () => clearInterval(id);
  }, [expiresAt, refreshInterval]);

  const duration = intervalToDuration({
    start: now,
    end: expiresAt,
  });

  return now > expiresAt ? {} : duration;
};

export const Timer = ({
  children,
  expiresAt,
  refreshInterval = 250,
}: {
  expiresAt: number;
  children: (duration: Duration) => React.ReactNode;
  refreshInterval?: number;
}) => {
  return <>{children(useTimer(expiresAt, refreshInterval))}</>;
};
