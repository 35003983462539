import invariant from "tiny-invariant";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useQuery } from "react-query";

import { useEvent } from "./EventProvider";
import { QueryKeys } from "../api/QueryKeys";
import { useEventState } from "./EventStateProvider";
import { Event } from "../contracts/event/event";
import { ReplayUrl } from "../contracts/replay/replay-url";
import { getReplayOptions } from "../api/event-api";

// To preserve past functionality for past event replays
const CUSTOMIZE_REPLAY_FEATURE_START = new Date("2021-06-21T16:32:28.506Z");

// upload new "custom replays" to a different file name
// There's only 1 custom replay per event,
// so keeping the file name unified allows us to simplify checking if it exists
export const TEMP_FOLDER = "TEMP";

const urlWithoutParams = (url: string) => url.split("?")[0];

export const isMatchingUrl = (
  url1: string | null | undefined,
  url2: string | null | undefined,
) => {
  // handle undefined urls
  const haveUndefinedUrl = !url1 || !url2;
  if (haveUndefinedUrl && url1 !== url2) {
    return false;
  }
  return urlWithoutParams(url1 || "") === urlWithoutParams(url2 || "");
};

export const isRecentlyUploadedReplayUrl = (
  url: string | null | undefined,
): boolean => !!url?.includes(TEMP_FOLDER);

export type VodInfo = {
  streamName: string; // ex: "25840460304597745-7 common marketing mistakes to avoid"
  vodName: string; // ex: "25840460304597745_3.mp4"
  streamId: string; // ex: "25840460304597745"
  creationDate: number;
  duration: number;
  fileSize: number;
  filePath: string; // ex: "streams/25840460304597745_1.mp4"
  vodId: string; // ex: "000269331604394342978553",
  type: string; // ex: "streamVod"
};

// Docs: https://antmedia.io/rest/
const WEB_RTC_BASE_PATH = "https://cdn.introvoke.com";

export const DEFAULT_STREAM_PATH = `${WEB_RTC_BASE_PATH}/`;

// To preserve past functionality for past event replays
export const isLegacyEvent = (event: Event) =>
  event.endDate < new Date(CUSTOMIZE_REPLAY_FEATURE_START);

// To preserve past functionality for past event replays
export const getLegacyVodPath = (event: Event) => {
  let baseStreamPath = "https://cdn.introvoke.com/";
  let streamId = event.streamInfo.streamId;

  if (event.ivsStreamInfo) {
    streamId = event.ivsStreamInfo.introvokeStreamKey;
  }

  if (event.streamInfo.streamPath) {
    baseStreamPath = event.streamInfo.streamPath;
  }

  return baseStreamPath + streamId + ".mp4";
};

export const useGetVodPath = (useCDN?: boolean): string => {
  const { data: event } = useEvent();
  const eventState = useEventState();
  const { replayEnabled, streamPlaceholderMessage } = eventState;
  let { replayUrl } = eventState;
  invariant(event, "useGetVodPath requires an Event");

  if (
    replayUrl?.length &&
    replayUrl.includes("https://stream.introvoke.com:5443/WebRTCApp/streams/")
  ) {
    replayUrl = replayUrl.replace(
      "https://stream.introvoke.com:5443/WebRTCApp/streams/",
      useCDN
        ? "https://cdn.introvoke.com/"
        : "https://cdn.introvoke.com/streams/",
    );
  }

  const replaySrc =
    isLegacyEvent(event) &&
    !replayUrl?.length &&
    !streamPlaceholderMessage?.length
      ? getLegacyVodPath(event)
      : replayUrl;

  return useMemo(
    () => (replayEnabled ? replaySrc || "" : ""),
    [replayEnabled, replaySrc],
  );
};

export interface EventReplaysContextValues {
  loadingReplays: boolean;
  replayUrls: ReplayUrl[];
  updatedAt: number;
}

export const EventReplaysContext =
  React.createContext<EventReplaysContextValues | null>(null);

export const EventReplaysProvider: React.FC<{}> = ({ children }) => {
  const { data: event } = useEvent();
  const { isEventLive } = useEventState();

  const fetchAvailableVods = useCallback(
    async () => (event?.uid ? getReplayOptions(event?.uid) : []),
    [event],
  );

  const queryOptions = {
    queryKey: QueryKeys.replays(event?.uid),
    queryFn: fetchAvailableVods,
    enabled: !!event?.uid,
    retry: 1,
    staleTime: Infinity,
  };

  const { data, dataUpdatedAt, isLoading, isFetching, refetch } =
    useQuery(queryOptions);

  useEffect(() => {
    // If the event recently went offline, check for replays again after a short delay
    if (!isEventLive) {
      const timerId = setTimeout(() => refetch(), 1000);
      return () => clearTimeout(timerId);
    }
  }, [isEventLive, refetch]);

  const contextValue = useMemo((): EventReplaysContextValues => {
    return {
      loadingReplays: isFetching || isLoading || isEventLive, // need to fetch replays again if event is still live
      replayUrls: data || [],
      updatedAt: dataUpdatedAt,
    };
  }, [data, dataUpdatedAt, isEventLive, isFetching, isLoading]);

  return (
    <EventReplaysContext.Provider value={contextValue}>
      {children}
    </EventReplaysContext.Provider>
  );
};

export const useEventReplaysContext = () => {
  const ctx = useContext(EventReplaysContext);

  invariant(
    ctx,
    "useEventReplaysContext called outside of EventReplaysContext",
  );

  return ctx;
};
