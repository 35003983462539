/** @jsxImportSource @emotion/react */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Duration, differenceInMinutes } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";

import { css } from "@emotion/react";
import { Typography } from "@mui/material";

import { User } from "../../contracts/user/user";
import { Timer, formatTimeLeft } from "../Timer";

interface NetworkingHubPlayerProps {
  user: User;
  jwt: string;
  hubId: string;
  circleId: string;
  circleName: string;
  circleOwnerEmail?: string[];
  fallback?: React.ReactNode;
  title?: string;
  expiresAt?: number;
  onLeave: () => void;
}

const getJitsiExternalApi = () => (window as any).JitsiMeetExternalAPI; // wow I hate this but they use flow so nothing we can do

const getDurationLabel = (duration: Duration) => {
  const formatted = formatTimeLeft(duration);
  return formatted ? `Time left ${formatted}` : "Time is up!";
};

export const TimerView = ({ expiresAt }: { expiresAt: number }) => (
  <Timer expiresAt={expiresAt}>
    {(duration) => (
      <Typography
        color="secondary"
        css={[
          (theme) => css`
            flex: 0 0 auto;
            padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
            font-size: 14px;
            font-weight: 400;
            width: auto;
          `,
          (theme) =>
            differenceInMinutes(expiresAt, Date.now()) < 5 &&
            css`
              color: ${theme.palette.primary.main};
            `,
        ]}
      >
        {getDurationLabel(duration)}
      </Typography>
    )}
  </Timer>
);

export const NetworkingHubPlayer = (props: NetworkingHubPlayerProps) => {
  // There might occur a problem when loading external script and JitsiMeetExternalAPI will be undefined
  // We need to handle this case by showing a kind of dialog that user needs to refresh page
  const JitsiMeetExternalAPI = useMemo(() => getJitsiExternalApi(), []);
  const jitsiScriptFailed = !JitsiMeetExternalAPI;

  const navigate = useNavigate();
  const location = useLocation();

  const scrollRef = useRef<HTMLDivElement>(null);
  const jitsiRef = useRef<HTMLDivElement>(null);
  const jitsiApiRef = useRef<any>(null);

  const startVirtualExperience = useCallback(
    (circleId: string, circleName: string) => {
      // Replace all characters except alphanumeric
      circleId = circleId.replace(/[^a-z0-9]/gim, " ").replace(/\s+/g, " ");

      const jitsiApi = new JitsiMeetExternalAPI("nh.introvoke.com", {
        roomName: `${circleId}`,
        jwt: props.jwt,
        noSSL: false,
        interfaceConfigOverwrite: {
          APP_NAME: "Introvoke",
          NATIVE_APP_NAME: "Introvoke",
          LANG_DETECTION: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          DISABLE_VIDEO_BACKGROUND: true,
          DEFAULT_BACKGROUND: "#fff",
          DISABLE_DOMINANT_SPEAKER_INDICATOR: false,
          OPTIMAL_BROWSERS: ["chrome", "chromium"],
        },
        configOverwrite: {
          prejoinPageEnabled: false,
          disableAudioLevels: true,
          hideConferenceSubject: true,
          channelLastN: -1,
          toolbarButtons: [
            "microphone",
            "camera",
            "desktop",
            "youtube",
            "sharedvideo",
            "shareaudio",
            "recording",
            ...(!props.circleOwnerEmail ||
            props.circleOwnerEmail.length === 0 ||
            props.circleOwnerEmail?.includes(props.user.email)
              ? ["recording"]
              : []),
            "fullscreen",
            "fodeviceselection",
            "hangup",
            "profile",
            "settings",
            "tileview",
            "select-background",
            "filmstrip",
          ],
          disableTileEnlargement: false,
          subject: circleName,
          disableStage: true,
        },
        userInfo: {
          displayName: props.user.name,
        },
        parentNode: jitsiRef.current,
        onload: () => {
          scrollRef.current?.scrollIntoView({ behavior: "smooth" });
        },
      });

      jitsiApi.addEventListener("readyToClose", async () => {
        setIsCallClosed(true);
      });

      return jitsiApi;
    },
    [
      props.jwt,
      props.user.name,
      props.circleOwnerEmail,
      props.user.email,
      JitsiMeetExternalAPI,
    ],
  );

  const [isCallClosed, setIsCallClosed] = useState(false);

  const { onLeave } = props;
  useEffect(() => {
    if (isCallClosed) {
      onLeave?.();
    } else {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
      if (jitsiApiRef.current && isCallClosed) {
        jitsiApiRef.current.dispose();
      }
    };
  }, [isCallClosed, navigate, location.search, onLeave]);

  useEffect(() => {
    let jitsiApi: any = null;
    if (!jitsiScriptFailed) {
      jitsiApi = startVirtualExperience(props.circleId, props.circleName);
      jitsiApiRef.current = jitsiApi;
    }
    return () => {
      if (jitsiApi) {
        jitsiApi.dispose();
      }
    };
  }, [
    jitsiScriptFailed,
    props.circleId,
    props.circleName,
    startVirtualExperience,
  ]);

  // TODO
  if (jitsiScriptFailed) {
    return (
      <>{props.fallback}</> || <div>VIDEO PLAYER SCRIPT LOADING FAILED</div>
    );
  }

  return (
    <div
      data-testid="hub-player-jitsi"
      css={(theme) => ({
        position: "relative",
        minHeight: "85%",
        width: "100%",
        background: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
      })}
    >
      <div
        css={css`
          background: white;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        `}
      >
        {props.title && (
          <Typography
            ref={scrollRef}
            color="secondary"
            css={(theme) => css`
              flex: 0 0 auto;
              padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
              font-size: 14px;
              width: auto;
            `}
          >
            {props.title}
          </Typography>
        )}
        {props.expiresAt && <TimerView expiresAt={props.expiresAt} />}
      </div>
      <div
        ref={jitsiRef}
        css={{
          flex: 1,
        }}
      />
    </div>
  );
};
