import React, { memo } from "react";
import invariant from "tiny-invariant";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import EventStatusContainer from "@src/components/Event/EventStage/EventStatusContainer";
import { useEvent } from "@src/providers/EventProvider";
import { useUserRole } from "@src/providers/UserProvider";
import { UserRole } from "@src/contracts/user/user";
import EventInfoStatic from "./EventInfoStatic";
import JoinGreenroomButton from "./JoinGreenroomButton";
import JoinNetworkingHubButton from "./JoinNetworkingHubButton";
import { useShowHeaderActions } from "@src/components/Event/EventStage/Header/hooks/useShowHeaderActions";
import { JoinCodeDialog } from "@src/components/SideBar/JoinCodeDialog";
import { useDialog } from "@src/providers/DialogProvider";

interface Props {
  onEnterStage?: () => void;
  isStreamLive: boolean;
  startingSoon?: boolean;
}

interface EventActionsProps {
  onEnterStage?: () => void;
  isStreamLive: boolean;
  showJoinNetworkingHub: boolean;
  showJoinGreenroom: boolean;
}

const EventActions = ({
  onEnterStage,
  isStreamLive,
  showJoinNetworkingHub,
  showJoinGreenroom,
}: EventActionsProps) => {
  const { data: event } = useEvent();

  invariant(event, "Event is required");

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      {showJoinNetworkingHub && <JoinNetworkingHubButton />}
      {showJoinGreenroom && (
        <JoinGreenroomButton
          // pleasing typescript, this will exist as its part of the showJoinGreenroom check in the hook
          onEnterStage={onEnterStage as () => void}
          isStreamLive={isStreamLive}
        />
      )}
    </Stack>
  );
};

const HeaderContainer = styled(Stack)({
  background: "rgba(70, 69, 102, 0.4)",
  boxShadow: "inset 0px 0px 107.588px rgba(255, 255, 255, 0.05)",
  backdropFilter: "blur(50px)",
  flex: 0,
});

const Header = ({ isStreamLive, onEnterStage, startingSoon }: Props) => {
  const { data: event } = useEvent();
  const userRole = useUserRole();
  const isUnregistered = userRole === UserRole.Unregistered;

  const { openDialog } = useDialog();

  invariant(event, "Event is required");

  const showHeaderActions = useShowHeaderActions({
    onEnterStage,
    isStreamLive,
    isStage: false,
  });

  return (
    <HeaderContainer
      justifyContent="space-between"
      direction={{ xs: "column", lg: "row" }}
      padding={1}
      paddingRight={{ xs: 1, lg: 3 }}
      textAlign={{ xs: "center", lg: "left" }}
    >
      <EventInfoStatic />
      {showHeaderActions.hasActions ? (
        <EventActions
          isStreamLive={isStreamLive}
          onEnterStage={onEnterStage}
          showJoinGreenroom={showHeaderActions.showJoinGreenroom}
          showJoinNetworkingHub={showHeaderActions.showJoinNetworkingHub}
        />
      ) : (
        <Stack spacing={1} direction="column" justifyContent="center">
          <EventStatusContainer
            startDate={event.startDate}
            startingSoon={startingSoon}
          />
          {event?.registration?.outsideOfAppEnabled && isUnregistered && (
            <Typography
              sx={{
                mb: 2,
                mt: { xs: 2, md: 0 },
                color: "common.white",
              }}
            >
              {"Already registered for the event? join by entering your "}
              <Typography
                component="span"
                onClick={() =>
                  openDialog("join-code-modal", <JoinCodeDialog />)
                }
                fontWeight="bold"
                sx={{
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {"JOIN CODE"}
              </Typography>
            </Typography>
          )}
        </Stack>
      )}
    </HeaderContainer>
  );
};

export default memo(Header);
