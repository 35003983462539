import React, { useEffect, useState } from "react";
import { usePreviousDistinct } from "@react-hookz/web";

import { IntrovokeChatProvider as UnwrappedChatProvider } from "../../providers/chat/ChatProvider";
import { usePubNubContext } from "../../providers/pubnub/PubNubProvider";
import { useConfigValue } from "@src/providers/config";
import { SideBarTabsProvider } from "../../providers/SideBarTabsProvider";
import { User, UserRole } from "../../contracts/user/user";
import { Registration } from "../../contracts/event/event";
import useEventController from "./useEventController";
import useHubController from "./useHubController";
import EventSideBar from "./EventSideBar";
import { SequelRegistrationSideBar } from "./SequelRegistrationSideBar";
import { NoRegistrationSideBar } from "./NoRegistrationSideBar";
import { useHasEventEnded } from "@src/providers/EventProvider";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";

export enum SideBarType {
  LIVE_EVENT = "live-event",
  NETWORKING_HUB = "networking-hub",
}

interface Props {
  /**
   * The type of side bar to display when registered
   */
  type: SideBarType;
  /**
   * The user information
   */
  user: User;
  /**
   * The information for registration
   */
  registration?: Registration;
  /**
   * The event ID. Note: this appears to have some magic if not supplied during Registration.
   */
  eventId?: string;
}

const ChatProvider = ({
  user,
  children,
}: {
  user: Props["user"];
  children: React.ReactNode;
}) => {
  const { test } = useConfigValue();
  const { pubnub } = usePubNubContext();

  return (
    <UnwrappedChatProvider
      pubnub={pubnub}
      user={{
        uid: test ? "test-user" : user.uid,
        profilePicture: user.profilePicture,
        name: test ? "test-user" : user.name,
        role: test ? UserRole.Viewer : user.userRole,
        email: test ? "test-user@introvoke.com" : user.email,
      }}
    >
      {children}
    </UnwrappedChatProvider>
  );
};

const StageSideBar = ({ user }: Pick<Props, "user">) => {
  const { tabs, showPoweredBy, showLiveSupport } = useEventController();
  const hasEventEnded = useHasEventEnded();
  const prevEnded = usePreviousDistinct(hasEventEnded);
  const [show, setShow] = useState(!hasEventEnded);

  const hidePostEventSidebar = useFeatureFlag(
    FeatureFlag.ENABLE_HIDE_SIDEBAR_POST_EVENT,
  );

  useEffect(() => {
    // If the event goes from live -> ended, keep sidebar for users in audience
    // when they refresh they will no longer have the sidebar
    if (typeof prevEnded !== "undefined" && hasEventEnded === !prevEnded) {
      setShow(true);
    } else {
      setShow(!hasEventEnded);
    }
  }, [hasEventEnded, prevEnded]);

  if (!tabs || (hidePostEventSidebar && !show)) {
    // No event to render or event has ended, show no sidebar
    return null;
  }

  return (
    <SideBarTabsProvider tabs={tabs}>
      <EventSideBar
        showPoweredBy={showPoweredBy}
        showAudienceView
        showLiveSupport={showLiveSupport}
      />
    </SideBarTabsProvider>
  );
};

const HubSideBar = ({ user }: Pick<Props, "user">) => {
  const { tabs, showPoweredBy, showLiveSupport } = useHubController();

  if (!tabs) {
    // No hub to render
    return null;
  }

  return (
    <SideBarTabsProvider tabs={tabs}>
      <EventSideBar
        showPoweredBy={showPoweredBy}
        showLiveSupport={showLiveSupport}
      />
    </SideBarTabsProvider>
  );
};

const SideBar = (props: Props) => {
  const { user, registration, eventId, type } = props;

  if (user.userRole === UserRole.Unregistered) {
    return registration?.enabled ? (
      <SequelRegistrationSideBar />
    ) : (
      <NoRegistrationSideBar eventId={eventId} />
    );
  }

  return (
    <ChatProvider user={user}>
      {type === SideBarType.LIVE_EVENT ? (
        <StageSideBar user={user} />
      ) : (
        <HubSideBar user={user} />
      )}
    </ChatProvider>
  );
};

export default React.memo(SideBar);
