/** @jsxImportSource @emotion/react */

import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
} from "react";
import { useQuery } from "react-query";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";
import { css } from "@emotion/react";
import { useInterval } from "react-use";

import { Api } from "../../api/api";
import { NetworkingHubRoom } from "../../contracts/networking-hub/NetworkingHubRoom";
import { User, UserRole } from "../../contracts/user/user";
import { NavigationHelper } from "../../helpers/navigation";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { CircleSortOrder, useConfigValue } from "../../providers/config";
import {
  useCircle,
  useCircleToken,
  useIsCircleOwner,
  useNetworkingHub,
} from "../../providers/NetworkingHubProvider";
import { RequiredKeys } from "../../types/RequiredKeys";
import { FullPageLoader } from "../FullPageLoader";
import NetworkingHubContent from "../NetworkingHub/NetworkingHubContent";
import { NetworkingHubPlayer as NetworkingHubPlayerLegacy } from "../NetworkingHub/NetworkingHubPlayer";
import { NetworkingStoppedDialog } from "../NetworkingHub/NetworkingStoppedDialog";
import { ProfileModal } from "../NetworkingHub/ProfileModal";
import { RootLayout } from "../RootLayout";
import SideBar, { SideBarType } from "../SideBar";
import { NetworkingHubHeader } from "./EventStage/NetworkingHubHeader";
import { useDialogControls } from "../../hooks/useDialogControls";
import { QueryKeys } from "../../api/QueryKeys";
import queryString from "query-string";
import { NetworkingHub as NetworkingHubType } from "../../contracts/networking-hub/NetworkingHub";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { useUser } from "../../providers/UserProvider";
import { CircleRole, CircleSessionRef } from "../CircleSession/types";
import NetworkingHubCircleHeader from "../NetworkingHub/NetworkingHubCircleHeader";
import { VideoType } from "../../models/eventType";
import FeatureLockedDialog from "../dialogs/FeatureLockedDialog";
import { Alert, Box, CircularProgress, Collapse } from "@mui/material";
import { isOptimalBrowser } from "../../helpers/browser";
import {
  FeatureFlag,
  useFeatureFlag,
} from "../../providers/FeatureFlagsProvider";
import lazyLoader from "@src/helpers/lazyLoader";
import { useDialog } from "@src/providers/DialogProvider";
import useCircleRecording, {
  ICircleRecordingStatus,
} from "./hooks/useCircleRecording";
import { JoinCodeDialog } from "../SideBar/JoinCodeDialog";
import { useCircleCapacityManagement } from "@src/components/Event/hooks/useCircleCapacityManagement";
import { useRouteParams } from "@src/hooks/useRouteParams";
import { useCreateNetworkingHubRoom } from "@src/providers/NetworkingHubProvider/useCreateNetworkingHubRoom";

const CircleSession = lazyLoader(
  () => import("../CircleSession"),
  "CircleSession",
);

export interface NetworkingHubProps {
  fallback?: React.ReactNode;
}

export const NetworkingHub = memo((props: NetworkingHubProps) => {
  const { eventId } = useRouteParams();
  const { data: networkingHub, isLoading, isError } = useNetworkingHub();
  const user = useUser();
  const params = useParams();

  const roomId = params.roomId || params.circleId;

  const { sortCirclesBy, fixedRoomsFilter } = useConfigValue();

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!networkingHub || isError) {
    return <>{props.fallback || null}</>;
  }

  return (
    <>
      <NetworkingHubView
        {...props}
        user={user}
        networkingRooms={networkingHub.networkingHubRooms || []}
        hubId={networkingHub.uid}
        roomId={roomId}
        fixedFilteredRooms={fixedRoomsFilter}
        sortCirclesBy={sortCirclesBy}
        hidePagination={networkingHub.whitelabel?.hidePagination}
        privateConversationTimeLimit={
          networkingHub.whitelabel?.privateConversationTimeLimit
        }
        hideRandomUserMingle={networkingHub?.whitelabel?.hideRandomUserMingle}
        showParticipantNames={networkingHub?.whitelabel?.showParticipantNames}
        hideCreateCircle={networkingHub?.whitelabel?.hideCreateCircle}
        returnToEventId={eventId}
        networkingHub={networkingHub}
      />

      {!!networkingHub?.hubLocked && (
        <FeatureLockedDialog
          open={networkingHub?.hubLocked}
          title="Networking Hub Disabled"
          hideAction
        />
      )}
    </>
  );
});

interface CircleProps {
  hubId: string;
  circleId: string | null | undefined;
  onLeave: () => void;
}

/**
 * *DO NOT USE* Exported for testing only
 */
export const Circle = memo(function Circle({
  circleId,
  hubId,
  onLeave,
}: CircleProps) {
  const [circleRecordingStatus, setCircleRecordingStatus] =
    useState<ICircleRecordingStatus>(ICircleRecordingStatus.INACTIVE);

  const [isConnected, setIsConnected] = useState(false);
  const circleSessionRef = useRef<CircleSessionRef>(null);

  const circleToken = useCircleToken(hubId, circleId);
  const hubV2Enabled = useFeatureFlag(FeatureFlag.HUB_CIRCLE_STAGE_V2);
  const circleRecordingV2Enabled = useFeatureFlag(
    FeatureFlag.CIRCLE_RECORDINGS,
  );

  const user = useUser();
  const isHost = user?.userRole === UserRole.Organizer;
  const circle = useCircle(circleId);
  const isCircleOwner = useIsCircleOwner(circleId);
  const useNewTelemetryEndpoint = useFeatureFlag(
    FeatureFlag.ENABLE_NEW_TELEMETRY_ENDPOINT,
  );

  useCircleCapacityManagement({
    isConnected,
    circle,
    onLeave,
  });

  const shouldRender =
    !!circleId &&
    !!circle &&
    user?.userRole !== UserRole.Unregistered &&
    !circleToken.isLoading &&
    !!circleToken.accessToken;

  useEffect(() => {
    if (!shouldRender) {
      // reset all the states
      setIsConnected(false);
      setCircleRecordingStatus(ICircleRecordingStatus.INACTIVE);
    }
  }, [shouldRender]);

  // Send an API event that the user has joined a circle
  // TODO: move these to actual hooks from an analytics provider once we
  // actually have an idea of what analytics we are sending for hubs

  useEffect(() => {
    if (!shouldRender) return;
    Api.AnalyticsApi.UpdateEventPageVideoView(
      hubId,
      `${hubId}-networkingHub`,
      VideoType.Live,
      user.uid,
      user.name,
      user.userRole,
      user.profilePicture,
      user.location,
      circle?.name,
      circleId as string,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRender, hubId, circleId, user.uid]);

  // Call analytics api for watch time if rendering circle
  useInterval(
    () => {
      if (!shouldRender) return;
      Api.AnalyticsApi.UpdateLengthOfWatch(
        hubId,
        `${hubId}-networkingHub`,
        user.sessionId,
        new Date().toISOString(),
        VideoType.Live,
        user.uid,
        user.name,
        user.userRole,
        user.profilePicture,
        user.location,
        circle?.name,
        circleId as string,
        useNewTelemetryEndpoint,
      );
    },
    // supplying null will disable the interval
    shouldRender ? 60000 : null,
  );

  const stageUser = useMemo(
    () => ({
      id: user.uid,
      role: isCircleOwner || isHost ? CircleRole.HOST : CircleRole.PRESENTER,
      name: `${user.name}`,
      companyName: user.companyName,
      title: user.title,
      email: user.email,
      avatar: user.profilePicture,
    }),
    [
      user.uid,
      user.name,
      user.email,
      user.profilePicture,
      user.companyName,
      user.title,
      isCircleOwner,
      isHost,
    ],
  );

  const onRecordingStarted = useCallback(() => {
    setCircleRecordingStatus(ICircleRecordingStatus.ACTIVE);
  }, []);

  const onRecordingStopped = useCallback(() => {
    setCircleRecordingStatus(ICircleRecordingStatus.INACTIVE);
  }, []);

  const onRecordingActivating = useCallback(() => {
    setCircleRecordingStatus(ICircleRecordingStatus.ACTIVATING);
  }, []);

  const onConnectionChanged = useCallback((connected: boolean) => {
    setIsConnected(connected);
  }, []);

  const { onToggleRecording: onToggleRecordingV2 } = useCircleRecording({
    enabled: !!circleRecordingV2Enabled,
    hubId,
    circleId,
    circleRecordingStatus,
    onRecordingStarted,
    onRecordingStopped,
    onRecordingActivating,
  });

  const onToggleRecording = () => {
    if (circleRecordingV2Enabled) {
      onToggleRecordingV2();
      return;
    }
    if (circleRecordingStatus === ICircleRecordingStatus.ACTIVE) {
      circleSessionRef.current?.stopRecording?.();
    } else {
      circleSessionRef.current?.startRecording?.();
      setCircleRecordingStatus(ICircleRecordingStatus.ACTIVATING);
    }
  };

  // TS is dumb and can't infer that I boolean checked earlier
  if (!circle || !circleId || !shouldRender) return null;

  return (
    <>
      {/* Old Jitsi UI */}
      {!hubV2Enabled && (
        <NetworkingHubPlayerLegacy
          jwt={circleToken.accessToken as string}
          title={circle.name}
          hubId={hubId}
          circleId={circleId}
          circleName={circle.name}
          user={user}
          expiresAt={circle.expiresAt}
          circleOwnerEmail={circle.ownerEmail}
          onLeave={onLeave}
        />
      )}
      {/* New UI */}
      {hubV2Enabled && (
        <>
          <NetworkingHubCircleHeader
            circleName={circle.name}
            recordingEnabled={isCircleOwner || isHost}
            circleExpiration={circle.expiresAt}
            isRecording={
              circleRecordingStatus === ICircleRecordingStatus.ACTIVE
            }
            isRecordingActivating={
              circleRecordingStatus === ICircleRecordingStatus.ACTIVATING
            }
            isRecordingDisabled={!isConnected}
            onToggleRecording={onToggleRecording}
          />
          <div
            data-testid="circle-session-wrapper"
            css={css`
              display: flex;
              min-height: 85%;
            `}
          >
            <Suspense
              fallback={
                <Box
                  display="flex"
                  flex="1"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              }
            >
              <CircleSession
                ref={circleSessionRef}
                circleId={circleId}
                jwt={circleToken.accessToken as string}
                configUrl={circleToken.configUrl as string}
                configJson={circleToken.configJson}
                user={stageUser}
                isRecording={
                  circleRecordingStatus === ICircleRecordingStatus.ACTIVE
                }
                onRecordingStarted={onRecordingStarted}
                onRecordingStopped={onRecordingStopped}
                onKicked={onLeave}
                onLeave={onLeave}
                onConnectionChanged={onConnectionChanged}
              />
            </Suspense>
          </div>
        </>
      )}
    </>
  );
});

export interface NetworkingHubViewProps {
  user: User;
  cleanUI?: boolean;
  fallback?: React.ReactNode;
  fixedFilteredRooms?: string[];
  sortCirclesBy: CircleSortOrder;
  networkingRooms: RequiredKeys<NetworkingHubRoom, "id">[];
  hubId: string;
  roomId?: string;
  hidePagination?: boolean;
  privateConversationTimeLimit?: number;
  hideRandomUserMingle?: boolean;
  showParticipantNames?: ParticipantNameOption;
  hideCreateCircle?: boolean;
  returnToEventId?: string;
  networkingHub?: NetworkingHubType | null;
}

const NetworkingHubView = memo((props: NetworkingHubViewProps) => {
  const { openDialog } = useDialog();
  const { cleanUI } = useConfigValue();
  const { mutateAsync: handleCreateRoom } = useCreateNetworkingHubRoom(
    props.networkingHub?.uid,
  );

  const scrollRef = useRef<HTMLDivElement>(null);
  const { noProfilePopup } = queryString.parse(window?.location?.search);
  const [openBrowserAlert, setOpenBrowserAlert] = React.useState(true);

  useEffect(() => {
    if (
      !noProfilePopup &&
      props.user.userRole !== UserRole.Unregistered &&
      !props.user.updated
    ) {
      const user = props.user;
      openDialog("profile-modal", <ProfileModal user={user} />);
    }
  }, [noProfilePopup, props.user, openDialog]);

  useEffect(() => {
    if (props.networkingHub?.registration?.outsideOfAppEnabled) {
      openDialog("join-code-dialog", <JoinCodeDialog />, {
        priority: "critical",
        disableOutsideClick: true,
        hideCloseIcon: true,
      });
    }
  }, [openDialog, props.networkingHub?.registration?.outsideOfAppEnabled]);

  const location = useLocation();
  const navigate = useNavigate();

  const currentRoom = props.networkingRooms.find(
    (room) => room.id === props.roomId || room.name === props.roomId,
  );

  // We allow the route to contain the circle name but we should
  // use the ID in child components
  const circleId = currentRoom?.id;

  useEffect(() => {
    if (!currentRoom?.expiresAt) {
      return;
    }
    const id = setTimeout(() => {
      navigate({
        pathname: `/networkingHub/${props.hubId}`,
        search: location.search,
      });
    }, currentRoom.expiresAt - Date.now());

    return () => clearTimeout(id);
  }, [currentRoom?.expiresAt, navigate, location.search, props.hubId]);

  const joinMingleRoom = async (data: {
    name: string;
    slots: number;
    metadata: Record<string, string>;
  }) => {
    const { id } = await handleCreateRoom(data);
    navigate({
      pathname: `/networkingHub/${props.hubId}/room/${id}`,
      search: location.search,
    });
  };

  const { data: eventStatus } = useQuery(
    QueryKeys.networkingStatus(props.returnToEventId),
    () => {
      invariant(props.returnToEventId, "Event must exist");
      return Api.EventApi.GetEventStatus(props.returnToEventId);
    },
    {
      refetchIntervalInBackground: true,
      refetchInterval: 5000,
      enabled: Boolean(props.returnToEventId?.length),
      cacheTime: 0,
      staleTime: 0,
    },
  );
  const isNetworkingActive = eventStatus ? eventStatus.isNetworkingLive : true;

  const showReturnToVirtualStage =
    !isNetworkingActive && !!eventStatus?.returnToEvent;
  const dialogProps = useDialogControls(showReturnToVirtualStage);

  const handleReturnToEvent = useLatestCallback(async () => {
    if (props.returnToEventId) {
      NavigationHelper.navigate(
        undefined,
        `/event/${props.returnToEventId}`,
        window.location.search,
      );
    }
  });

  const handleStopNetworking = useLatestCallback(
    async (returnToEvent: boolean) => {
      if (props.returnToEventId) {
        await Api.EventApi.StopNetworking(props.returnToEventId, returnToEvent);
      }
    },
  );

  const showParticipantNames =
    props.showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
    props.user.userRole === UserRole.Organizer
      ? ParticipantNameOption.ALL
      : props.showParticipantNames;

  const handleLeaveCircle = useCallback(() => {
    navigate({
      pathname: `/networkingHub/${props.hubId}`,
      search: location.search,
    });
  }, [navigate, location.search, props.hubId]);

  const optimalBrowser = isOptimalBrowser();

  return props.networkingRooms ? (
    <RootLayout
      content={
        <div
          ref={scrollRef}
          css={css`
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 0;
            overflow: auto;
            height: 100%;
            flex: 1;
            ${props.user.userRole === UserRole.Unregistered
              ? `filter: blur(6px);`
              : ""}
          `}
        >
          {!optimalBrowser && (
            <Box sx={{ width: "100%" }}>
              <Collapse in={openBrowserAlert}>
                <Alert
                  onClose={() => {
                    setOpenBrowserAlert(false);
                  }}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  severity="warning"
                >
                  {
                    "Your browser is not optimal. For the best experience, please download the "
                  }
                  <a
                    href="https://www.google.com/chrome/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    latest version of Chrome.
                  </a>
                </Alert>
              </Collapse>
            </Box>
          )}
          <NetworkingStoppedDialog
            onConfirm={handleReturnToEvent}
            {...dialogProps}
          />

          <NetworkingHubHeader
            onReturnToEvent={
              props.returnToEventId ? handleReturnToEvent : undefined
            }
            onStopNetworking={handleStopNetworking}
            isNetworkingActive={isNetworkingActive}
          />
          <Circle
            hubId={props.hubId}
            circleId={circleId}
            onLeave={handleLeaveCircle}
          />
          <NetworkingHubContent
            parentScrollRef={scrollRef}
            room={circleId}
            cleanUI={cleanUI}
            networkingRooms={props.networkingRooms}
            filteredRooms={props.fixedFilteredRooms}
            sortCirclesBy={props.sortCirclesBy}
            user={props.user}
            // TODO: This cascades down as the event ID... Need to fix all this later
            eventId={props.hubId}
            onCreateRoom={joinMingleRoom}
            hidePagination={props.hidePagination}
            hideRandomUserMingle={props.hideRandomUserMingle}
            showParticipantNames={showParticipantNames}
            hideCreateCircle={props.hideCreateCircle}
          />
        </div>
      }
      sidebar={
        !cleanUI && (
          <SideBar
            type={SideBarType.NETWORKING_HUB}
            user={props.user}
            // TODO: This cascades down as the event ID... Need to fix all this later
            eventId={props.hubId}
            registration={props.networkingHub?.registration}
          />
        )
      }
    />
  ) : (
    <FullPageLoader />
  );
});

export default NetworkingHub;
