import { Typography } from "@mui/material";
import { DialogContentProps } from "@src/providers/DialogProvider";
import { useDialog } from "@src/providers/DialogProvider/DialogProvider";
import React from "react";
import AlertDialogContent from "../AlertDialogContent";
import { OpenDialogOptions } from "@src/providers/DialogProvider/DialogContext";
import { AlertDialogContentProps } from "../AlertDialogContent/AlertDialogContent";

interface ConnectionErrorsProps
  extends Pick<AlertDialogContentProps, "iconSize" | "hideAction" | "sx">,
    Pick<DialogContentProps, "dialogId"> {
  errors: string[];
  title?: string;
}

export const connectionErrorOpenOptions: OpenDialogOptions = {
  priority: "critical",
};

const ConnectionErrors = ({
  errors,
  title,
  dialogId,
  ...restProps
}: ConnectionErrorsProps) => {
  const { hideDialog } = useDialog();

  return (
    <AlertDialogContent
      {...restProps}
      variant="warning"
      title={title ? title : "Oops! Looks Like There's a Problem"}
      body={errors.map((item, index) => (
        <Typography
          key={index}
          variant="body1"
          textAlign="center"
          mb={index !== errors.length - 1 ? 3 : 0}
        >
          {item}
        </Typography>
      ))}
      onClose={() => hideDialog(dialogId as string)}
    />
  );
};

export default React.memo(ConnectionErrors);
