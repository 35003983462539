/** @jsxImportSource @emotion/react */
import React, { memo, useEffect, useMemo, useState } from "react";

import { Theme, css } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material";

import { useLatestCallback } from "../../hooks/useLatestCallback";
import defaultImage from "../../static/networking-image.svg";
import { CloseIcon } from "../icons";
import type { CircleInvite } from "./NetworkingHubInviteProviderNexus";
import {
  usePubNubContext,
  PubnubState,
} from "../../providers/pubnub/PubNubProvider";

export interface NetworkingHubInviteDialogProps {
  loading?: boolean;
  show: boolean;
  invite?: CircleInvite | null;
  onConfirm?: () => void;
  onClose?: () => void;
  onRetry?: () => void;
  isLoading?: boolean;
  circle?: {
    id: string;
    name: string;
  };
}

export interface NetworkingHubInviteDialogContentProps {
  circle?: {
    id: string;
    name: string;
  };
  image: string;
  message: React.ReactNode;
  confirmButton?: string;
  onConfirm?: () => void;
  cancelButton?: string;
  onCancel?: () => void;
  isLoading?: boolean;
  loader?: number | boolean | null;
  expiresIn?: number | null;
}

export const NetworkingHubInviteDialogContent = memo(
  (props: NetworkingHubInviteDialogContentProps) => {
    return (
      <DialogContent
        css={() => css`
          display: flex;
          flex-direction: column;
        `}
      >
        {props.onCancel && (
          <Box display="flex" justifyContent="flex-end" marginBottom={3}>
            <IconButton color="secondary" onClick={props.onCancel} size="large">
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        )}

        <Box
          marginBottom={3}
          display="flex"
          justifyContent="center"
          css={css`
            background-image: url("${props.image}");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            flex: 1 1 200px;
          `}
        />

        <Box marginBottom={3}>
          <Typography variant="h3" align="center" color="secondary">
            {props.message}
          </Typography>
        </Box>

        {props.loader && (
          <Box marginBottom={3} display="flex" justifyContent="center">
            {typeof props.loader === "number" ? (
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  value={props.loader}
                  color="primary"
                />
                {typeof props.expiresIn === "number" && (
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >{`${Math.round(props.expiresIn)}s`}</Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <CircularProgress variant="indeterminate" color="primary" />
            )}
          </Box>
        )}

        <Box marginBottom={3} display="flex" justifyContent="center" gap={2}>
          {props.onCancel && (
            <Button
              variant="text"
              color="primary"
              size="large"
              onClick={props.onCancel}
              disabled={props.isLoading}
            >
              {props.cancelButton || "Cancel"}
            </Button>
          )}
          {props.onConfirm && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={props.onConfirm}
              disabled={props.isLoading}
            >
              {props.confirmButton || "Join Networking"}
            </Button>
          )}
        </Box>
      </DialogContent>
    );
  },
);

const primaryColorStyle = (theme: Theme) => css`
  color: ${theme.palette.primary.main};
`;

const getContentProps = (
  invite: CircleInvite | null | undefined,
  {
    onConfirm,
    onRetry,
    onClose,
    isLoading,
    loading,
  }: {
    onConfirm?: () => void;
    onRetry?: () => void;
    onClose?: () => void;
    isLoading?: boolean;
    loading: boolean;
  },
  avatars: Record<string, string>,
): NetworkingHubInviteDialogContentProps => {
  const searchingRandom = {
    message: "Searching for an attendee to mingle with...",
    image: defaultImage,
    loader: true,
    isLoading,
  };

  if (loading) {
    return searchingRandom;
  }

  if (invite?.status === "invite-received") {
    if (invite.type === "canceled") {
      return {
        message: (
          <>
            Invite was canceled by{" "}
            <span css={primaryColorStyle}>{invite?.username}</span>
          </>
        ),
        image: avatars[invite.userId] || invite.avatar || defaultImage,
        cancelButton: "Close",
        onCancel: onClose,
        isLoading,
      };
    }
    return {
      message: invite.circle ? (
        <>
          <span css={primaryColorStyle}>{invite?.username}</span> invited you to
          circle <span css={primaryColorStyle}>{invite.circle.name}</span>
        </>
      ) : (
        <>
          <span css={primaryColorStyle}>{invite?.username}</span>{" "}
          {invite.private
            ? `invited you to start a private conversation`
            : `invited you to start a private mingle`}
        </>
      ),
      image: avatars[invite.userId] || invite.avatar || defaultImage,
      onConfirm,
      ...(invite.private && {
        confirmButton: "Join",
      }),
      onCancel: onClose,
      isLoading,
    };
  }

  if (invite?.status === "invite-send") {
    if (invite.type === "rejected") {
      return {
        message: (
          <>
            <span css={primaryColorStyle}>{invite?.username}</span> rejected or
            did not respond to the invitation
          </>
        ),
        image: avatars[invite.userId] || invite.avatar || defaultImage,
        cancelButton: "Close",
        onCancel: onClose,
        ...(!invite.private && {
          confirmButton: "Mingle with another attendee",
          onConfirm: onRetry,
        }),
        isLoading,
      };
    }
    return {
      message: !invite.private ? (
        <>
          We paired you with{" "}
          <span css={primaryColorStyle}>{invite?.username}</span>, waiting for a
          response
        </>
      ) : (
        <>
          You have invited{" "}
          <span css={primaryColorStyle}>{invite?.username}</span> to start a
          private conversation, waiting for a response
        </>
      ),
      image: avatars[invite.userId] || invite.avatar || defaultImage,
      cancelButton: "Cancel",
      onCancel: onClose,
      isLoading,
    };
  }

  if (invite?.status === "no-available-users") {
    return {
      message: `We were not able to pair you, all attendees are currently unavailable. Please try again later.`,
      image: defaultImage,
      confirmButton: "Try again",
      onConfirm: onRetry,
      cancelButton: "Close",
      onCancel: onClose,
      isLoading,
    };
  }

  if (invite?.status === "error-random-user") {
    return {
      message: `There was an error trying to pair you with another attendee. Please try again later.`,
      image: defaultImage,
      confirmButton: "Try again",
      onConfirm: onRetry,
      cancelButton: "Close",
      onCancel: onClose,
      isLoading,
    };
  }

  if (invite?.status === "user-not-available") {
    return {
      message: `User is not available right now to invite. Please try again later.`,
      image: defaultImage,
      cancelButton: "Close",
      onCancel: onClose,
      isLoading,
    };
  }

  return searchingRandom;
};

export const NetworkingHubInviteDialog: React.FC<NetworkingHubInviteDialogProps> =
  memo(({ show, invite, onConfirm, onClose, onRetry, isLoading }) => {
    const expiresAt = invite && "expiresAt" in invite ? invite.expiresAt : null;
    const invitedAt = invite && "invitedAt" in invite ? invite.invitedAt : null;

    const users = usePubNubContext().userInfoRef.current;
    const allUsers = Object.values(users) as PubnubState[];
    const avatars = useMemo(
      () =>
        Object.fromEntries(allUsers.map((user) => [user.userId, user.avatar])),
      [allUsers],
    );

    const [forceSearching, setForceSearching] = useState(false);
    const handleRetry = useLatestCallback(() => {
      setForceSearching(true);
      onRetry?.();
      setTimeout(() => {
        setForceSearching(false);
      }, 1000);
    });

    const [contentProps, setContentProps] = useState(() =>
      getContentProps(
        invite,
        {
          loading: forceSearching,
          onConfirm,
          onClose,
          onRetry: handleRetry,
          isLoading,
        },
        avatars,
      ),
    );

    useEffect(
      () => {
        if (show) {
          setContentProps(
            getContentProps(
              invite,
              {
                loading: forceSearching,
                onConfirm,
                onClose,
                onRetry: handleRetry,
                isLoading,
              },
              avatars,
            ),
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        handleRetry,
        invite,
        forceSearching,
        onClose,
        onConfirm,
        show,
        isLoading,
      ],
    );

    const [loader, setLoader] = useState<null | number | boolean>(null);
    useEffect(() => {
      const isPending =
        (invite?.status === "invite-send" ||
          invite?.status === "invite-received") &&
        (invite.type === "outgoing" || invite.type === "incoming");

      if (isLoading) {
        return setLoader(true);
      }

      if (
        typeof expiresAt === "number" &&
        typeof invitedAt === "number" &&
        isPending
      ) {
        const timer = setInterval(() => {
          const expiresInPercent =
            100 - (100 * (Date.now() - invitedAt)) / (expiresAt - invitedAt);
          if (expiresInPercent > 0) {
            setLoader(expiresInPercent);
          } else {
            setLoader(null);
            clearInterval(timer);
          }
        }, 100);

        return () => {
          setLoader(null);
          clearInterval(timer);
        };
      }
    }, [expiresAt, invite, invitedAt, isLoading]);

    return (
      <Dialog
        open={show}
        fullWidth
        css={(theme) =>
          css`
            .MuiDialog-paper {
              max-width: 720px;
            }
          `
        }
      >
        <NetworkingHubInviteDialogContent
          loader={loader}
          {...contentProps}
          expiresIn={expiresAt && invitedAt && (expiresAt - Date.now()) / 1000}
        />
      </Dialog>
    );
  });
