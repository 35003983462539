import { formatEvent, IMetricsEvent } from "@src/metrics";
import axios from "axios";

export interface CreateUserTrackedEventOptions {
  eventType: string;
  eventGroup?: string;
  companyUid?: string;
}
export const createUserTrackedEvent = async (
  apiEndpoint: string,
  event: IMetricsEvent,
) => {
  const url = `${apiEndpoint}/api/v3/tracked-events/createUserTrackedEvent`;
  try {
    await axios.post(url, {
      eventType: event.event,
      eventGroup: event.group,
      companyUid: event.data?.companyId || "",
    });
  } catch (error) {
    console.error(`Failed to track internal event - ${formatEvent(event)}:`, {
      event,
      error,
    });
  }
};
