/** @jsxImportSource @emotion/react */

import React from "react";
import { useEvent } from "../../../providers/EventProvider";
import { ViewerLayout as ViewerLayoutLegacy } from "./ViewerLayoutLegacy";
import { ViewerLayout } from "./ViewerLayout";
import Box from "@mui/material/Box";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { NetworkingDialog } from "@src/components/Event/EventStage/NetworkingDialog";
import { StageDevTools } from "@src/components/Event/EventStage/StageDevTools";

export const EventWatch = () => {
  const { data: event } = useEvent();
  const showCountdownRefactor = useFeatureFlag(FeatureFlag.COUNTDOWN_REFACTOR);
  return (
    <>
      {process.env.NODE_ENV === "development" && <StageDevTools />}
      <NetworkingDialog />
      <Box
        data-testid="event-content-container"
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "auto",
          flex: 1,
          minHeight: 0,
          minWidth: 0,
          backgroundImage: `url(${event?.picture})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        {showCountdownRefactor ? <ViewerLayout /> : <ViewerLayoutLegacy />}
      </Box>
    </>
  );
};
