import constate from "constate";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/react";

import { QueryKeys } from "../../api/QueryKeys";
import { Api } from "../../api/api";
import { useUser } from "../UserProvider";
import {
  GET_EVENT_STATUS,
  SUBSCRIBE_EVENT_STATUS,
} from "./EventProvider.graphql";
import useGqlSubscription from "../../graphql/hooks/useGqlSubscription";
import { EventStatusInfoFragment } from "../../types/graphQl";
import { UserRole } from "@src/contracts/user/user";
import { FullPageLoader } from "@src/components/FullPageLoader";
import ErrorPage from "@src/components/ErrorPage";
import { saveGeneralAccessCookie } from "@src/helpers/cookie";
import { getAuthToken } from "@src/helpers/authSession";
import { useSetMetricsUser } from "@src/metrics";

import { useIsEventLive } from "../EventStateProvider/useIsEventLive";
import { useEvent } from "./useEvent";

export const EventProvider = memo(
  ({
    children,
    eventId,
    fallback = <FullPageLoader />,
  }: React.PropsWithChildren<{
    eventId: string;
    fallback?: React.ReactNode;
  }>) => {
    const { data: event, isLoading, isError } = useEvent();

    useSetMetricsUser({ company_id: event?.organizerUid });

    const tagsRef = React.useRef<{
      eventId: string | undefined;
    }>({ eventId: undefined });
    if (tagsRef.current.eventId !== eventId) {
      // Set tags in render instead of effect to catch errors during first render
      Sentry.setTag("event_id", eventId || null);
      Sentry.setTag("networking_hub_id", null);
      tagsRef.current = {
        eventId,
      };
    }

    if (isLoading) return <>{fallback}</>;

    if (isError || !event)
      return <ErrorPage message="Event not found" showRefresh={false} />;

    return (
      <EventManagementProvider eventId={eventId}>
        {children}
      </EventManagementProvider>
    );
  },
);

export const [
  EventManagementProvider,
  useEventManagement,
  useEventStatus,
  useBlockedEmails,
  useAmIBlocked,
] = constate(
  ({ eventId }: { eventId: string }) => {
    const { data: event } = useEvent();
    const { data: eventStatus } = useGqlSubscription<EventStatusInfoFragment>({
      queryKey: QueryKeys.eventStatus(eventId),
      query: SUBSCRIBE_EVENT_STATUS,
      initialQuery: GET_EVENT_STATUS,
      variables: { eventId },
      // don't enable unless event actually exists (avoid unnecessary GQL errors)
      // otherwise always leave enabled => will fallback to proper polling if anything goes wrong
      enabled: !!event?.uid,
      queryFallback: true,
      apiFallbackFn: () => Api.EventApi.GetEventStatus(eventId) as any,
      dataUpdater: (prevData, newData) => ({
        ...(newData || {}),
        eventId: newData?.eventId || eventId,
      }),
    });

    const user = useUser();
    useEffect(() => {
      if (
        event?.organizerUid &&
        event?.registration?.generalAccessCookiesEnabled &&
        user?.userRole !== UserRole.Unregistered
      ) {
        saveGeneralAccessCookie({
          accessId: event.organizerUid,
          userRole: user.userRole,
          authKey: getAuthToken() || "",
        });
      }
    }, [
      event?.organizerUid,
      event?.registration?.generalAccessCookiesEnabled,
      user?.userRole,
    ]);

    const blockedEmailsResponse = eventStatus?.blockedEmails;

    const blockedEmails = useMemo(
      () => (blockedEmailsResponse || []).map((e) => e && e.toLowerCase()),
      [blockedEmailsResponse],
    );
    const blockEmails = useCallback(
      (emails: string[]) => Api.EventApi.BlockEmails(eventId, emails),
      [eventId],
    );
    const unblockEmails = useCallback(
      (emails: string[]) => Api.EventApi.UnblockEmails(eventId, emails),
      [eventId],
    );

    const amIBlocked = useMemo(
      () => blockedEmails?.includes(user.email),
      [blockedEmails, user.email],
    );

    return {
      status: eventStatus,
      blockedEmails,
      blockEmails,
      unblockEmails,
      amIBlocked,
    };
  },
  (props) => props,
  (props) => props.status,
  (props) => props.blockedEmails,
  (props) => props.amIBlocked,
);

/**
 * Returns an access token for the virtual stage conference call
 *
 * @returns An object containing the access token and flags indicating loading/error states
 */
export const useEventStageToken = () => {
  const user = useUser();
  const { data: event } = useEvent();

  const eventId = event?.uid;

  // TODO: eventually we need to fix our user roles
  const isRegisteredUser = !!user.email?.length; // cannot fetch token for unregistered users

  const { data, isLoading, isError } = useQuery(
    QueryKeys.userToken({
      userId: user.uid,
      userRole: user.userRole,
      eventId,
      // TODO: Remove this once the API is updated
      betaOptIn: true,
    }),
    () =>
      Api.UserApi.getUserToken(
        eventId as string,
        user.uid,
        user.userRole === UserRole.Organizer,
        true,
      ),
    {
      enabled: !!eventId && isRegisteredUser,
      retry: true,
    },
  );

  return {
    isLoading,
    isError,
    accessToken: data?.jwt,
    configUrl: data?.configUrl,
    configJson: data?.configJson || null,
  };
};

/**
 * Hook to check whether the event is past the end date.
 *
 * If the event is past its end date but currently live, will return `false`.
 */
export const useHasEventEnded = () => {
  const { data: event } = useEvent();
  // we also need to check if the event is currently live, if so it hasn't ended
  const isLive = useIsEventLive();
  return useMemo(() => {
    if (isLive) return false;
    return !!event?.endDate && event.endDate.getTime() < Date.now();
  }, [event?.endDate, isLive]);
};
