import amplitude from "amplitude-js";

import { createLogger, Logger } from "@src/helpers/logging";
import {
  MetricsConfig,
  MetricsUser,
  IMetricsEvent,
  MetricsEventData,
  ObjectType,
} from "./common";
import { createUserTrackedEvent } from "@src/api/metrics";
import { formatEvent } from "./common";

const onInitError = () => {
  console.error("Error initializing amplitude, events will not be captured");
};

export class MetricsClient {
  /**
   * Singleton instance of the Metrics client
   */
  private static instance: MetricsClient;
  private _instanceId: string;

  private _initialized: boolean;

  /**
   * If `true`, will not send logs to amplitude. By default it is only false in `local` and `test` environments
   */
  private _disabled: boolean;
  /**
   * Enabling this will log events to the this._logger. By default only `true` in the development env
   * and `false` in all other environments
   */
  private _logEvents: boolean;
  /**
   * Set this to `true` when in test mode to disable all logs
   */
  private _test: boolean;
  /**
   * Enabling this will log extra details when logging events in the this._logger
   */
  private _verbose: boolean;

  private _userProperties: MetricsUser = {};

  /**
   * An optional list of events to track internally in our own database when logged
   */
  private _trackedEvents: IMetricsEvent[] = [];

  /**
   * The api endpoint to send the tracked events to (e.g.: `https://dev-api.sequel.io`)
   */
  private _internalApiEndpoint: string;

  private _logger: Logger;

  /**
   * Private constructor
   */
  private constructor() {
    this._instanceId = "";
    this._initialized = false;
    this._disabled = process.env.NODE_ENV !== "production";
    this._logEvents = process.env.NODE_ENV === "development";
    this._test = process.env.NODE_ENV === "test";
    this._internalApiEndpoint = "";
    this._verbose = false;
    this._logger = createLogger("MetricsClient");
  }

  /**
   * Gets the metrics client being used internally
   */
  private get _client() {
    return amplitude.getInstance(this._instanceId);
  }

  private isTrackedEvent(event: IMetricsEvent) {
    return this._trackedEvents.some(
      (trackedEvent) => trackedEvent.event === event.event,
    );
  }

  public isInitialized() {
    return this._initialized;
  }

  public isEnabled() {
    return this.isInitialized() && !this._disabled;
  }

  /**
   * Initializes or re-initializes the client based on the passed configuration.
   *
   * `initialize` MUST be called at least once before attempting to use the client
   */
  public initialize(config: MetricsConfig) {
    // init default options
    this._instanceId = config.instanceId ?? this._instanceId;
    this._disabled = config.disabled ?? this._disabled;
    this._logEvents = config.logEvents ?? this._logEvents;
    this._verbose = config.verbose ?? this._verbose;
    this._test = config.test ?? this._test;
    this._userProperties = {};
    this._trackedEvents = config.trackedEvents ?? [];
    this._internalApiEndpoint =
      config.internalApiEndpoint ?? this._internalApiEndpoint;
    this._logger = createLogger("MetricsClient", { enabled: !this._test });

    if (this._disabled) {
      this._initialized = false;
      return this._logger.warn(
        "[Metrics.initialize]: Metrics disabled, no logs will be sent to service",
      );
    }

    if (config.projectApiKey) {
      this._client.init(config.projectApiKey, undefined, {
        includeReferrer: true,
        saveEvents: true,
        onError: onInitError,
      });

      this._initialized = true;

      return this._logger.log(
        "[Metrics.initialize]: Metrics service initialized and ready to log events",
      );
    }

    return this._logger.warn(
      "[Metrics.initialize]: Metrics service failed to initialize. ApiKey is missing",
    );
  }

  public static getInstance() {
    if (!MetricsClient.instance) {
      MetricsClient.instance = new MetricsClient();
    }

    return MetricsClient.instance;
  }

  /**
   * Updates the tracked user's properties
   */

  public setUser(user: MetricsUser | null) {
    return user ? this.setUserProperties(user) : this.clearUserProperties();
  }

  private setUserProperties(properties: MetricsUser) {
    const prev = { ...this._userProperties };

    this._userProperties = {
      ...this._userProperties,
      ...properties,
    };

    if (this.isEnabled()) {
      this._client.setUserId(this._userProperties.user_id ?? null);
      this._client.setUserProperties(this._userProperties);
    }

    if (this._verbose) {
      this._logger.log("[Metrics.setUserProperties]:", {
        prev,
        updated: this._userProperties,
      });
    }
  }

  private clearUserProperties() {
    const prev = { ...this._userProperties };
    this._userProperties = {};

    if (this.isEnabled()) {
      // null indicates anonymous user using same device as previous logged in
      // this should technically never occur
      // We can create a new user altogether in Amplitude: https://developers.amplitude.com/docs/javascript#log-out-and-anonymous-users
      // but we would not be able to see
      this._client.setUserId(null);
      this._client.setUserProperties({});
    }

    if (this._verbose) {
      this._logger.log("[Metrics.clearUserProperties]:", { prev, updated: {} });
    }
  }

  /**
   * Logs an event using the internal metrics client. If the event is also in the
   * list of the passed tracked events, it is automatically tracked using the Sequel API
   */
  public logEvent<
    TGroup extends string = string,
    TData extends ObjectType = ObjectType,
    TAdditionalData extends ObjectType = ObjectType, // allow for separate data from event.data
  >(
    event: IMetricsEvent<TGroup, TData>,
    additionalData: MetricsEventData<TAdditionalData> = {} as TAdditionalData,
  ) {
    const loggedEvent = {
      ...event,
      data: {
        ...(event.data ?? {}),
        ...additionalData,
      },
    };

    const formattedName = formatEvent(loggedEvent);

    // log event to the console
    if (this._logEvents) {
      const logObj = {
        event: formattedName,

        // only log event.data if it exists
        ...(loggedEvent.data &&
          Object.keys(loggedEvent.data).length && { data: loggedEvent.data }),

        // if verbose => log user data
        ...(this._verbose && { user: this._userProperties }),
      };
      this._logger.log("[Metrics.logEvent]:", logObj);
    }

    // if not initialized or disabled => don't log to metrics service or call callback
    if (!this.isEnabled()) return;

    // set user again just in case to always use latest user properties
    this.setUser(this._userProperties);

    this._client.logEvent(formattedName, loggedEvent.data);

    if (this.isTrackedEvent(loggedEvent) && this._internalApiEndpoint) {
      createUserTrackedEvent(this._internalApiEndpoint, {
        ...loggedEvent,
        data: {
          ...loggedEvent.data,
          companyId:
            loggedEvent.data.companyId || this._userProperties.company_id || "",
        },
      });
    }
  }
}

export const __testable__ = {
  onInitError,
  formatEvent,
};
