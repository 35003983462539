import { useDeepCompareEffect, useLatest, usePrevious } from "react-use";
import React, {
  useRef,
  useEffect,
  useCallback,
  memo,
  MutableRefObject,
} from "react";
import videojs, { VideoJsPlayer } from "video.js";
import {
  VideoJsPlayerProps,
  VideoPlayerCallbacks,
  VideoPlayerInstance,
} from "./types";
import { StyledVideContainer, StyledVideo } from "./VideoJs.styles";

const { registerIVSTech, registerIVSQualityPlugin } = window as any;
// Register IVS as a tech for videojs
registerIVSTech(videojs);
registerIVSQualityPlugin(videojs);

export enum VideoJsTech {
  /**
   * Amazon IVS tech for VideoJS
   */
  IVS = "AmazonIVS",
  /**
   * Native HTML video player tech
   */
  HTML = "html5",
}

const DEFAULT_WATCH_INTERVAL_MS = 10000; // 10 second ticks on watch interval

const initializePlayerEvents = ({
  player,
  callbackRefs,
}: {
  player: VideoPlayerInstance;
  callbackRefs: MutableRefObject<VideoPlayerCallbacks | undefined>;
}) => {
  player.on("error", () =>
    callbackRefs.current?.onError?.(player.error() as MediaError, player),
  );
  player.on("ended", (event: EventTarget) =>
    callbackRefs.current?.onEnded?.(event, player),
  );
  player.on("play", (event: EventTarget) =>
    callbackRefs.current?.onPlay?.(event, player, player.currentTime()),
  );
  player.on("pause", (event: EventTarget) =>
    callbackRefs.current?.onPause?.(event, player, player.currentTime()),
  );
  player.on("waiting", (event: EventTarget) =>
    callbackRefs.current?.onWaiting?.(event, player, player.currentTime()),
  );
  player.on("playing", (event: EventTarget) => {
    callbackRefs.current?.onPlaying?.(event, player, player.currentTime());
    // Trigger a volume event to catch a muted state while playing muted
    callbackRefs.current?.onVolumeChange?.(
      event,
      player,
      player.volume(),
      player.muted(),
    );
  });
  player.on("ended", (event: EventTarget) =>
    callbackRefs.current?.onEnded?.(event, player),
  );
  player.on("loadeddata", (event: EventTarget) =>
    callbackRefs.current?.onLoadedData?.(event, player),
  );
  player.on("loadedmetadata", (event: EventTarget) =>
    callbackRefs.current?.onLoadedMetadata?.(event, player),
  );
  player.on("volumechange", (event: EventTarget) =>
    callbackRefs.current?.onVolumeChange?.(
      event,
      player,
      player.volume(),
      player.muted(),
    ),
  );

  // Keep track of start/stop times for seeking
  let currentTime = 0;
  let previousTime = 0;
  let startTime = 0;

  player.on("timeupdate", (event: EventTarget) => {
    let tempTime = player.currentTime();
    callbackRefs.current?.onTimeUpdate?.(event, player, tempTime);
    // Adjust our current timing to get correct seeked start/stop times
    previousTime = currentTime;
    currentTime = tempTime;
    if (previousTime < currentTime) {
      startTime = previousTime;
      previousTime = currentTime;
    }
  });
  player.on("seeking", (event: EventTarget) => {
    // Ignore these events as they can be fired when seeking, we only care about when
    // the seek ends, so we update the start/end times for the seeked
    player.off("timeupdate", () => {});
    player.one("seeked", () => {});
    callbackRefs.current?.onSeeking?.(event, player, player.currentTime());
  });
  player.on("seeked", (event: EventTarget) => {
    callbackRefs.current?.onSeeked?.(
      event,
      player,
      startTime,
      player.currentTime(),
    );
  });
  player.on("enterpictureinpicture", (event: EventTarget) => {
    callbackRefs.current?.onPictureInPictureChange?.(event, player, true);
  });
  player.on("leavepictureinpicture", (event: EventTarget) => {
    callbackRefs.current?.onPictureInPictureChange?.(event, player, false);
  });
  player.on("dispose", (event: EventTarget) => {
    callbackRefs.current?.onDestroy?.(event);
  });
};

const initializeWatchInterval = ({
  player,
  callbackRefs,
}: {
  player: VideoPlayerInstance;
  callbackRefs: MutableRefObject<VideoPlayerCallbacks | undefined>;
}) => {
  let previousTime: number;
  let secondsWatched: number;
  let totalTime = 0;
  player.on("seeked", () => {
    previousTime = player.currentTime();
  });
  return setInterval(() => {
    if (!player) return;
    if (!previousTime) {
      previousTime = player.currentTime();
    }

    if (!secondsWatched) {
      secondsWatched = previousTime;
    }

    secondsWatched += player.currentTime() - previousTime;
    previousTime = player.currentTime();
    if (secondsWatched > 60) {
      totalTime += secondsWatched;
      secondsWatched = secondsWatched % 60;
      callbackRefs.current?.onWatchInterval?.(player, totalTime);
    }
  }, DEFAULT_WATCH_INTERVAL_MS);
};

/**
 * React component implementation of VideoJS.
 *
 * _DO NOT_ use this component directly, use the `VideoPlayer` component throughout the app.
 *
 * This component should only be used by player components who wish to implement specific tech.
 */
const VideoJs = ({
  watchIntervalSeconds,
  options,
  enableContextMenu,
  children,
  containerProps = {},
  ...callbacks
}: VideoJsPlayerProps) => {
  const previousOptions = usePrevious(options);
  const callbackRefs = useLatest(callbacks);
  const playerRef = useRef<VideoJsPlayer>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const watchIntervalRef = useRef<ReturnType<typeof setInterval> | undefined>();

  const initializePlayer = useCallback(() => {
    const player = playerRef.current as VideoPlayerInstance;

    initializePlayerEvents({
      player,
      callbackRefs,
    });

    watchIntervalRef.current = initializeWatchInterval({
      player,
      callbackRefs,
    });

    callbackRefs.current?.onReady?.(player);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!videoRef.current) return;
    if (!playerRef.current) {
      playerRef.current = videojs(
        videoRef.current,
        {
          ...options,
          controlBar:
            typeof options.controlBar === "boolean"
              ? options.controlBar
              : {
                  ...(options.controlBar ?? {}),
                  volumePanel:
                    typeof options.controlBar?.volumePanel === "boolean"
                      ? options.controlBar.volumePanel
                      : {
                          inline: false,
                          ...(options.controlBar?.volumePanel ?? {}),
                        },
                },
        },
        initializePlayer,
      );
    }
  }, [initializePlayer, options]);

  useEffect(() => {
    return () => {
      playerRef.current?.dispose();
      playerRef.current = undefined;
      typeof watchIntervalRef.current !== "undefined" &&
        clearInterval(watchIntervalRef.current);
    };
  }, []);

  // Update player state
  useDeepCompareEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    // Check the type of source, prefer the sources over src
    if (options?.sources && previousOptions?.sources !== options?.sources) {
      player.src(options.sources);
      player.load();
    } else if (options?.src && previousOptions?.src !== options?.src) {
      player.src(options.src);
      player.load();
    }
    typeof options?.controls !== "undefined" &&
      player.controls(options.controls);
    typeof options?.loop !== "undefined" && player.loop(options?.loop);
    typeof options?.autoplay !== "undefined" &&
      player.autoplay(options.autoplay);
    typeof options?.muted !== "undefined" && player.muted(options.muted);
  }, [options ?? {}]);

  // Right click context menu
  useEffect(() => {
    const player = playerRef.current;
    const element = player?.contentEl();
    const handler = (event: Event) => event.preventDefault();
    const cleanup = () => {
      element?.removeEventListener("contextmenu", handler, false);
    };
    if (!element || enableContextMenu) return cleanup;
    // Disable right-click context menu
    element?.addEventListener?.("contextmenu", handler, false);
    return cleanup;
  });

  return (
    <StyledVideContainer {...containerProps} data-vjs-player>
      <StyledVideo
        playsInline
        className="video-js vjs-big-play-centered"
        ref={videoRef}
      />
      {children}
    </StyledVideContainer>
  );
};

export default memo(VideoJs);
