import React, {
  memo,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from "react";
import invariant from "tiny-invariant";
import { useUpdateEffect } from "react-use";
import { useRecoilState } from "recoil";

import isString from "lodash/isString";
import { User, UserRole } from "@src/contracts/user/user";
import { extractIdsFromUrl } from "@src/helpers/utils";
import { FullPageLoader } from "@src/components/FullPageLoader";
import { isObjectWithProps } from "@src/guards/isObjectWithProps";
import { getUserRoleCookie, saveUserRole } from "@src/helpers/cookie";
import {
  useGetAuthKey,
  useAuthenticatedUser,
  useGetUserProps,
  useAdaptContextValue,
  useUserQuery,
  useSetSentryUser,
} from "./hooks";
import { useAuthParams } from "../QueryParamsProvider";
import { userRoleAtom } from "./atoms";
import { useRoleChecks } from "./hooks/useRoleChecks";
import { useApiRoleQuery } from "./hooks/useApiRoleQuery";
import {
  MessageCommand,
  PostMessageEvent,
  useCommandListener,
  useEmitEvent,
} from "../embed";
import { useEvent } from "../EventProvider";
import { useNetworkingHub } from "../NetworkingHubProvider";
import { checkRegistrationModes } from "@src/hooks/useRegistrationModes";
import { FeatureFlag, useFeatureFlag } from "../FeatureFlagsProvider";

export interface UserContextValue {
  user:
    | (User & {
        mode?: string;
      })
    | null;
}

export const UserContext = createContext<UserContextValue | null>(null);

export const useUserContext = () => {
  const ctx = useContext(UserContext);
  invariant(ctx, "useUserContext called outside of UserProvider");
  return ctx;
};

export interface UserRoleWrapperProps {
  eventId?: string;
  networkingHubId?: string;
}

const useUserRoleProvider = ({
  eventId,
  networkingHubId,
}: UserRoleWrapperProps) => {
  const { user } = useUserContext();
  const [{ role: userRole, temporaryRole }, setUserRole] =
    useRecoilState(userRoleAtom);

  const { data: apiRoleData, isLoading } = useApiRoleQuery({
    user: user as User,
    eventId,
    networkingHubId,
  });

  // Listen for commands to set the user's role (example, setting as viewer from demo site)
  useCommandListener(MessageCommand.SET_USER_ROLE, (data) => {
    setUserRole((s) => {
      // ignore if unregistered
      if (s.role >= UserRole.Unregistered) return s;
      return {
        ...s,
        temporaryRole: data.role,
      };
    });
  });

  // On role updates emit role changes to any parent frames
  useEmitEvent(
    {
      event: PostMessageEvent.USER_UPDATED,
      data: {
        role: temporaryRole !== null ? temporaryRole : userRole,
      },
    },
    [userRole, temporaryRole],
  );

  // TODO: This should be within a route guard, move this
  // once we fix routing and auth entry guards
  const eventOrNetworkingHubId = eventId || networkingHubId;
  const disableCookieRole = useFeatureFlag(FeatureFlag.DISABLE_COOKIE_ROLE);
  const cookieRole = useMemo(
    () =>
      disableCookieRole
        ? UserRole.Unregistered
        : getUserRoleCookie(eventOrNetworkingHubId).userRole,
    [eventOrNetworkingHubId, disableCookieRole],
  );
  const apiRole =
    typeof apiRoleData === "number" ? apiRoleData : UserRole.Unregistered;

  useEffect(() => {
    const userRole = Math.min(cookieRole, apiRole, UserRole.Unregistered);

    setUserRole((c) => ({ ...c, role: userRole, apiRole }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRole, setUserRole, cookieRole]);

  // Update effect prevents initial mount from overriding roles
  // with unregistered before cookie or api loaded
  useUpdateEffect(() => {
    // Avoid persisting any roles while a temporary role is active,
    // this avoids weird state when temporary as a viewer (actual host) -> promoted/demoted (could lose original role)
    if (temporaryRole !== null) return;
    // always persist current role in cookies (only update role)
    saveUserRole(userRole, eventOrNetworkingHubId as string, "");
  }, [userRole, temporaryRole, eventOrNetworkingHubId]);

  return {
    userRole: temporaryRole !== null ? temporaryRole : userRole,
    isLoading,
  };
};

/**
 * Wrapper needed to access the user from user context and prevent
 * rendering children until we get the role form the API.
 */
const UserRoleWrapper = React.memo(
  ({
    children,
    eventId,
    networkingHubId,
  }: React.PropsWithChildren<UserRoleWrapperProps>) => {
    const { userRole, isLoading } = useUserRoleProvider({
      eventId,
      networkingHubId,
    });
    // validate user role and environment
    // TODO: see if we really need to call can join in this hook
    useRoleChecks({ eventId, userRole });

    if (isLoading) {
      return <FullPageLoader />;
    }

    return <>{children}</>;
  },
);

export interface UserProps {
  userId: string;
  originalId: string;
  userEmail: string | null;
  userName: string;
  userProfile: string;
  userRole: UserRole;
  mode?: string;
}

export const UserProvider = memo(({ children }) => {
  const { eventId, networkingHubId } = extractIdsFromUrl();
  const params = useAuthParams();

  const { data: event, isLoading: isLoadingEvent } = useEvent(eventId);
  const { data: hub, isLoading: isLoadingHub } =
    useNetworkingHub(networkingHubId);

  const registrationModes = checkRegistrationModes(event ?? hub);

  // check if params have user info
  const hasParamsUserInfo =
    isObjectWithProps(params, "userName", "userProfile") &&
    isString(params.userName) &&
    isString(params.userProfile) &&
    // do not allow them to bypass when registration is enabled
    !(
      registrationModes.isSequelRegistration ||
      registrationModes.isThirdPartyRegistration
    );

  const authKey = useGetAuthKey({
    eventId,
    networkingHubId,
    hasParamsUserInfo,
  });

  const { user, isLoading } = useAuthenticatedUser({
    eventId,
    networkingHubId,
    authKey,
  });

  const userProps = useGetUserProps({
    user,
    // wait on loading the auth and event/hub data
    isLoading: isLoading || isLoadingEvent || isLoadingHub,
    hasParamsUserInfo,
  });

  const userQuery = useUserQuery({ userProp: userProps, eventId });

  const contextValue = useAdaptContextValue({
    userProps,
    userQuery,
  });

  useSetSentryUser({ contextValue });

  if (!contextValue) {
    return <FullPageLoader />;
  }

  return (
    <UserContext.Provider value={contextValue}>
      <UserRoleWrapper eventId={eventId} networkingHubId={networkingHubId}>
        {children}
      </UserRoleWrapper>
    </UserContext.Provider>
  );
});

export const __testable__ = {
  UserRoleWrapper,
};
